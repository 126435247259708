import React, {Component} from 'react';

import Header from '../components/Header/Header';
import Footer from '../components/Footer';


export default class NotFound extends Component {
    render() {
        return (
            <div>
                <Header background-color="blue_dark" text-color="white" backgroundImage={require("../images/TankYou-v2-white.png")} />
                <section className="pad-100">
                    <div className="center">
                        <p className="not-found">404</p>
                        <img alt="404 not found" src={require("../images/404.png")} className="wp-70" />
                        <p className="not-found-text">Oups, impossible de trouver cette page</p>
                        <a href="/" className="btn_primary">Accueil</a>
                    </div>
                </section>
                <Footer backgroundColor="blue_dark" textColor="white" />
            </div>
        );
    }
}