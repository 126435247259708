import React, {Component} from 'react';
import vars from '../../../vars';
import {StripeProvider} from 'react-stripe-elements';

import MyStoreCheckout from '../../Stripe/MyStoreCheckout';

export default class CustomerPaymentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stripe: null
        }
    }

    componentWillMount() {
        if (window.Stripe) {
            this.setState({stripe: window.Stripe(vars.stripe_token)});
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({stripe: window.Stripe(vars.stripe_token)});
            });
        }
    }

    render() {
        if(!this.state.stripe)
            return null;
            
        return (
            <StripeProvider stripe={this.state.stripe}>
                <MyStoreCheckout
                    toggle={this.props.toggle}
                    cards={this.props.cards}
                />
            </StripeProvider>
        );
    }
}
