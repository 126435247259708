import React, {Component} from 'react';
import {Registry} from "../../../Utils";
import Modal from "../../Modal";
import CustomerPaymentModal from "../OrderBlockModal/CustomerPaymentModal";


export default class OrderBlockPayment extends Component {
    constructor(props) {
        super(props);

        this.selected = [];

        this.state = {
            card: {
                id: null,
                metadata: null,
                last4: null
            },
            cards: [],
            toggle: false,
            loading: true
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.api.get("/customers/paymentMethods")
            .then(results => {
                let cards = [];
                for(let c of results.data){
                    cards.push({
                        id: c.id,
                        metadata: c.metadata,
                        last4: c.last4
                    });
                }
                this.setState({cards: cards, loading: false});
            })
            .catch(err => {
                console.log(err);
                this.setState({loading: false})
            });
    };

    renderCardItem = (card) => {
        return " **** " + card.last4;
    };

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    };

    getSelected = (card) => {
        this.setState({card: card}, () => {this.props.hasCreditCard(true); this.props.orderInformations({payment: this.state.card})});
    };

    selectAction = (card) => {
        this.getSelected(card);
    };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-10">
                        <p className="marg-b-10">{ this.props.subtitle }</p>
                    </div>
                    <div className="col-md-2 right relative">
                        <i className="f-18 icon-more pink" onClick={this.toggle}> </i>
                        {
                            !this.state.loading && this.state.cards.length === 0 ?
								<div className="tooltip-info-container">
									<div className="tooltip-info">Ajoutez votre premi&egrave;re carte bancaire pour continuer</div>
								</div>
                                : null
                        }
                    </div>
                </div>
                {
                    this.state.loading ?
                        <div className="marg-t-10">
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw f-16"> </i>
                        </div>
                        :
                        this.state.cards.length === 0 ?
                            <div className="pad-15">
                                <span className="grey">{this.props.placeholder}</span>
                            </div>
                            :
                            this.state.cards.map((card, i) =>
                                <div key={"card-" + i} className={"div-block-content " + (this.state.card.id === card.id ?  "border-blue-1" : "border-grey-1")}>
                                    <div className="div-block-logo">
                                        <i className="font-ty icon-credit-card"> </i>
                                    </div>
                                    <div className="pad-5 vertical-align-middle">
                                        {this.renderCardItem(card)}
                                    </div>
                                    <div className="block-button">
                                        <div className={"button-select " + (this.state.card.id === card.id ? "border-blue-1" : "border-grey-1")} onClick={() => this.selectAction(card)}>
                                            {
                                                this.state.card.id === card.id ?
                                                    <div className="button-selected"> </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                }
                <Modal
                    modalId="addCardModal"
                    modal={this.state.toggle}
                    toggle={this.toggle}
                    modalHeader="Ajouter un moyen de paiement"
                ><CustomerPaymentModal
                    toggle={this.toggle}
                    cards={this.loadCards}
                />
                </Modal>
            </div>
        );
    }
}

OrderBlockPayment.defaultProps = {
    hasCreditCard: () => {},
    orderInformations: () => {},
    subtitle: "",
    placeholder: ""
};
