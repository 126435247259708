import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Registry } from "../../Utils";
import AccountPaymentCard from "./PaymentCard";
import Variables from '../../vars';
import CustomerPaymentModal from "../Order/OrderBlockModal/CustomerPaymentModal";
import Modal from "../Modal";


class AccountPayments extends Component{
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        this.user = Registry.observe(this, "user", user => {
            this.user = user;
        });

        this.loadCards = this.loadCards.bind(this);
        this.addCard = this.addCard.bind(this);
        this.onDeletedCard = this.onDeletedCard.bind(this);

        this.state = {
            loading: false,
            cards: [],
            msgError: "",
            toggle: false
        };
    }

    componentDidMount(){
        this.loadCards();
    }

    loadCards(){
        this.setState({loading: true, cards: []});
        this.api.get("/customers/paymentMethods")
            .then(results => {
                if(results && results.hasOwnProperty("data"))
                    this.setState({cards:results.data, loading: false});
                else {
                    this.setState({loading: false});
                    Registry.register("registered_card", false);
                }
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
            })
    }

    addCard(){
        let c = this.state.cards;
        let len = c.length;
        let add = !len || c[len-1].id;

        if(add){
            c.push({
                id: null,
                last4: "",
                metadata: {name: ""}
            });
            this.setState({cards: c});
        }
    }

    onDeletedCard(card){
        this.loadCards();
    }

    getMsgError = (msg) => {
        this.setState({
            msgError: msg
        })
    };

    toggle = () => {
        this.setState({toggle: !this.state.toggle})
    };

    render(){
        return (
            <div className="row col-md-6">
                <div className="block-content pad-t-15 pad-b-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                            Paiement
                                            <span className="pink cursor right">
                                                <i className="icon-more" onClick={this.toggle}> </i>
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 marg-t-15">
                                {
                                    this.state.loading ?
                                        <i className="fa fa-spinner fa-spin fa-fw"> </i>
                                    :
                                        this.state.cards.length === 0 ?
                                            <div className="grey_dark marg-b-10">Aucun moyen de paiement</div>
                                            :
                                            <StripeProvider apiKey={Variables.stripe_token}>
                                                <Elements>
                                                    <div>
                                                        {
                                                            this.state.cards.map((card, i) => {
                                                                return <AccountPaymentCard
                                                                    key={i}
                                                                    card={card}
                                                                    onDeletedCard={this.onDeletedCard}
                                                                    msgError={this.getMsgError}
                                                                />;
                                                            })
                                                        }
                                                    </div>
                                                </Elements>
                                            </StripeProvider>
                                }
                            </div>
                        </div>
                        {
                            this.state.msgError ?
                                <div className="col-md-12">
                                    <div className="red row">
                                        {this.state.msgError}
                                    </div>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
                <Modal
                    modalId="addCardModal"
                    modal={this.state.toggle}
                    toggle={this.toggle}
                    modalHeader="Ajouter un moyen de paiement"
                ><CustomerPaymentModal
                    toggle={this.toggle}
                    cards={this.loadCards}
                />
                </Modal>
            </div>
        );
    }
}

AccountPayments.defaultProps = {
};

export default AccountPayments;
