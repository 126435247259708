import React, {Component} from 'react';
import CustomerVehicleModal from "../OrderBlockModal/CustomerVehicleModal";
import {Registry} from "../../../Utils";
import DivGroupInline from "../../DivGroupInline";
import Modal from "../../Modal";

export default class OrderBlockVehicle extends Component {
    constructor(props) {
        super(props);

        this.vehicles = [];

        this.state = {
            toggle: false,
            vehicles: [],
            gasName: "",
            disabledGas: "",
            data: {
                brand: "",
                model: "",
                color: "",
                gas_id: "",
                plate: ""
            },
            errors: {
                brand: true,
                model: true,
                color: true,
                gas_id: true,
                plate: true
            },
            showErrors: false
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        this.loadVehicles();
        this.getDisabledGas();
    }

    loadVehicles = () => {
        this.api.get("/fleet")
            .then(res => {
                this.setState({ vehicles: res.fleet })
            })
            .catch(err => {
                console.log(err);
            })
    };

    getDisabledGas = () => {
        this.api.get("/settings/application")
            .then(res => {
                this.setState({disabledGas: res["disabled-gas"]});
            })
            .catch(err => {
                console.log(err);
            })
    };

    vehiclesSelected = (vehicle, add = true) => {
        if (add) {
            this.vehicles.push(vehicle);
        } else {
            let removeVehicle = this.vehicles.filter(function(obj) {
                return obj.id !== vehicle.id;
            });
            this.vehicles = removeVehicle;
        }
        this.props.orderInformations({vehicle: this.vehicles});

        if (this.vehicles.length > 0) {
            this.props.hasVehicle(true);
        } else {
            this.props.hasVehicle(false);
        }
    };

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        });
    };

    getVehicleDescription(vehicle) {
        return <div>{vehicle.description}<br/>
            <span className="grey fw-100">{vehicle.gas_name} - {vehicle.plate}</span>
        </div>
    }

    validationAction = () => {
        let errors = this.state.errors;
        let _this = this;

        Object.keys(errors).forEach(function(key) {
            if (errors[key] === true) {
                _this.setState({
                    showErrors: true
                });
                return false;
            }
        });

        let data = {
            plate: this.state.data.plate,
            description: this.state.data.brand + " " + this.state.data.model,
            color: this.state.data.color,
            gas_id: this.state.data.gas_id
        };
        this.api.post("/fleet", data)
            .then(res => {
                this.loadVehicles();
                this.setState({toggle: false})
            })
            .catch(err => {
                console.log(err);
            });
    };

    getData = (data) => {
        let errors = {};
        Object.keys(data).forEach(function(key) {
            if (data[key].length === 0 || /^\s*$/.test(data[key])) {
                errors[key] = true;
            } else {
                errors[key] = false;
            }
        });

        this.setState({
            errors: errors,
            data: data
        });

        return data;
    };

    getGasName = (gasId) => {
        this.api.get("/gas/" + gasId)
            .then(res => {
                this.setState({
                    gasName: res.name
                });
            })
            .catch(err => {
                return err;
            })
    };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-10">
                        <p className="marg-b-10">{ this.props.subtitle }</p>
                    </div>
                    <div className="col-md-2 right relative">
                        <i className="f-18 icon-more pink" onClick={this.toggle}> </i>
						{
							!this.state.loading && this.state.vehicles.length === 0 ?
								<div className="tooltip-info-container top--22-important">
									<div className="tooltip-info">Ajoutez un v&eacute;hicule pour continuer</div>
								</div>
								: null
						}
                    </div>
                </div>
                    {
                        this.state.vehicles.length === 0 ?
                            <div className="pad-15 grey">
                                {this.props.placeholder}
                            </div>
                            :
                            this.state.vehicles.map((vehicle, i) =>
                                <DivGroupInline key={i}
                                    icon="icon-car-side"
                                    content={this.getVehicleDescription(vehicle)}
                                    obj={vehicle}
                                    choose={!this.state.disabledGas.includes(vehicle.gas_id)}
                                    getSelected={this.vehiclesSelected}
                                    disabledItems={this.state.disabledGas.includes(vehicle.gas_id)}
                                    disabledMsg="Carburant temporairement indisponible"
                                />
                            )
                    }
                <Modal
                    modalId="addVehicleModal"
                    modal={this.state.toggle}
                    toggle={this.toggle}
                    modalHeader="Ajouter un v&eacute;hicule"
                    validationAction={this.validationAction}
                ><CustomerVehicleModal
                    getData={this.getData}
                    toggle={this.toggle}
                    loadVehicles={this.loadVehicles}
                    errors={this.state.errors}
                    showErrors={this.state.showErrors}
                />
                </Modal>
            </div>
        );
    }
}

OrderBlockVehicle.defaultProps = {
    placeholder: "",
    subtitle: ""
};
