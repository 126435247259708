import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Registry } from '../../Utils';
import {Line} from 'react-chartjs-2';
import moment from "moment";
import DeliveredCard from "./DeliveredCard";
import Utils from "../../Utils";
import { SetFCUser, ClearFCUser } from '../../utils/freshchat';

class AccountHome extends Component{
    constructor(props){
        super(props);

        this.loadData = this.loadData.bind(this);
        this.setPeriodView = this.setPeriodView.bind(this);

        this.user = Registry.observe(this, "user", user => {
            this.user = user;
            this.loadData();
            if(user)
                SetFCUser(user);
            else 
                ClearFCUser();
        });

        this.chartOptions = {
            responsive: true
        };

        this.chartOptionsLine = {
            responsive: true,
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        this.state  = {
            loading: false,
            globalStats: {
                sp95_delivered: 0,
                gazole_delivered: 0,
                euro_eco: 0,
                co2_eco: 0
            },
            bar_labels: [],
            bar_gazole_data: [],
            bar_sp95_data: [],
            view: "month",
            sp_price: 0,
            gazole_price: 0
        };

        this.api = Registry.registred("api");
    }

    setPeriodView(view){
        this.setState({view: view}, () => this.loadData(view));
    }

    loadData(){
        if(this.user){
            this.setState({loading: true});

            this.api.get("/stats/customers?period=" + this.state.view)
                .then(results => {
                    let sp_delivered = 0;
                    let gazole_delivered = 0;
                    let euro_eco = 0;
                    let co2_eco = 0;
                    for(let r of results.data){
                        if(r.gas_shorten === "sp95")
                            sp_delivered += r.total_quantity_delivered;
                        else if(r.gas_shorten === "gasole")
                            gazole_delivered += r.total_quantity_delivered;
                        euro_eco += r.euro_eco;
                        co2_eco += r.co2_eco;
                    }
                    this.setState({
                        globalStats: {
                            // avg_gas_price: results.data[0].avg_gas_price,
                            // business_id: results.data[0].business_id,
                            co2_eco: co2_eco,
                            // customer_id: results.data[0].customer_id,
                            eco_gazole: results.data[0].eco_gazole,
                            // eco_sp: results.data[0].eco_sp,
                            euro_eco: euro_eco,
                            // fees: results.data[0].fees,
                            gazole_delivered: gazole_delivered,
                            // nb_order: 0,
                            // nb_vehicles: results.data[0].nb_vehicles,
                            sp95_delivered: sp_delivered,
                            // time_eco: results.data[0].time_eco
                        },
                        loading: false
                    });
                })
                .catch(error => {
                    console.log("ERROR", error);
                    this.setState({loading: false})
                });

            this.api.get("/stats/customers/volumes?period=" + this.state.view)
                .then(results => {
                    let labels = [];
                    let gazole = [];
                    let sp95 = [];

                    for(let d of results.data){
                        let date = moment.unix(d.delivery_datetime);

                        if(this.state.view === "month")
                            labels.push(date.format("MMMM YY"));
                        else if(this.state.view === "year")
                            labels.push(d.c_year);
                        else
                            labels.push("S"+d.c_week);

                        if(d.gazole_delivered)
                            gazole.push(d.gazole_delivered.toFixed(2));
                        if(d.sp_delivered)
                            sp95.push(d.sp_delivered.toFixed(2));
                    }

                    this.setState({
                        bar_labels: labels,
                        bar_gazole_data: gazole,
                        bar_sp95_data: sp95
                    });
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    componentDidMount(){
        if(this.user)
            this.loadData();
    }

    render(){
        return (
            <div className="block-content-account">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                            <div className="col-md-10 col-sm-10 col-xs-10">
                                <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                    Accueil
                                </h3>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2">
                                <button className="uppercase btn btn-white-red bg-white" style={{display: "none"}}>Tutoriel</button>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p className="marg-t-15">Bienvenue dans votre espace personnel, vous allez pouvoir en quelques cliques vous faire livrer votre plein d'essence ou de diesel depuis chez vous, votre travail ...</p>
                        </div>

                        <div className="col-md-12 marg-t-15 marg-b-15">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="title-category">Consommation</p>
                                    <div className="row">
                                        <DeliveredCard name="Gasoil" isLoading={this.state.loading} value={this.state.globalStats.gazole_delivered.toFixed(2) + " L"} fontIcon="icon-drop" fontColor="font-ty-yellow" />
                                        <DeliveredCard name="SP 95" isLoading={this.state.loading} value={this.state.globalStats.sp95_delivered.toFixed(2) + " L"} fontIcon="icon-drop" fontColor="font-ty-green" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p className="title-category">&eacute;conomies</p>
                                    <div className="row">
                                        <DeliveredCard name="Euros" isLoading={this.state.loading} value={this.state.globalStats.euro_eco.toFixed(2) + " €"} fontIcon="icon-economic" fontColor="font-ty-blue" />
                                        <DeliveredCard name="CO2" isLoading={this.state.loading} value={Utils.displayWeights(this.state.globalStats.co2_eco)} fontIcon="icon-green" fontColor="font-ty-blue" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="block-content pad-15">
                                        <div className="col-md-8 col-xs-8 col-sm-8 pad-l-7">
                                            <p className="marg-b-5">Carburant</p>
                                            <div className="uppercase grey pad-b-10 f-11">
                                                <span className={this.state.view === "week" ? "blue" : "cursor"} onClick={() => {this.setPeriodView("week")}}>Semaine</span> |
                                                <span className={this.state.view === "month" ? "blue" : "cursor"} onClick={() => {this.setPeriodView("month")}}> Mois</span> |
                                                <span className={this.state.view === "year" ? "blue" : "cursor"} onClick={() => {this.setPeriodView("year")}}> Ann&eacute;e</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-xs-4 col-sm-4" style={{textAlign: "right" }}>
                                            <p className="f-12 marg-b-5"><span className="rounded-badge-yellow" />&nbsp;&nbsp;{this.state.globalStats.gazole_delivered} L</p>
                                            <p className="f-12"><span className="rounded-badge-green" />&nbsp;&nbsp;{this.state.globalStats.sp95_delivered} L</p>
                                        </div>
                                    <Line data=
                                              {
                                                  {
                                                      labels: this.state.bar_labels,
                                                      datasets: [
                                                          {
                                                              label: "Gasoil",
                                                              fill: false,
                                                              //     backgroundColor: Chart.helpers.color("rgb(241, 196, 15)").alpha(0.5).rgbString(),
                                                              borderColor: 'rgba(241, 196, 15, 1.0)',
                                                              data: this.state.bar_gazole_data
                                                          },
                                                          {
                                                              label: "SP 95",
                                                              fill: false,
                                                              // backgroundColor: Chart.helpers.color("rgb(46, 204, 113)").alpha(0.5).rgbString(),
                                                              borderColor: 'rgb(46, 204, 113)',
                                                              data: this.state.bar_sp95_data
                                                          }
                                                      ]
                                                  }
                                              }
                                          options={this.chartOptionsLine}
                                    />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {/*<p className="title-category">Blog</p>*/}
                                    {/*<p className="f-12 marg-b-10">Pourquoi le prix de votre essence a t'il autant augment&eacute; au 1er janvier ?</p>*/}
                                    {/*<p className="f-12 marg-b-10">Pourquoi le prix de votre essence a t'il autant augment&eacute; au 1er janvier ?</p>*/}
                                    {/*<p className="f-12 marg-b-10">Pourquoi le prix de votre essence a t'il autant augment&eacute; au 1er janvier ?</p>*/}
                                    {/*<div className="col-md-12" style={{textAlign: "right"}}>*/}
                                        {/*<a className="font-ty" href="https://www.facebook.com/TankYou.co"><i className="icon-facebook"> </i></a>*/}
                                        {/*<a className="font-ty" href="https://twitter.com/tankyou_co"><i className="icon-twitter"> </i></a>*/}
                                        {/*<a className="font-ty" href="https://medium.com/tankyou"><i className="icon-medium"> </i></a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountHome);
