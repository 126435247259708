import React, {Component} from 'react';
import './styles.css';

export default class ButtonSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };
    }

    selectAction = () => {
        this.setState({
                selected: !this.state.selected
            }, () => {
                this.props.getElementSelected(this.props.obj, this.state.selected);
                this.props.isSelected();
            }
        );
    };

    render() {
        return (
            <div className="block-button">
                <div className={"button-select " + (this.state.selected ? "border-blue-1" : "border-grey-1")} onClick={this.selectAction}>
                    {
                        this.state.selected ?
                            <div className="button-selected"> </div>
                            :
                            ""
                    }
                </div>
            </div>
        );
    }
}

ButtonSelection.defaultProps = {
    obj: {},
    getElementSelected: () => {}
};
