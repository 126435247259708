const COMMON = {
    "freshchatToken": "dd236894-2724-479c-a251-84c3edef28eb",
    "enterprise_activities": [
      "Agroalimentaire",
      "Banque / Assurance",
      "Bois / Papier / Carton / Imprimerie",
      "BTP / Matériaux de construction",
      "Chimie / Parachimie",
      "Commerce / Négoce / Distribution",
      "Édition / Communication / Multimédia",
      "Électronique / Électricité",
      "Études et conseils",
      "Industrie pharmaceutique",
      "Informatique / Télécoms",
      "Machines et équipements / Automobile",
      "Métallurgie / Travail du métal",
      "Plastique / Caoutchouc",
      "Services aux entreprises",
      "Textile / Habillement / Chaussure",
      "Transports / Logistique"
    ],
    "testimonial_data": [
      {
        "title": "...révolution dans la gestion du carburant",
        "details": "Tankyou facilite la gestion de notre flotte de véhicules de location, une révolution dans la gestion du carburant.",
        "grade": 5,
        "author": "Thibault C.",
        "status": "CEO de Virtuo",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/testimonials/virtuo.jpg",
        "pro": true
      },
      {
        "title": "...faire des économies sur les dépenses de carburant",
        "details": "Tankyou m'a permis de faire des économies sur les dépenses de carburant. Mes chauffeurs peuvent désormais se concentrer uniquement sur leur livraison.",
        "grade": 5,
        "author": "Hassan O.",
        "status": "DG de PSR Logistique",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/testimonials/firstep.jpg",
        "pro": true
      },
      {
        "title": "...gain de temps inestimable",
        "details": "Grâce à Tankyou, je n'ai plus besoin de faire un détour par la station essence en rentrant chez moi, c'est un gain de temps inestimable.",
        "grade": 5,
        "author": "Marie R.",
        "status": "Habitant à Paris",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/testimonials/femme2.jpg",
        "pro": false
      },
      {
        "title": "Commande faite en 2 minutes...",
        "details": "Commande faite en 2 minutes depuis mon espace personnel, Tankyou m'a réconcilié avec la station-service.",
        "grade": 5,
        "author": "Ghislaine B.",
        "status": "Habitant à Lille",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/testimonials/femme1.jpg",
        "pro": false
      },
      {
        "title": "...permet de centraliser la facturation de carburant en évitant les notes de frais",
        "details": "Tankyou est un vrai facilitateur pour nous. Plus besoin de déplacer les véhicules pendant les tournages et cela nous permet de centraliser la facturation de carburant en évitant les notes de frais.",
        "grade": 5,
        "author": "Julien L.",
        "status": "Régisseur chez Firststep Production",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/testimonials/firstep.jpg",
        "pro": true
      },
      {
        "title": "Livré dans la journée...",
        "details": "Livré dans la journée, j'ai pu suivre le plein d'essence de ma voiture à distance pendant que j'étais à mon travail.",
        "grade": 5,
        "author": "Benjamin G",
        "status": "Habitant à Paris",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/testimonials/homme1.jpg",
        "pro": false
      }
    ],
    "press_testi": [
      {
        "title": "",
        "details": "Se faire livrer de l'essence partout, à tout moment et sans avoir à se déplacer, c’est avec Tankyou",
        "grade": "",
        "author": "Le Figaro",
        "status": "",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/lefigaro.png",
        "pro": false
      },
      {
        "title": "",
        "details": "Tankyou, la station-essence à domicile, fait partie de la sélection des 100 start-up où investir en 2018",
        "grade": "",
        "author": "Challenges",
        "status": "",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/challenges.png",
        "pro": false
      },
      {
        "title": "",
        "details": "Se faire livrer son carburant, en toute sécurité, et à un prix compétitif, c’est avec Tankyou",
        "grade": "",
        "author": "BFM Business",
        "status": "",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/bfm.jpg",
        "pro": false
      },
      {
        "title": "",
        "details": "Le service de livraison de carburant, un concept qui fait fureur dans le monde !",
        "grade": "",
        "author": "Capital",
        "status": "",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/capital.png",
        "pro": false
      },
      {
        "title": "",
        "details": "Tankyou, la station-service mobile qui vous ravitaille en carburant à Paris et à Lille",
        "grade": "",
        "author": "Le Monde",
        "status": "",
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/lemonde.png",
        "pro": false
      }
    ],
    "categories_icons": {
      "commande": {
        "img": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/icones/png/blue-dark/responsive.png",
        "alt": "La commande de carburant à domicile | Tankyou"
      },
      "espace-pro": {
        "img": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/icones/png/blue-dark/business.png",
        "alt": "L'espace pro pour gérer votre consommation | Tankyou"
      },
      "général": {
        "img": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/icones/png/blue-dark/gun-petrol.png",
        "alt": "Le service de livraison de carburant | Tankyou"
      },
      "livraison": {
        "img": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/icones/png/blue-dark/truck.png",
        "alt": "La livraison de carburant pour particuliers et professsionels | Tankyou"
      },
      "paiement": {
        "img": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/icones/png/blue-dark/credit-card.png",
        "alt": "Le paiement de vos livraisons de carburant | Tankyou"
      },
      "prix": {
        "img": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/icones/png/blue-dark/economic.png",
        "alt": "La station mobile à prix compétitif | Tankyou"
      }
    },
    "months": [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre"
    ],
    "weekdays_long": [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi"
    ],
    "weekdays_short": [
      "Dim",
      "Lun",
      "Mar",
      "Mer",
      "Jeu",
      "Ven",
      "Sam"
    ],
    "min_hour": 9,
    "max_hour": 20,
    "address_designation": [
      {
        "value": "work",
        "key": "Bureau"
      },
      {
        "value": "home",
        "key": "Maison"
      },
      {
        "value": "other",
        "key": "Autre"
      }
    ],
    "press_release": [
      {
        "date": "2018-12-01",
        "title": "Tankyou et Wheeliz s’associent pour faciliter la mobilité des personnes handicapées",
        "url": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Press/Communique%CC%81+de+Presse+Tankyou+-+Wheeliz.pdf"
      },
      {
        "date": "2018-06-26",
        "title": "Lancement de l'application",
        "url": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Press/Tankyou+-+Lancement+de+l'application+v.2.pdf"
      },
      {
        "date": "2017-12-04",
        "title": "Faites-vous livrer votre carburant à Lille et dans l’agglomération",
        "url": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Press/Tankyou+-+Communique%CC%81+de+presse+(Lille).pdf"
      },
      {
        "date": "2017-09-26",
        "title": "Tankyou lève 1 million d’euros",
        "url": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Press/TY_Communique%CC%81+de+presse.pdf"
      }
    ],
    "in_the_press": [
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/canalplus.png",
        "imageAlt": "Canal+",
        "description": "Tankyou, livreur d'énergie",
        "url": "https://www.dailymotion.com/video/x6ywcb4",
        "isVideo": true
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/frenchweb.png",
        "imageAlt": "Frenchweb",
        "description": "Tankyou révolutionne la distribution de l’énergie",
        "url": "https://www.frenchweb.fr/radar-energie-tankyou-revolutionne-la-distribution-de-lenergie-en-creant-le-futur-de-la-station-service/335974"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/canalplus.png",
        "imageAlt": "Canal+",
        "description": "La station-service du futur Tankyou remporte l’Easy Mobility Challenge 2018",
        "url": "https://detours.canal.fr/tank-you-gagnant-easy-mobility-challenge-2018-organise-canal-seat/"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/maddyness.png",
        "imageAlt": "Maddyness",
        "description": "Les 10 start-ups sélectionnés",
        "url": "https://www.maddyness.com/2018/10/19/easy-mobility-challenge-les-10-startups-selectionnees-pour-revolutionner-la-mobilite-du-futur/"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/europe1.png",
        "imageAlt": "Europe1",
        "description": "L'entreprise qui livre votre carburant",
        "url": "https://www.europe1.fr/emissions/label-entreprise/label-entreprise-du-jour-tankyou-26012018-3556451"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/voixdunord.jpg",
        "imageAlt": "Voix du Nord",
        "description": "Avec Tank you, la livraison de carburant se fait à domicile",
        "url": "http://lavdn.lavoixdunord.fr/276582/article/2017-12-08/avec-tank-you-la-livraison-de-carburant-se-fait-domicile"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/faireface.jpg",
        "imageAlt": "Faire Face",
        "description": "Handicap : plus besoin de se déplacer pour faire le plein",
        "url": "https://www.faire-face.fr/2018/02/07/plein-tankyou-pom-station-service-mobile/"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/lefigaro.png",
        "imageAlt": "Le Figaro",
        "description": "Se faire livrer de l'essence partout, à tout moment et sans avoir à se déplacer, c’est avec Tankyou",
        "url": "http://www.lefigaro.fr/societes/2018/01/02/20005-20180102ARTFIG00173-tank-you-la-start-up-qui-livre-l-essence-la-o-vous-etes.php"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/challenges.png",
        "imageAlt": "Challenges",
        "description": "Tankyou, la station-essence à domicile, fait partie de la sélection des 100 start-up où investir en 2018",
        "url": "https://www.challenges.fr/start-up/tankyou-la-station-d-essence-a-domicile_577753"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/bfm.jpg",
        "imageAlt": "BFM Business",
        "description": "Se faire livrer son carburant, en toute sécurité, et à un prix compétitif, c’est avec Tankyou",
        "url": "http://bfmbusiness.bfmtv.com/mediaplayer/video/hello-startup-tankyou-la-startup-qui-propose-un-service-de-livraison-d-essence-pour-les-particuliers-et-les-professionnels-2010-994011.html"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/capital.png",
        "imageAlt": "Capital",
        "description": "Le service de livraison de carburant, un concept qui fait fureur dans le monde !",
        "url": "https://www.capital.fr/votre-carriere/tank-you-la-start-up-qui-vient-vous-livrer-lessence-1262948"
      },
      {
        "image": "https://s3.eu-west-2.amazonaws.com/tankyou-cdn/public/Front/img/presse/lemonde.png",
        "imageAlt": "Le Monde",
        "description": "Tankyou, la station-service mobile qui vous ravitaille en carburant à Paris et à Lille",
        "url": "http://www.lemonde.fr/economie/article/2017/11/25/la-pompe-a-essence-qui-vous-ravitaille-a-domicile_5220356_3234.htm"
      }
    ]
 };

export { COMMON };