import React, {Component} from 'react';
import './styles.css';

export default class Modal extends Component {
    render() {
        const display = {
            display: 'block'
        };
        const hide = {
            display: 'none'
        };

        return (
            <div id={this.props.modalId} className="modal" style={this.props.modal ? display : hide }>
                <div className="container min-height-container">
                    <div className="col-md-4 col-md-offset-4 col-sm-7 col-sm-offset-3 col-xs-offset-1 col-xs-10 pad-md-0">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span className="close" onClick={this.props.toggle}><i className="icon-cross"> </i> </span>
                                <h3 className="f-19 marg-b-10 blue_dark">{this.props.modalHeader}</h3>
                            </div>
                            <div className="modal-body">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Modal.defaultProps = {
    modalId: "myModal",
    modal: false,
    toggle: () => {},
    modalHeader: "",
    validation: "Ajouter"
};
