let observers = {};


/**
 * Notify function.
 * Use this to shoot events
 *
 * @param event key of triggered event
 * @param data data that will be sent to listener callaback
 */
export function notify(event, data = {}) {
	if (observers.hasOwnProperty(event)) {
		for (const f of observers[event]) f(data);
	}
}

/**
 * Observe function
 * Used to add observer to specific event
 *
 * @param event key of event to listen
 * @param callback Callbak handler for event
 */
export function observe(event, callback) {
	if (!observers.hasOwnProperty(event)) observers[event] = [];
	observers[event].push(callback);
}

/**
 * Used to remove an observer for a particular event
 *
 * @param event key of event to listen
 * @param callback Callbak handler for event
 */
export function stopObserving(event, callback) {
    if (observers.hasOwnProperty(event)) 
        observers[event] = observers[event].filter((fn) => fn !== callback);
}
