import React, {Component} from 'react';
import TYTextField from '../../TYInputs/textField';
import Phone from "react-phone-number-input";

export default class ProfileHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false
        }
    }
    render() {
        return (
            <div className="col-md-12">
                <div className="row marg-b-15">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Nom"
                            value={this.props.data.last_name}
                            hasError={this.props.error.last_name}
                            onChangeText={ (e) => this.props.inputChanged(e, "last_name") }
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Pr&eacute;nom"
                            value={this.props.data.first_name}
                            hasError={this.props.error.first_name}
                            onChangeText={ (e) => this.props.inputChanged(e, "first_name") }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <div
                            className={
                                "input-text-field "
                                + (this.state.focused ? " input-text-focused " : "")
                                + (this.props.error.phone_number ? " input-text-error " : "")
                            }
                        >
                            <label>N&deg; de t&eacute;l&eacute;phone</label>
                            <Phone
                                country={'FR'}
                                value={this.props.data.phone_number}
                                className={"order-big-input blue_dark pad-l-0 "
                                    + (this.state.focused ? "phone-number-focused" : "")
                                    + (this.props.error.phone_number ? " phone-number-error " : "")}
                                onFocus={() => { this.setState({ focused: true }); }}
                                onBlur={() => { this.setState({ focused: false }); }}
                                onChange={ (e) => this.props.inputChanged(e, "phone_number") }
                                style={{marginBottom: "0px"}}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Email"
                            value={this.props.data.email}
                            hasError={this.props.error.email}
                            onChangeText={ (e) => this.props.inputChanged(e, "email") }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ProfileHome.defaultProps = {
    data: {
        last_name: "",
        first_name: "",
        phone_number: "",
        email: ""
    }
};
