import React, {Component} from 'react';
import {Registry, Utils} from "../../../Utils";
import DivGroupInline from "../../DivGroupInline";

export default class OrderBlockSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vehicleGas: [],
            fees: 0,
            fees_type: 0,
            fees_source: "",
            special_offer_label: "",
            gas_prices: []
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        if (this.props.summaryPage) {
            this.getGasPrice();
        }
    }

    getGasPrice = () => {
        this.api.get("/customers/prices" + (this.props.location ? "?location=" + this.props.location : ""))
            .then(res => {
                let gas_prices = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].product_type === "GAS" && res[i].price_ttc !== null) {
                        gas_prices[res[i].name] = {
                            color: res[i].color,
                            price_ttc: res[i].price_ttc
                        };
                    }
                    if (res[i].product_type === "FEE") {
                        this.setState({
                            fees: res[i].price_ttc,
                            fees_type: parseInt(res[i].product_type_id),
                            fees_source: res[i].source,
                            special_offer_label: res[i]["special-offer-label"]
                        });
                    }
                }
                let gasInVehicle = [];
                let vehicles = this.props.vehicle;
                let tmpGas = [];

                for (let i = 0; i < vehicles.length; i++) {
                    if (gasInVehicle.indexOf(vehicles[i].gas_shorten) === -1) {
                        if (tmpGas.indexOf(vehicles[i].gas_name) === -1) {
                            gasInVehicle.push({
                                name: vehicles[i].gas_name,
                                color: gas_prices[vehicles[i].gas_name]["color"],
                                price_ttc: gas_prices[vehicles[i].gas_name]["price_ttc"],
                            });
                            tmpGas.push(vehicles[i].gas_name);
                        }
                    }
                }
                this.setState({
                    gas_prices: gasInVehicle
                });
            })
            .catch(err => {
                this.setState({msgError: "Erreur"});
            })
    };

    getVehicleDescription = (vehicle) => {
        return <div>{vehicle.description}<br/>
            <span className="grey">{vehicle.gas_name} - {vehicle.plate}</span>
        </div>
    };


    getProductDescription = (product) => {
        return <div>{product.product.name}<br/>
            <span className="grey">{product.price} &euro;</span>
        </div>
    };

    getVehicleGas = (gas, i) => {
        return <div key={"gas-"+ i} className="col-md-12 pad-b-15">
            <div className="inline-gas-price">
                <div className="font-ty-round-inline" style={{ backgroundColor: gas.color }}>
                    <i className="icon-drop"> </i>
                </div>
                <div className="pad-l-15">{gas.name}</div>
                <div className="inline-price">{gas.price_ttc} &euro; / L</div>
            </div>
        </div>
    };


    render() {
        return (
            <div className="col-md-12 block-content pad-b-15">
                <p className="underline-title f-18"><i className="font-ty icon-order" style={{paddingLeft: "0px"}}> </i>&nbsp;&nbsp;Votre commande</p>
                <p className="f-14 marg-b-10">{ this.props.location === null ?  "Informations non renseignées" : <span><i className="font-ty-20 icon-address"> </i>{this.props.location}</span> }</p>
                <p className="f-14 marg-b-10">
                    { this.props.selectedDay === null ?  "" : <span><i className="font-ty-20 icon-calendar"> </i>{Utils.formatDate(this.props.selectedDay, "ddd DD MMMM YYYY")}</span> }
                    { this.props.beginHour === null ? "" :  " entre " + this.props.beginHour + "h et " + this.props.endHour + "h" }
                </p>
                <p className="f-14 vehicle-recap marg-t-20">
                    {this.props.products === null ?
                        ""
                        :
                        this.props.products.map((p, i) =>
                            <DivGroupInline
                                key={i}
                                icon="icon-order"
                                content={this.getProductDescription(p)}
                            />
                        )
                    }
                    
                    {this.props.vehicle === null ?
                        ""
                        :
                        this.props.vehicle.map((vehicle, i) =>
                            <DivGroupInline
                                key={i}
                                icon="icon-car-side"
                                content={this.getVehicleDescription(vehicle)}
                            />
                        )
                    }
                </p>
                {
                    this.props.summaryPage ?
                        <div className="f-14">
                            <div className="row marg-t-20">
                                {this.state.gas_prices.map((gas, i) => this.getVehicleGas(gas, i))}
                            </div>
                            <div className="col-md-12 marg-b-15">
                                <div className="row">
                                    <div className="col-md-6 pad-l-0">
                                        Frais de service
                                    </div>
                                    <div className="col-md-6 right pad-r-0">
                                        {
                                            parseInt(this.state.fees) !== 0 ?
                                                <span className="pull-right">
                                                    { this.state.fees_source === "special-offer" && this.state.fees !== 0 ? <span className="line-through marg-r-5">5 &euro;</span> : "" }
                                                    {this.state.fees} &euro;{parseInt(this.state.fees_type) === 0 ? " / véhicule" : ""}
                                                </span>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.fees_source === "special-offer" ?
                                                <div className="green right">{this.state.special_offer_label}</div> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr className="marg-t-15 marg-b-15"/>
                            <div>
                                <i className="font-ty icon-credit-card"> </i> {this.props.payment.metadata.name === null ? "" : this.props.payment.metadata.name} **** {this.props.payment.last4}
                            </div>
                        </div>
                        :
                        ""
                }
            </div>
        );
    }
}

OrderBlockSummary.defaultProps = {
    location: null,
    selectedDay: null,
    beginHour: null,
    endHour: null,
    vehicle: null,
    products: null,
    summaryPage: false
};
