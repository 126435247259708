import React, {Component} from 'react';

class OrderBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayTuto: false
        };

        this.displayTutorial = this.displayTutorial.bind(this);
    }

    displayTutorial() {
        this.setState({ displayTuto: !this.state.displayTuto })
    }


    render() {
        return (
            <div className="row marg-b-15">
                <div className={"col-md-6 block-content-delivery " + (this.props.stepActive === this.props.step ? "delivery-active" : "")}>
                    <div className="row">
                        <div className="col-md-10 col-sm-10 col-xs-10">
                            <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                { this.props.title }
                            </h3>
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-2" style={{ paddingRight: "6px" }} onClick={this.displayTutorial}>
                            <i className="font-ty icon-question cursor"> </i>
                        </div>
                    </div>
                    { this.props.children }
                </div>
                <div className={"col-md-6 " + (!this.state.displayTuto ? "display-none" : "") }>
                    <div className="col-md-12 block-content-blue f-13">
                        <h3 className="tuto-title">
                            <i className="font-ty icon-question"> </i> { this.props.tutoTitle }
                            <i className="cursor right icon-cross marg-t-5" onClick={ this.displayTutorial }> </i>
                        </h3>
                        <p className="marg-b-5 f-13 fw-100"><span>{ this.props.tutoSubtitleOne }</span> { this.props.tutoDescriptionOne } </p>
                        <p className="marg-b-5 f-13 fw-100"><span>{ this.props.tutoSubtitleTwo }</span> { this.props.tutoDescriptionTwo }</p>
                    </div>
                </div>
            </div>
        );
    }
}

OrderBlock.defaultProps = {
    title: "",
    tutoTitle: "",
    tutoSubtitleOne: "",
    tutoSubtitleTwo: "",
    tutoDescriptionOne: "",
    tutoDescriptionTwo: "",
    stepActive: 1,
    step: 1,
    nextStepAction: {}
};

export default OrderBlock;
