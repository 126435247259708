import React, {Component} from 'react';
import { withGoogleMap, GoogleMap, Polygon, Marker } from "react-google-maps";
import {Registry} from "../../Utils";
import DivGroupInlineColor from "../DivGroupInline/DivGroupInlineColor";
import DeliveryZone from "../DeliveryZone";
import Popover from "../Popover";

const MyMapComponent = withGoogleMap(props => {
    return (
        <GoogleMap
            defaultZoom={10}
            defaultCenter={props.center}
            center={props.center}
            ref={props.refHandle}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
            <Polygon
                path={props.coords}
                options={{
                    strokeColor: '#23395b',
                    fillColor: '#71cbf4',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillOpacity: 0.5
                }}
            />
        </GoogleMap>
    );
});

export default class AccountZones extends Component {
    constructor(props) {
        super(props);

        this.api = Registry.registred("api");
        this.user = Registry.registred("user");

        this.mapCompRef = null;
        this.google = window.google;
        this.state = {
            coords: [],
            bounds: [],
            lat: 0,
            lng: 0,
            map: null,
            fees: 0,
            zones: [],
            gas_prices: [],
            products: [],
            fees_type: 0,
            fees_source: "",
            special_offer_label: "",
            msgError: "",
            markerShown: false,
            locationSelected: false
        }
    }

    componentWillMount() {
        this.getZones();
    }

    checkZone = (address) => {
        this.api.get("/orders/checkZone?address=" + address)
            .then(res => {
                this.getPrices(address);
                this.getProducts(res.zone.id);

                this.setState({
                    coords: res.zone.polygon,
                    ...res.geo,
                    msgError: "",
                    markerShown: true,
                    locationSelected: true
                });

                let b = this.getLatLong(res.zone.polygon);

                if(this.mapCompRef){
                    this.mapCompRef.fitBounds(b);
                }
            })
            .catch(err => {
                let msg = "";
                switch (err.subcode) {
                    case "GETOrdersInvalidAddress":
                    default:
                        msg = "L'adresse n'est pas dans la zone de livraison";
                        break;
                }

                this.setState({ msgError: msg, markerShown: false, locationSelected: false });
            })
    };

    getZones = () => {
        this.api.get("/zones")
            .then(res => {
                let zones = res.zones;
                this.setState({
                    lat: zones[0].center.lat,
                    lng: zones[0].center.lng,
                    coords: zones[0].polygon,
                    zones: zones
                })
            })
            .catch(err => {
                console.log(err);
            })
    };

    getLatLong = (polygon) => {
        let latMin = polygon[0].lat;
        let latMax = polygon[0].lat;
        let lngMin = polygon[0].lng;
        let lngMax = polygon[0].lng;

        for (let i = 1; i < polygon.length; i++) {
            if (latMin > polygon[i].lat)
                latMin = polygon[i].lat;
            else if (latMax < polygon[i].lat)
                latMax = polygon[i].lat;
            if (lngMin > polygon[i].lng)
                lngMin = polygon[i].lng;
            else if (lngMax < polygon[i].lng)
                lngMax = polygon[i].lng;
        }

        return new this.google.maps.LatLngBounds(
            new this.google.maps.LatLng(latMin, lngMin),
            new this.google.maps.LatLng(latMax, lngMax)
        );
    };

    showZone = (zoneId) => {
        this.getPrices(zoneId, false);
        this.getProducts(zoneId);

        this.api.get("/zones/" + zoneId)
            .then(res => {
                let zone = res.zone;

                let b = this.getLatLong(zone.polygon);

                if(this.mapCompRef){
                    this.mapCompRef.fitBounds(b);
                }

                this.setState({
                    lat: zone.center.lat,
                    lng: zone.center.lng,
                    coords: zone.polygon,
                    markerShown: false,
                    locationSelected: true
                })
            })
            .catch(err => {
                this.setState({ locationSelected: false });
            })
    };

    getProducts = zone_id => {
        this.setState({products: []});
        this.api.get("/products/zone/" + zone_id)
            .then(products => {
                this.setState({products: products});
            })
    };

    getPrices = (location = null, isLocation = true) => {
        this.api.get("/customers/prices" + (isLocation ? "?location=" : "?zone_id=") + location)
            .then(res => {
                let gas_prices = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].product_type === "GAS" && res[i].price_ttc !== null && res[i].available_to.includes("B2C")) {
                        gas_prices.push({
                            color: res[i].color,
                            name: res[i].name,
                            price_ttc: res[i].price_ttc
                        });
                    }
                    if (res[i].product_type === "FEE") {
                        this.setState({
                            fees: res[i].price_ttc,
                            fees_type: res[i].product_type_id,
                            fees_source: res[i].source,
                            special_offer_label: res[i]["special-offer-label"]
                        });
                    }
                }
                this.setState({
                    gas_prices: gas_prices
                });
            })
            .catch(err => {
                this.setState({msgError: "L'adresse n'est pas dans la zone de livraison"});
            })
    };

    render () {
        return(
            <div className=" col-md-12">
                <div className="row">
                    <div className="block-content pad-t-15 pad-b-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12 pad-b-15">
                                            <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                                Tarifs et Zones
                                            </h3>

                                            <div className="row pad-t-15">
                                                <div className="col-sm-6 col-xs-12">
                                                    <p className="marg-b-15">Tarifs des carburants du jour</p>
                                                    {
                                                        !this.state.locationSelected ?
                                                            <p className="f-14">Pour visualiser les tarifs des carburants du jour, s&eacute;lectionnez une ville ou rentrez une adresse dans les <b>Zones de livraison</b>.</p>
                                                            :
                                                            <div>
                                                                {
                                                                    this.state.gas_prices.length ?
                                                                        this.state.gas_prices.map((gas, i) => {
                                                                            return <DivGroupInlineColor
                                                                                key={i}
                                                                                icon="icon-drop"
                                                                                color={gas.color}
                                                                                content={gas.name}
                                                                                content2={gas.price_ttc}
                                                                            />
                                                                        })
                                                                        :
                                                                        ""
                                                                }
                                                                <p className="f-14 marg-b-15 pad-t-5">
                                                                    Frais de service
                                                                    <i className="pad-l-5 icon-question show-info">
                                                                        <Popover content="Frais de livraison appliqué lors de chaque commande" customClass="fees-description" />
                                                                    </i>
                                                                    {
                                                                        parseInt(this.state.fees) !== 0 ?
                                                                            <span className="pull-right">{this.state.fees} &euro;{parseInt(this.state.fees_type) === 0 ? " / véhicule" : ""}</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                    {  parseInt(this.state.fees) !== 0 ? <br/> : "" }
                                                                    {
                                                                        this.state.fees_source === "special-offer" ?
                                                                            <span className="pull-right green">{ this.state.special_offer_label }</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                </p>

                                                                {
                                                                    this.state.products.length ?
                                                                        <div>
                                                                            <p className="marg-b-15">Produits additionnels</p>

                                                                            {
                                                                                this.state.products.map((p, i) => {
                                                                                    return <div className="fw-300 div-block-content border-grey-1 pad-10">
                                                                                        <div className="pad-5 vertical-align-middle">
                                                                                            { p.product.name }
                                                                                        </div>
                                                                                        <div className="div-block-price">{p.price} &euro;</div>
                                                                                    </div>;
                                                                                })
                                                                            }
                                                                        </div>
                                                                        : ""
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                                <div className="col-sm-6 col-xs-12">
                                                    <p className="marg-b-15">Zones de livraison</p>
                                                    <DeliveryZone
                                                        zones={this.state.zones}
                                                        getZone={this.showZone}
                                                        checkAddress={this.checkZone}
                                                    />
                                                    {
                                                        this.state.msgError !== "" ?
                                                            <div className="marg-t-10 marg-b-10 red f-14">{this.state.msgError}</div>
                                                            :
                                                            ""
                                                    }
                                                    <MyMapComponent
                                                        refHandle={ref => {this.mapCompRef = ref; }}
                                                        loadingElement={<div style={{ height: '100%' }} />}
                                                        containerElement={
                                                            <div className="map-component" />
                                                        }
                                                        mapElement={
                                                            <div className="map-element" />
                                                        }
                                                        bounds={this.state.bounds}
                                                        center={{lat: parseFloat(this.state.lat), lng: parseFloat(this.state.lng)}}
                                                        coords={this.state.coords}
                                                        lat={this.state.lat}
                                                        lng={this.state.lng}
                                                        isMarkerShown={this.state.markerShown}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
