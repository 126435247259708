import React, {Component} from 'react';
import vars from '../../../vars';
import Utils, {Registry} from "../../../Utils";
import RangeSlider from "../../RangeSlider/index";
import ReactDOM from 'react-dom';
import TYCalendarSlider from "../../TYCalendarSlider";
import moment from "moment";

export default class OrderBlockCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: false,
            selectedDay: null,
            min: vars.min_hour,
            max: vars.max_hour,
            beginHour: vars.min_hour,
            endHour: vars.max_hour,
            doNext: true,
            showError: false,
            dateChanged: false,
            disabledDays: "",
            dayChosen: false
        };

        this.dates = [];

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        let hour = this.getHourPlusTwo();
        this.setState({
            min: hour,
            beginHour: hour
        });

        this.getDates();

        document.addEventListener('click', this.closeCalendar, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeCalendar, false)
    }

    toggleCalendar = () => {
        this.setState({showCalendar: true})
    };

    closeCalendar = (e) => {
        if(!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState({showCalendar: false})
        }
    };

    getDates = () => {
        let startDay = moment().subtract(this.props.showDaysBeforeCurrent + 1, 'days');
        let totalDaysCount = this.props.showDaysAfterCurrent + this.props.showDaysBeforeCurrent;

        this.dates = [...Array(totalDaysCount)].map(_ => startDay.add(1, 'day').clone());

        this.getDisabledDays();
    };

    getSunday = () => {
        let sundays = "";
        for (let i = 0; i < this.dates.length; i++) {
            if (this.dates[i].day() === 0) {
                sundays += this.dates[i].format("YYYY-MM-DD");
            }
        }

        return sundays;
    };

    getSelectedDay = (day) => {
        let formatDay = day.format("YYYY-MM-DD");
        let today = moment().format("YYYY-MM-DD");
        let min = 0;
        if (formatDay > today)
            min = vars.min_hour;
        else if (formatDay === today)
            min = this.getHourPlusTwo();

        let dateChanged = false;

        if (this.state.selectedDay !== null)
            dateChanged = (this.state.selectedDay.format("YYYY-MM-DD") !== formatDay);
        else
            dateChanged = true;

        this.setState({
            selectedDay: day,
            dateChanged: dateChanged,
            min: min,
            beginHour: min,
            endHour: vars.max_hour
        })
    };

    valueChanged = (v, key) => {
        let value = Math.round(v);
        if (key === "beginHour") {
            this.setState({dateChanged:false,beginHour: value})
        }
        else if (key === "endHour") {
            this.setState({dateChanged:false,endHour: value})
        }
    };

    getDisabledDays = () => {
        this.api.get("/settings/application")
            .then(res => {
                let today = "";
                let todayTooLate = (vars.max_hour - this.getHourPlusTwo() < 2);
                let sundays = this.getSunday();
                let disabledDays = sundays + res["disabled-days"];

                if (todayTooLate) {
                    today = moment().format("YYYY-MM-DD");
                    disabledDays += "," + today;
                }

                this.setState({disabledDays: disabledDays});
            })
            .catch(err => {
                console.log(err);
            })
    };

    getHourPlusTwo = () => {
        let date = new Date();
        let hour = date.getHours();

        return hour + 2;
    };

    setData = () => {
        if (this.state.selectedDay === null || this.state.selectedDay === undefined) {
            this.setState({showError: true});
            return;
        }
        let data = {
            selectedDay: this.state.selectedDay,
            beginHour: this.state.beginHour,
            endHour: this.state.endHour
        };

        this.setState({
            showCalendar: false,
        }, () => {
            if (this.state.doNext) {
                this.props.goNextBlock();
                this.setState({doNext: false});
            }
            this.props.orderInformations(data);
        });
    };

    beginDate = () => {
        const today = moment();
        const tomorrow = moment().add(1, "days");
        let beginDate = (vars.max_hour - this.getHourPlusTwo() < 2 || this.state.disabledDays.includes(today.format("YYYY-MM-DD"))) ? tomorrow : today;

        return beginDate;
    };

    render() {
        return (
            <div>
                <p className="marg-b-10">{ this.props.subtitle }</p>
                <div className="block-content h-48" onClick={this.toggleCalendar}>
                    {
                        this.state.selectedDay === null ?
                            <div className="col-md-12 pad-15 grey">{this.props.placeholder}</div>
                            :
                            <div>
                                <div className="col-md-7 pad-t-15">{ this.state.selectedDay === null ? "" : Utils.formatDate(this.state.selectedDay, "dddd DD MMMM YYYY")}</div>
                                <div className="col-md-5 pad-t-15 right">{this.state.beginHour === null ? ""  : "entre " + this.state.beginHour + "h et " + this.state.endHour + "h"}</div>
                            </div>
                    }
                </div>
                {
                    this.state.showCalendar ?
                        <div className="col-md-12 pad-0">
                            <div className="dropdown-delivery">
                                <TYCalendarSlider
                                    days={this.dates}
                                    disabledDays={this.state.disabledDays}
                                    getSelectedDay={this.getSelectedDay}
                                    selectedDay={this.state.selectedDay}
                                />
                                <div className="center f-16 marg-t-20 marg-b-20">Cr&eacute;neaux de livraison</div>
                                <div className="slider">
                                    <RangeSlider
                                        getTimeRange={this.valueChanged}
                                        min={(this.beginDate() > moment()) ? vars.min_hour : this.state.min}
                                        max={this.state.max}
                                        dateChanged={this.state.dateChanged}
                                    />
                                </div>

                                {
                                    this.state.showError ?
                                        <div className="row center red marg-b-10 marg-t-15">
                                            Veuillez s&eacute;lectionner une date de livraison
                                        </div>
                                        :
                                        ""
                                }

                                <div className="row center marg-t-15">
                                    <button className="btn-no-border" onClick={this.setData}>Valider</button>
                                </div>
                            </div>
                        </div>
                    :
                    ""
                }
            </div>
        );
    }
}

OrderBlockCalendar.defaultProps = {
    showDaysAfterCurrent: 14,
    showDaysBeforeCurrent: 0
};
