import React, { Component } from "react";
import { Registry } from "../../Utils";
import OrderBlock from "./OrderBlock";
import OrderBlockPayment from "./OrderBlockAction/OrderBlockPayment";


class OrderPayment extends Component{
    constructor(props){
        super(props);

        this.paymentChosen = this.paymentChosen.bind(this);
        this.renderCardItem = this.renderCardItem.bind(this);
        this.addNewPaymentClicked = this.addNewPaymentClicked.bind(this);
        this.paymentMethodAdded = this.paymentMethodAdded.bind(this);
        this.loadData = this.loadData.bind(this);

        this.api = Registry.registred("api");

        this.state = {
            addPaymentMethod: false,
            loading: true,
            cards: [],
            hasCreditCard: false
        };
    }

    componentDidMount(){
        this.loadData();
        window.scrollTo(0,0);
    }

    loadData(){
        this.api.get("/customers/paymentMethods")
            .then(results => {
                if(results.hasOwnProperty("data")){
                    let cards = [];
                    for(let c of results.data){
                        cards.push({
                            id: c.id,
                            metadata: c.metadata,
                            last4: c.last4
                        });
                    }
                    this.setState({loading: false, cards: cards});
                }
            })
            .catch(error => {
                this.setState({loading: false});
            })
    }

    renderCardItem(card){
        return "(" + card.metadata.name + ") **** " + card.last4;
    }

    paymentChosen(payment){
        this.props.onPaymentChosen(payment);
    }

    addNewPaymentClicked(){
        this.setState({addPaymentMethod: true});
    }

    paymentMethodAdded(card){
        this.setState({addPaymentMethod: false});
        this.loadData();
    }

    updateCreditCard = (bool) => {
        this.setState({
            hasCreditCard: bool
        })
    };

    render(){
        return (
            <div>
                <OrderBlock
                    title="Moyen de paiement"
                    tutoTitle="Paiement"
                    tutoDescriptionOne="Vous ne serez d&eacute;bit&eacute; qu'apr&egrave;s la livraison, en fonction de la quantit&eacute; de carburant qui vous a &eacute;t&eacute; livr&eacute;e."
                >
                    <OrderBlockPayment
                        subtitle="Carte bancaire"
                        placeholder="Aucune carte bancaire renseign&eacute;e"
                        orderInformations={this.props.orderInformations}
                        hasCreditCard={this.updateCreditCard}
                    />
                </OrderBlock>
                <div className="row">
                    <div className="col-md-6 center marg-t-15">
                        {
                            this.state.hasCreditCard ?
                                <button className="btn_primary" onClick={this.props.nextStep}>Valider</button>
                                :
                                <button className="btn-disable">Valider</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

OrderPayment.defaultProps = {
    onPaymentChosen: payment => {}
};

export default OrderPayment;
