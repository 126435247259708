import React, {Component} from 'react';
import Utils, {Registry} from "../../../Utils";
import DivGroupInline from "../../DivGroupInline";

export default class CancelationModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showVehicles: false,
            commentFocused: false,
            comment: "",
            errorCancelation: false,
            errorMessage: ""
        }

        this.api = Registry.registred("api");
    }

    toggleVehicle = () => {
        this.setState({
            showVehicles: !this.state.showVehicles
        });
    };

    getVehicleDescription = (vehicle) => {
        return <div>{vehicle.description}<br/>
            <span className="fw-100">{vehicle.gas_name} - {vehicle.plate}</span>
        </div>
    };

    inputChanged = (e, input_key) => {
        if(this.state.hasOwnProperty(input_key) && typeof e !== "undefined"){
            let nState = this.state;

            nState[input_key] = (typeof e === "string" ? e : e.target.value);

            this.setState(nState);
        }
    };

    cancelOrder = () => {
        this.api.put("/orders/" + this.props.data.id + "/requestCancel", {comment: this.state.comment})
            .then(res => {
                this.setState({toggle: false, errorCancelation: false, errorMessage: ""});
                this.props.loadOrders("waiting", 0);
            })
            .catch(err => {
                console.log(err);
                if (err.subcode === "PUTOrdersRequestEmpty" || err.subcode === "PUTOrdersEmptycomment") {
                    this.setState({
                        errorCancelation: true,
                        errorMessage: "Veuillez saisir un message"
                    });
                }
            })
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12 marg-b-15">
                    <div className="block-content-account f-14 pad-l-15 pad-r-15">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="medium f-14">Date & lieu</span>
                                <div className="pad-b-10 pad-t-10">
                                    <i className="font-ty icon-calendar" style={{paddingLeft: "0px"}}> </i>
                                    {
                                        this.props.data.status === "waiting" ?
                                            Utils.formatDate(this.props.data.date_min, "ddd DD MMMM YYYY [entre] HH[h]mm") + " et " + Utils.formatDate(this.props.data.date_max, "HH[h]mm")
                                            :
                                            Utils.formatDate(this.props.data.start_datetime, "ddd DD MMMM YYYY [à] HH[h]mm")
                                    }
                                </div>
                                <div className="pad-b-10">
                                    <i className="font-ty icon-address" style={{paddingLeft: "0px"}}> </i> { this.props.data.location }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div>
                                    <span className="medium f-14">V&eacute;hicule(s) : </span> {this.props.data.vehicles.length} {this.props.data.vehicles.length <= 1 ? "véhicule" : "véhicules"}
                                    <i className={"right font-ty icon-chevron-" + (!this.state.showVehicles ? "down" : "top")} onClick={() => this.toggleVehicle() }> </i>
                                </div>
                                {
                                    !this.state.showVehicles ?
                                        ""
                                        :
                                        this.props.data.vehicles.map((vehicle, i) =>
                                            <DivGroupInline key={i}
                                                            icon="icon-car-side"
                                                            content={this.getVehicleDescription(vehicle)}
                                                            obj={vehicle}
                                                            command={true}
                                            />
                                        )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className={"f-14 "
                    + (this.state.commentFocused ? "blue" : "")
                    + (this.state.errorCancelation ? "red" : "")
                    }>Message</label>
                    <textarea name="message"
                              onChange={e => this.inputChanged(e, "comment")}
                              onFocus={() => this.setState({commentFocused: true}) }
                              onBlur={() => this.setState({commentFocused: false}) }
                              className={"comment-field marg-t-10 "
                              + (this.state.commentFocused ? "border-blue-1" : "")
                              + (this.state.errorCancelation ? "border-red-1" : "")}
                              rows="3" value={this.state.comment} required/>

                </div>
                {
                    this.state.errorCancelation ?
                        <div className="col-md-12 red marg-t-10">
                            {this.state.errorMessage}
                        </div>
                        :
                        ""
                }
                <div className="col-md-12 center marg-t-20">
                    <button className="btn_primary" onClick={() => this.cancelOrder() }>Demander l'annulation</button>
                </div>
            </div>
        );
    }
}
