import React, {Component} from 'react';
import OrderBlockSummary from "./OrderBlockAction/OrderBlockSummary";

export default class OrderSummary extends Component {

    getPromotionalCode = (code) => {
        this.props.getPromotionalCode(code);
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-6 pad-0">
                    <div className="block-content-delivery delivery-active col-md-12">
                        <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">R&eacute;capitulatif</h3>
                        <OrderBlockSummary
                            location={this.props.data.location}
                            selectedDay={this.props.data.selectedDay}
                            beginHour={this.props.data.beginHour}
                            endHour={this.props.data.endHour}
                            vehicle={this.props.data.vehicle}
                            products={this.props.data.products}
                            payment={this.props.data.payment}
                            summaryPage={true}
                        />
                        <div className="col-md-12 marg-t-15">
                            <div className="row">
                                {
                                    this.props.data.promo_success ?
                                        <div className="promo-block-success">
                                            {this.props.getPromotionalCodeDetail(this.props.data.promo)}
                                            <div className="underline right cursor h-5" onClick={() => this.props.cancelPromo() }>Annuler</div>
                                        </div>
                                        :
                                        <div className="input-group">
                                            <input type="text" placeholder="Code promotionnel" className="custom-input-group form-control" value={this.props.data.promotionalCode} onChange={(e) => this.props.updatePromotionalCode(e.target.value)} />
                                            <div className="input-group-addon underline" onClick={() => {this.props.getPromotionalCode(this.props.data.promotionalCode)}}>Valider</div>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            this.props.data.error ?
                                <div className="col-md-12 marg-t-10 red">
                                    <div className="row">
                                        {this.props.data.error_msg}
                                    </div>
                                </div>
                                :
                                ""
                        }
                    </div>
                    <div className="col-md-12 marg-t-15">
                        <textarea className="comment-field" rows="6" placeholder="Écrire un commentaire" value={this.props.data.comments} onChange={(e) => this.props.updateComment(e.target.value)}> </textarea>
                    </div>
                    <div className="col-md-12 marg-t-30 center">
                        {
                            this.props.loading ?
                                <button className="btn_primary"><i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i></button>
                                :
                                <button className="btn_primary" onClick={this.props.nextStep}>Commander</button>
                        }
                    </div>
                </div>

            </div>
        );
    }
}

OrderSummary.defaultProps = {
    data: {
        location: "",
        selectedDay: "",
        beginHour: "",
        endHour: "",
        vehicle: "",
        products: [],
        payment: "",
        promotionalCode: "",
    },
    getPromotionalCode: () => {}
};
