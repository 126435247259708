import  React, { Component } from 'react';
import {
    withRouter
} from 'react-router-dom';
import {Registry, AppLink} from '../Utils';


class AccountVerified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            success: false,
            loading: false,
            isMobile: false,
            customer: {}
        };

        this.api = Registry.registred("api");
    }

    componentDidMount(){
        this.setState({loading: true}, () => this.checkRedirection());
    }    

    checkRedirection = () => {
        let token = this.props.match.params.token;
        let noApp = this.props.match.params.noApp;
        if(!noApp && AppLink.isMobile()){
            this.setState({isMobile: true});
            AppLink.goAppLink("activate", token, {
                storeLink: window.location.href + "/1"
            });
        }
        else
            this._activateAccount();
    };

    _activateAccount = () => {
        let token = this.props.match.params.token;
        this.setState({loading: true});
        this.api.get("/customers/verify?token=" + token)
            .then(result => this.setState({customer: result.result, error: false, success: true, loading: false}))
            .catch(_ => this.setState({error: true, success: false, loading: false}));
    };

    render() {
        return (
            <div className="bg-blue_dark " style={{minHeight: "100vh"}} id="app-link">
                <div>
                    {/*<Header />*/}
                    <div className="pad-t-100">
                        <div className="background-contain w-200 pad-t-100"
                                style={{margin: "0 auto", backgroundImage: "url('"+require('../images/TankYou-v2-white.png')+"')"}}
                        >
                        </div>
                    </div>
                    <div className="ty-title-white ty-title-after-blue">
                        Bienvenue chez Tankyou
                    </div>
                    <div className="marg-t-20 col-md-4 col-md-offset-4 left pad-b-100">
                        <div className="bg-white relative pad-30 border-grey-1 blue_dark border-rad-5 center marg-md-t-25 marg-25">
                            {
                                this.state.loading ?
                                    <div className="grey">
                                        Votre compte est en cours d'activation. Veuillez patienter ...
                                    </div>
                                    : null
                            }

                            {
                                this.state.success ?
                                    <div className="green">
                                        Votre compte a bien &eacute;t&eacute; activ&eacute;
                                    </div>
                                    : null
                            }

                            {
                                this.state.error ?
                                    <div>
                                        <div className="red">
                                            Une erreur s'est produite lors de l'activation de votre compte.
                                            Merci de bien vouloir r&eacute;essayer dans quelques minutes.
                                        </div>
                                        <div className="blue_dark marg-t-20 f-14">
                                            Si le problème persiste, contactez-nous.
                                        </div>
                                    </div>
                                    : null
                            }

                            {
                                this.state.success ?
                                    <div className="center">
                                        {
                                            this.state.customer.business_id ?
												<a href="https://dashboard.tankyou.co" className="btn_primary btn_large marg-t-25 marg-l-15 uppercase cta-min-height">
													<i className="font-ty icon-business marg-r-15" />
													Espace professionnel
												</a>
                                                :
												<a href="/connexion" className="btn_primary btn_large marg-t-25 uppercase cta-min-height">
													<i className="font-ty icon-user marg-r-15" />
													Espace perso
												</a>
                                        }
                                    </div> : null
                            }
                        </div>

                    </div>
                    <div className="clear"> </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AccountVerified);
