import React, { Component } from "react";
import { Registry } from "../../Utils";
import {
    injectStripe
} from "react-stripe-elements";


class AccountPaymentCard extends Component{
    constructor(props){
        super(props);

        this.api = Registry.registred("api");
        this.stripe = Registry.registred("stripe");
        this.user = Registry.observe(this, "user", user => {
            this.user = user;
        });

        this.inputChanged = this.inputChanged.bind(this);
        this.deleteCard = this.deleteCard.bind(this);

        this.state = {
            loading: false,
            loadingDeletion: false,
            success_msg: null,
            card_name: "",
            id: this.props.card.id,
            last4: this.props.card.last4,
            metadata: this.props.card.metadata,
            expirationDate: this.props.card.exp_month + "/" + this.props.card.exp_year
        }
    }

    inputChanged(e, key){
        let s = this.state;
        if(s.hasOwnProperty(key) && typeof e !== "undefined"){
            s[key] = (typeof e === "string" ? e : e.target.value);
            this.setState(s);
        }
    }

    deleteCard(){
        this.setState({loadingDeletion: true});
        this.api.del("/customers/paymentMethods", {card_id: this.state.id})
            .then(() => {
                this.props.onDeletedCard(this);
                this.props.msgError("");
            })
            .catch(error => {
                this.setState({
                    loadingDeletion: false
                }, this.props.msgError("Cette carte est utilisé pour une commande en cours vous ne pouvez la supprimer"));
            });
    }

    render(){
        return (
            <div className="marg-b-10">
                <div className="div-block-content border-grey-1 command">
                    <div className="div-block-logo">
                        <i className="font-ty icon-credit-card"> </i>
                    </div>
                    <div className="pad-5 vertical-align-middle f-14">
                        **** {this.state.last4}<br/>
                        <span className="grey">{this.state.expirationDate}</span>
                    </div>
                    <div className="pad-r-10 vertical-align-middle">
                    {
                        this.state.loadingDeletion ?
                            <i className="fa fa-spinner pull-right fa-spin fa-fw"> </i>
                            :
                            <i className="icon-cross pull-right blue_dark-hover cursor" onClick={this.deleteCard} aria-hidden="true"> </i>
                    }
                    </div>
                </div>
            </div>
        );
    }
}

AccountPaymentCard.defaultProps = {
    onDeletedCard: card => {},
    card: {
        id: null,
        last4: "",
        metadata: {name: ""},
        expiration: ""
    }
};

export default injectStripe(AccountPaymentCard);
