import React, {Component} from 'react';
import './styles.css';
import Utils from "../../Utils";

export default class DeliveryZone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggle: false,
            location: "",
            locations: []
        }
    }

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        });
    };

    updateLocations = locations => {
        this.setState({
            locations: locations
        })
    };

    setLocation = location => {
        this.setState({
            location: location.main_text + ", " + location.secondary_text,
            locations: []
        }, () => this.props.checkAddress(this.state.location));
    };

    render () {
        return (
            <div className="col-sm-12">
                <div className="row">
                    <div className="div-block-content">
                        <div>
                            <input
                                className="input-delivery-zone"
                                placeholder="Adresse, Ville ..."
                                onChange={ e => {
                                    if(typeof e.target.value !== "undefined" && e.target !== null){
                                        let value = e.target.value;

                                        this.setState({location: value}, () => {Utils.getGeoSuggest(value, this.updateLocations)});
                                    }
                                }}
                                value={this.state.location}
                            />
                            {
                                this.state.locations.length === 0 ?
                                    ""
                                    :
                                    <div className="col-sm-12">
                                        <div className="row dropdown-delivery">
                                            {
                                                this.state.locations.length ?
                                                    this.state.locations.map((location, i) =>
                                                        <div key={i} className="list-address" onClick={() => { this.setLocation(location); }}>
                                                            <div className="w-40 vertical-align-middle"><i className="font-ty pad-l-0 icon-address"> </i></div>
                                                            <div className="pad-l-10">{location.main_text}<br/><span className="grey">{location.secondary_text}</span></div>
                                                        </div>
                                                    )
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className={(this.state.toggle ? "div-block-is-dropdown" : "div-block-dropdown")}
                             onClick={this.toggle}
                        >
                            <i className={"vertical-align-middle icon-chevron-" + (this.state.toggle ? "top" : "down")}> </i>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            {
                                this.state.toggle ?
                                    <div className="dropdown-zone">
                                        {
                                            this.props.zones.map((zone, i) => {
                                                return <div key={i} className="list-address cursor">
                                                    <div className="w-40 vertical-align-middle">
                                                        <i className="font-ty pad-l-0 icon-address"> </i>
                                                    </div>
                                                    <div className="pad-l-10 vertical-align-middle"
                                                         onClick={() => {this.props.getZone(zone.id); this.toggle()}}
                                                    >Zone {zone.name}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    :
                                    ""
                            }

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

DeliveryZone.defaultProps = {
    zones: [],
    getZone: () => {}
};
