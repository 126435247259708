import React, { Component } from "react";

import "./styles.css";


export default class Marker extends Component{
    constructor(props){
        super(props);

        this.state = {
            triggered: false,
            position: 0,
            markerPosition: 0
        };
    }

    mouseDown = e => {
        this.props.onMouseToggle(true, e);
        this.setState({triggered: true});
    };

    mouseUp = e => {
        this.props.onMouseToggle(false, e);
        this.setState({triggered: false});
    };

    mouseOver = e => {
        this.setState({ triggered: true })
    };

    mouseOut = e => {
        this.setState({ triggered: false })
    };


    render(){
        return (
            <div
                className="slider-trigger"
                onMouseDown={ this.mouseDown }
                onMouseUp={ this.mouseUp }
                onMouseOut={ this.mouseOut }
                onMouseOver={ this.mouseOver }
                style={{
                    left: this.props.positionX,
                    width: this.props.markerSize,
                    height: this.props.markerSize
                }}
            >
                <div className={"slider-value " + (this.props.left ? "marg-l-5" : "marg-l-neg-5" )}>{Math.round(this.props.value)} h</div>
                <div className="slider-button"> </div>
            </div>
        );
    }
}

Marker.defaultProps = {
    positionX: 0,
    value: 0,
    markerSize: 20,
    left: true,
    onMouseToggle: () => {}
};
