import React, {Component} from 'react';
import "./styles.css";
import ButtonSelection from "../ButtonSelection";

export default class DivGroupInline extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        }
    }

    elementSelected = (element, add=true) => {
        return this.props.getSelected(element, add);
    };

    isSelected = () => {
        this.setState({
            selected: !this.state.selected
        })
    };

    render() {
        return (
            <div className={"div-block-content " + (this.state.selected ?  "border-blue-1" : "border-grey-1") + (this.props.command ? " command" : "")}
                onClick={this.props.showModal ? this.props.showModal : undefined}
            >
                <div className="div-block-logo">
                    <i className={"font-ty " + this.props.icon}> </i>
                </div>
                <div className="pad-5 vertical-align-middle">
                    {this.props.content}
                </div>
                {
                    this.props.choose ?
                        <ButtonSelection
                            obj={this.props.obj}
                            getElementSelected={this.elementSelected}
                            isSelected={this.isSelected}
                        />
                        :
                        ""
                }
                {
                    this.props.update ?
                        <div className="update-content">
                            <i className="icon-edit cursor" onClick={() => {this.props.updateAction(true, this.props.obj.id); this.props.toggle(); }}> </i>
                        </div>
                        :
                        ""
                }
                {
                    this.props.disabledItems ?
                        <div className="disabled-msg-overlay">
                            <div className="disabled-msg">{this.props.disabledMsg}</div>
                        </div>
                        :
                        ""
                }
            </div>
        );
    }
}

DivGroupInline.defaultProps = {
    icon: "",
    content: "",
    obj: {},
    choose: false,
    update: false,
    command: false,
    getSelected: () => {},
    updateAction: () => {},
    toggle: () => {},
    showModal: false,
    disabledItems: false,
    disabledMsg: ""
};
