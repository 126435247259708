import React, { Component } from 'react';
import { Utils, Registry } from '../../Utils.js';
import {
    withRouter
} from 'react-router-dom';
import { SignOut } from "@TaankYou/auth";

class HeaderBurger extends Component {
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        let user = Registry.observe(this, "user", user => {
            this.setState({user:user});
        });

        this.state = {
            user: user,
            displayMenu: "none"
        };

        this.logout = this.logout.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

    }

    toggleMenu(){
        if(this.state.displayMenu === "block"){
            this.setState({displayMenu: "none"});
        }else{
            this.setState({displayMenu: "block"});
        }
    }

    logout(){
        Registry.remove("user");
        const { protocol, host } = window.location;
        SignOut(`${protocol}//${host}`);
    }

    componentDidMount(){
        let user = Registry.registred("user");
        if(user)
            this.setState({user: user});
    }

    render() {
        return (
            <div className={ this.state.displayMenu === "block" ? "scrollable bg-white" : ""}>
                <div className={"wp-100 bg-" + this.props["background-color"] + " f-14 left display_below-md "}>
                    <div className="">
                        <div className="h-60">
                            {
                                this.state.displayMenu === "block" ?
                                    <div className={"pull-right marg-t-10 marg-r-20 " + this.props["text-color"]}>
                                        <img alt="close" className="cursor" src={require("../../images/close.png")} height="33px" width="33px" onClick={() => this.toggleMenu()}/>
                                    </div> :
                                    <div id="nav-icon2" style={{zIndex: "1000"}}
                                         className={"navBurger marg-r-20 " + this.props["text-color"]}
                                         onClick={() => this.toggleMenu()}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                            }

                            <a href="/" className="cursor marg-l-30 background-contain-center h-66 w-80 f-32 lh-66 display-inline-block" style={{backgroundImage: 'url("'+this.props.backgroundImage+'")'}}>
                                &nbsp;
                            </a>
                        </div>
                    </div>

                    <div className="relative">
                        <div className=" header-content h-full bg-grey_light"  style={{display: this.state.displayMenu}}>
                            <div>
                                {
                                    this.state.user ?
                                        <div className="bg-white pad-t-10 pad-b-10 ">
                                            <div>
                                                <div className="relative center uppercase pad-5 blue_dark">
                                                    {
                                                        "Bonjour " + this.state.user.first_name + " !"
                                                    }
                                                </div>
                                                <div className="absolute top-15 right-30">
                                                    <i className="f-17 icon-log-out cursor pink-hover blue_dark pad-5"
                                                       aria-hidden="true" onClick={() => this.logout()}
                                                    > </i>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="center pad-10">
                                                    <a href="/commander" className="btn_primary lh-25">
                                                        <span className="pad-l-10 pad-r-10">
                                                            Commander
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div onClick={()=> Utils.goTo(this, "/mon-compte/accueil")} className="center underline primary_link f-12">
                                                <span className="pad-5">
                                                    Mon espace perso
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="bg-white pad-t-10 pad-b-10">
                                            <div>
                                                <div className="center uppercase pad-5 blue_dark">
                                                    Bonjour !
                                                </div>
                                            </div>
                                            <div>
                                                <div className="center pad-10">
                                                    <a href="/connexion" className="btn_primary lh-25">
                                                        <span className="pad-l-10 pad-r-10">
                                                            Se connecter
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="center pad-10 pad-t-5">
                                                <a href="/inscription" className="underline primary_link f-12">
                                                    <span className="pad-5">
                                                        Créer mon compte
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className="center_tablet border-t-grey-1">
                                <div className="center marg-l-20 marg-md-l-0 pad-t-10">
                                    <a href="/" className="header-burger-item pink-hover blue_dark">
                                        Home
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0 ">
                                    <a href="/livraison-carburant-professionnels" className="header-burger-item pink-hover blue_dark">
                                        Tankyou pour les professionnels
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-carburant-collectivites" className="header-burger-item pink-hover blue_dark">
                                        Tankyou pour les collectivit&eacute;s
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-carburant-particuliers" className="header-burger-item pink-hover blue_dark">
                                        Tankyou pour les particuliers
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-energie-gnv" className="header-burger-item pink-hover blue_dark">
                                        GNV
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-energie-gnr" className="header-burger-item pink-hover blue_dark">
                                        GNR
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-energie-adblue" className="header-burger-item pink-hover blue_dark">
                                        Adblue
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-energie-diesel" className="header-burger-item pink-hover blue_dark">
                                        Diesel
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="/livraison-energie-essence" className="header-burger-item pink-hover blue_dark">
                                        Essence
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="https://blog.tanyou.co" rel="noopener noreferrer" target="_blank" className="header-burger-item pink-hover blue_dark">
                                        Blog
                                    </a>
                                </div>
                                <div className="center marg-l-20 marg-md-l-0">
                                    <a href="https://www.welcometothejungle.com/fr/companies/tankyou/jobs" rel="noopener noreferrer" target="_blank" className="header-burger-item pink-hover blue_dark">
                                        Recrutement
                                    </a>
                                </div>
                                <div className="border-b-grey-1 pad-t-20 marg-l-30 marg-r-30">
                                    &nbsp;
                                </div>
                            </div>
                                <div className="center pad-20">
                                    <div className="lh-35 blue_dark">
                                        <i className="icon-phone" aria-hidden="true"></i> 01 86 76 08 88
                                    </div>
                                </div>
                                <div className="pad-b-50">
                                    &nbsp;
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HeaderBurger.defaultProps = {
    "text-color": "blue_dark",
    "background-color": "white",
    "backgroundImage": require("../../images/TankYou-v2.png")
};

export default withRouter(HeaderBurger);
