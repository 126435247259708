import React, {Component} from 'react';
import {Registry} from "../../../Utils";

export default class CustomerVehicleModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: this.props.modal,
            gas: [],
            data: {
                brand: "",
                model: "",
                color: "",
                gas_id: "",
                plate: ""
            },
            errors: {
                brand: false,
                model: false,
                color: false,
                gas_id: false,
                plate: false
            },
            msgErrors: {
                brand: "La marque du véhicule doit être renseignée",
                model: "Le modèle du véhicule doit être renseigné",
                color: "La couleur du véhicule doit être renseignée",
                gas_id: "Le carburant du véhicule doit être renseigné",
                plate: "La plaque d'immatriculation doit être renseignée"
            },
            showErrors: false
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        this.getGas();
    }

    componentWillReceiveProps(nextProps) {
        this.resetData();
        if (nextProps.vehicle !== null) {
            this.api.get("/fleet/" + nextProps.vehicle)
                .then(res => {
                    let fleet = res.fleet;
                    this.setState({
                        data: {
                            brand: fleet.description.split(/, (.+)?/)[0],
                            model: fleet.description.split(/, (.+)?/)[1],
                            color: fleet.color,
                            gas_id: fleet.gas_id,
                            plate: fleet.plate
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            this.resetData();
        }
    }

    getGas = () => {
        this.api.get("/gas")
            .then(res => {
                let gasList = [];
                let gas = res.gas;

                for (let i = 0; i < gas.length; i++) {
                    gasList.push({
                        id: gas[i].id,
                        name: gas[i].name
                    })
                }
                this.setState({ gas: gasList });
            })
    };

    resetData = () => {
        this.setState({
            data: {
                brand: "",
                model: "",
                color: "",
                gas_id: "",
                plate: ""
            },
            errors: {
                brand: false,
                model: false,
                color: false,
                gas_id: false,
                plate: false
            },
            showErrors: false
        });
    };

    inputChanged = (e, input_key) => {
        if(this.state.data.hasOwnProperty(input_key)){
            let nState = this.state.data;
            nState[input_key] = (typeof e === "string" ? e : e.target.value);
            this.setState(nState);
        }
    };

    deleteVehicle = () => {
        this.api.del("/fleet/" + this.props.vehicle)
            .then(res => {
                this.props.loadVehicles();
                this.props.toggle();
            })
            .catch(err => {
                console.log(err);
            })
    };

    showMessageError = () => {
        if (this.state.errors.brand) {
            return this.state.msgErrors.brand;
        } else if (this.state.errors.model) {
            return this.state.msgErrors.model;
        } else if (this.state.errors.color) {
            return this.state.msgErrors.color;
        } else if (this.state.errors.gas_id) {
            return this.state.msgErrors.gas_id;
        } else if (this.state.errors.plate) {
            return this.state.msgErrors.plate;
        }
    };

    vehicleValidation = () => {
        let nState = this.state;
        let error  = false;

        for(let k in this.state.data){
            if((this.state.data.hasOwnProperty(k) && (this.state.data[k] === undefined || this.state.data[k] === "" || (/^\s*$/).test(this.state.data[k])))){
                nState.errors[k] = true;
                error = true;
                nState.showErrors = true;
            }
        }
        this.setState(nState);

        if(!error) {
            let data = {
                plate: this.state.data.plate,
                description: this.state.data.brand + ", " + this.state.data.model,
                color: this.state.data.color,
                gas_id: this.state.data.gas_id
            };

            if (this.props.isUpdated) {
                this.api.put("/fleet/" + this.props.vehicle, data)
                    .then(res => {
                        this.props.loadVehicles();
                        this.props.toggle();
                        this.resetData();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.api.post("/fleet", data)
                    .then(res => {
                        this.props.loadVehicles();
                        this.props.toggle();
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    };

    render() {
        return(
            <div className="row">
                <div className="col-md-12 marg-b-5">
                    <div className="row">
                        <div className="col-sm-6 marg-sm-b-10">
                            <input className={"blue_dark wp-100 border-rad-4 marg-t-25 " + ((this.state.showErrors && this.state.errors.brand) ? "border-red-1" : "")}
                                   placeholder="Ex: Citroën"
                                   value={this.state.data.brand}
                                   onChange={e => this.inputChanged(e, "brand") }
                            />
                            <label className={"blue_dark custom-label-control" + ((this.state.showErrors && this.state.errors.brand) ? " red" : "")}>Marque</label>
                        </div>
                        <div className="col-sm-6 marg-sm-b-10">
                            <input className={"blue_dark wp-100 border-rad-4 marg-t-25 " + ((this.state.showErrors && this.state.errors.model) ? "border-red-1" : "")}
                                   placeholder="Ex: C3"
                                   value={this.state.data.model}
                                   onChange={e => this.inputChanged(e, "model") }
                            />
                            <label className={"blue_dark custom-label-control" + ((this.state.showErrors && this.state.errors.model) ? " red" : "")}>Modèle</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 marg-b-5">
                    <div className="row">
                        <div className="col-sm-6 marg-sm-b-10">
                            <select className={"custom-select-control marg-t-25 " + ((this.state.showErrors && this.state.errors.color) ? "border-red-1" : "")}
                                    value={this.state.data.color}
                                    onChange={e => {this.inputChanged(e, "color")}}>
                                <option value="">S&eacute;lectionner</option>
                                <option value="Blanc">Blanc</option>
                                <option value="Noir">Noir</option>
                                <option value="Gris">Gris</option>
                                <option value="Jaune">Jaune</option>
                                <option value="Rouge">Rouge</option>
                                <option value="Bleu">Bleu</option>
                                <option value="Vert">Vert</option>
                                <option value="Orange">Orange</option>
                                <option value="Violet">Violet</option>
                            </select>
                            <label className={"blue_dark custom-label-control" + ((this.state.showErrors && this.state.errors.color) ? " red" : "")}>Couleur</label>
                        </div>
                        <div className="col-sm-6 marg-sm-b-10">
                            <select className={"custom-select-control marg-t-25 " + ((this.state.showErrors && this.state.errors.gas_id) ? "border-red-1" : "")}
                                    value={this.state.data.gas_id}
                                    onChange={e => {this.inputChanged(e, "gas_id")}}>
                                <option value="">S&eacute;lectionner</option>
                                {
                                    this.state.gas.length ?
                                        this.state.gas.map((gas, i) => {
                                            return <option key={"gas-" + i} value={gas.id}>{gas.name}</option>
                                        })
                                        :
                                        ""
                                }
                            </select>
                            <label className={"blue_dark custom-label-control" + ((this.state.showErrors && this.state.errors.gas_id) ? " red" : "")}>Carburant</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <input className={"blue_dark wp-100 border-rad-4 marg-t-25 " + ((this.state.showErrors && this.state.errors.plate) ? "border-red-1" : "")}
                                   placeholder="Ex: AB 123 XZ"
                                   value={this.state.data.plate}
                                   onChange={e => this.inputChanged(e, "plate") }
                            />
                            <label className={"blue_dark custom-label-control" + ((this.state.showErrors && this.state.errors.plate) ? " red" : "")}>Plaque d'immatriculation</label>
                        </div>
                        {
                            this.props.isUpdated ?
                                <p className="dark-blue-light right pad-t-5 pad-r-15 cursor f-12" onClick={() => this.deleteVehicle()}>Supprimer ce v&eacute;hicule</p>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className="col-md-12 marg-t-10 marg-b-10 red">
                    {
                        this.state.showErrors ? this.showMessageError() : ""
                    }
                </div>

                <div className="center">
                    <button className="medium btn_primary marg-t-10 lh-20" onClick={() => this.vehicleValidation()}>{this.props.isUpdated ? "Modifier" : "Ajouter"}</button>
                </div>
            </div>
        );
    }
}

CustomerVehicleModal.defaultProps = {
    vehicle: null,
    isUpdated: false,
    loadVehicles: () => {}
};
