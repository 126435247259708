import React, { Component } from 'react';


class DeliveredCard extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="col-md-6 col-sm-6 col-xs-6">
                <div className="block-content center pad-t-20 pad-b-13">
                    {
                        this.props.isLoading ?
                            <div className="center lh-50"><i className="fa fa-spinner blue_dark fa-spin fa-fw marg-b-30"> </i></div>
                        :
                            <div>
                                <i className={"font-ty-round " + this.props.fontIcon + " " + this.props.fontColor }> </i><br/>
                                <p className="marg-t-10 marg-b-13">{this.props.name}</p>
                                <p className="font-value">{this.props.value}</p>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

DeliveredCard.defaultProps = {
    name: "",
    isLoading: false,
    value: "",
    fontIcon: "",
    fontColor: ""
};

export default DeliveredCard;
