import React, {Component} from 'react';
import Marker from "./Marker";
import Utils from "../../Utils";
import vars from '../../vars';

class RangeSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offsetX: 0,
            width: 0,
            centerMarker: this.props.markerSize / 2,
            positionMarkerOneX: this.props.markerSize / -2,
            positionMarkerTwoX: this.props.markerSize / -2,
            markerOneClicked: false,
            markerTwoClicked: false,
            valueOne: this.props.min,
            valueTwo: this.props.max,
            clickOffset: 0
        };
    }

    componentWillMount() {
        document.body.addEventListener("mouseup", this.markersTriggered)
    }

    componentWillUnmount() {
        document.body.removeEventListener("mouseup", this.markersTriggered)
    }

    componentWillReceiveProps(nextProps) {
        let positionMarkerOneX = Utils.getPositionX(nextProps.min, nextProps.min, nextProps.max, this.state.centerMarker, this.state.width);
        let positionMarkerTwoX = Utils.getPositionX(nextProps.max, nextProps.min, nextProps.max, this.state.centerMarker, this.state.width);

        if (nextProps.dateChanged) {
            this.setState({
                valueOne: nextProps.min,
                valueTwo: nextProps.max,
                positionMarkerOneX: positionMarkerOneX,
                positionMarkerTwoX: positionMarkerTwoX
            });
        } else{
            let data = {};
            if(nextProps.min && nextProps.min !== this.props.min)
                data.valueOne = nextProps.min;
            if(nextProps.max && nextProps.max !== this.props.max)
                data.valueTwo = nextProps.max;
            if(data !== {})
                this.setState(data);
        }
    }

    refreshBoundingClientRect = (ref) => {
        window.onresize = () => {
            let rect = ref.getBoundingClientRect();
            let width = rect.width;
            let positionMarkerOneX = Utils.getPositionX(this.state.valueOne, this.props.min, this.props.max, this.state.centerMarker, this.state.width);
            let positionMarkerTwoX = Utils.getPositionX(this.state.valueTwo, this.props.min, this.props.max, this.state.centerMarker, this.state.width);
            let offsetX = rect.left;
            this.setState({
                offsetX: offsetX,
                positionMarkerOneX: positionMarkerOneX,
                positionMarkerTwoX: positionMarkerTwoX,
                width: width
            });
        }
    };

    onLayout = ref => {
        if (ref === null)
            return;
        this.refreshBoundingClientRect(ref);
        let rect = ref.getBoundingClientRect();
        this.setState({
            offsetX: rect.left,
            width: rect.width,
            positionMarkerTwoX: this.state.positionMarkerTwoX + rect.width
        });
    };

    markerOneTriggered = (status, e) => {
        this.setState({
            markerOneClicked: status,
            clickOffset: e.pageX - (this.state.offsetX + this.state.positionMarkerOneX)
        });
    };

    markerTwoTriggered = (status, e) => {
        this.setState({
            markerTwoClicked: status,
            clickOffset: e.pageX - (this.state.offsetX + this.state.positionMarkerTwoX)
        });
    };

    updateValue = (v, key) => {
        console.log(v);
        this.props.getTimeRange(v, key);
    };

    mouseMove = e => {
        let removeX = -this.state.clickOffset;
        let x = e.clientX - this.state.offsetX + removeX;
        let clicked = true;

        if(x < removeX)
            x = removeX;
        else if(x > this.state.width + removeX)
            x = this.state.width + removeX;
        let v = Utils.getValueMarker(x, this.props.min, this.props.max, this.state.centerMarker, this.state.width);

        if (this.state.valueTwo - this.state.valueOne < 2) {
            if (this.state.markerOneClicked) {
                x = Utils.getPositionX((this.state.valueTwo - 2), this.props.min, this.props.max, this.state.centerMarker, this.state.width);
                v = this.state.valueTwo - 2;
                clicked = false;
            }
            else if (this.state.markerTwoClicked) {
                x = Utils.getPositionX((this.state.valueOne + 2), this.props.min, this.props.max, this.state.centerMarker, this.state.width);
                v = this.state.valueOne + 2;
                clicked = false;
            }
        }

        if (this.state.markerOneClicked) {
            this.setState({ positionMarkerOneX: x, valueOne: v, markerOneClicked: clicked });
            this.props.getTimeRange(v, "beginHour");
        }
        else if (this.state.markerTwoClicked) {
            this.setState({ positionMarkerTwoX: x, valueTwo: v, markerTwoClicked: clicked });
            this.props.getTimeRange(v, "endHour");
        }
    };

    markersTriggered = () => {
        this.setState({
            markerOneClicked: false,
            markerTwoClicked: false
        })
    };

    render () {
        return (
            <div className="slider-container"
                 ref={ this.onLayout }
                 onMouseMove={ this.mouseMove }
            >
                <div className="slider-back-line"> </div>
                <Marker
                    positionX={this.state.positionMarkerOneX}
                    onMouseToggle={ this.markerOneTriggered }
                    value={this.state.valueOne}
                    left={true}
                />

                <Marker
                    positionX={this.state.positionMarkerTwoX}
                    onMouseToggle={ this.markerTwoTriggered }
                    value={this.state.valueTwo}
                    left={false}
                />
                <div className="slider-range" style={{
                    left: (this.state.positionMarkerOneX + this.props.markerSize),
                    width: (this.state.positionMarkerTwoX - this.state.positionMarkerOneX - this.props.markerSize)
                }}>
                </div>

                <div className="marg-l-neg-5" style={{ position: "absolute", marginTop: "15px"}}>{this.props.min} h</div>
                <div style={{ right: "0", position: "absolute", marginTop: "15px", marginRight: "-10px"}}>{this.props.max} h</div>
            </div>
        )
    }
}

RangeSlider.defaultProps = {
    min: vars.min_hour,
    max: vars.max_hour,
    markerSize: 20,
    dateChanged: false,
    getTimeRange: () => {}
};

export default RangeSlider;
