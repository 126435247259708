import React, { Component } from "react";
import OrderBlock from "./OrderBlock";
import OrderBlockDelivery from "./OrderBlockAction/OrderBlockDelivery";
import OrderBlockCalendar from "./OrderBlockAction/OrderBlockCalendar";
import OrderBlockVehicle from "./OrderBlockAction/OrderBlockVehicle";
import OrderBlockProducts from "./OrderBlockAction/OrderBlockProducts";


export default class OrderDeliveryInfo extends Component{
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            has_vehicle: false
        };
    }

    goNextBlock = () => {
        this.setState({ step: this.state.step + 1 })
    };

    updateVehicle = (bool) => {
        this.setState({
            has_vehicle: bool
        })
    };

    render(){
        return (
            <div>
                <OrderBlock
                    title="O&ugrave; vous voulez"
                    tutoTitle="Adresse de livraison"
                    tutoSubtitleOne="Zone géographique opérationnelle :"
                    tutoSubtitleTwo="Livraison du véhicule :"
                    tutoDescriptionOne="R&eacute;gion parisienne, R&eacute;gion Lilloise"
                    tutoDescriptionTwo="Le v&eacute;hicule doit &ecirc;tre dans un espace priv&eacute; ou un parking public &agrave; l'air libre. La livraison ne doit en aucun cas g&ecirc;ner la circulation."
                    stepActive={1}
                    step={this.state.step}
                ><OrderBlockDelivery
                    subtitle="Adresse de livraison"
                    placeholder="Renseignez votre adresse de livraison ..."
                    orderInformations={this.props.orderInformations}
                    goNextBlock={this.goNextBlock}
                /></OrderBlock>
                {
                    this.state.step > 1 ?
                        <OrderBlock
                            title="Quand vous voulez"
                            tutoTitle="Date de livraison"
                            tutoSubtitleOne=""
                            tutoSubtitleTwo="Cr&eacute;neaux de livraison :"
                            tutoDescriptionOne="Nos clients peuvent r&eacute;server 14 jours avant la date de livraison souhait&eacute;e et au plus tard 2 heures avant."
                            tutoDescriptionTwo="Pouvez-vous pr&eacute;ciser la temps de disponibilit&eacute; de votre v&eacute;hicule sur place (minimum 2 heures)."
                            stepActive={2}
                            step={this.state.step}
                        ><OrderBlockCalendar
                            subtitle="Date de livraison"
                            placeholder="Renseignez votre date de livraison ..."
                            orderInformations={this.props.orderInformations}
                            goNextBlock={this.goNextBlock}
                        /></OrderBlock>
                        :
                        ""
                }
                {
                    this.state.step > 2 ?
                        <div>
                            <OrderBlock
                                title="Quoi ?"
                                tutoTitle="V&eacute;hicules"
                                tutoSubtitleOne="Minimum de livraison :"
                                tutoSubtitleTwo="Trappe ouverte :"
                                tutoDescriptionOne="Tankyou r&eacute;alise un minimum de 20&euro; de livraison (essence + frais de service) afin de se d&eacute;placer."
                                tutoDescriptionTwo="S'il le souhaite le client peut &ecirc;tre absent lors de la livraison en laissant sa trappe &agrave; essence entre-ouverte et fermant son v&eacute;hicule."
                                stepActive={3}
                                step={this.state.step}
                            >

                                <OrderBlockProducts 
                                    zoneId={this.props.zoneId}
                                    orderInformations={this.props.orderInformations} 
                                />
                                
                                <OrderBlockVehicle
                                    subtitle="V&eacute;hicules"
                                    placeholder="Aucun v&eacute;hicule"
                                    orderInformations={this.props.orderInformations}
                                    hasVehicle={this.updateVehicle}
                                />

                            </OrderBlock>
                            <div className="row">
                                <div className="col-md-6 center marg-t-15">
                                    <button className="btn_primary" onClick={this.props.nextStep}>Valider</button>
                                    { /*
                                        this.state.has_vehicle ?
                                            <button className="btn_primary" onClick={this.props.nextStep}>Valider</button>
                                            :
                                            <button className="btn-disable">Valider</button>
                                    */ }
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
            </div>
        );
    }
}

OrderDeliveryInfo.defaultProps = {
    orderInformations: () => {},
    nextStep: () => {},
    zoneId: null
};
