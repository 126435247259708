import React, { Component } from "react";


export default class CovidInfoBlock extends Component {
    state = {
        shown: true
    };

    constructor(props){
        super(props);
        this.storage = window.localStorage || window.sessionStorage;
    }

    componentDidMount(){
        let isClosed = this.storage.getItem("covid-info-closed");
        if(isClosed === "true")
            this.setState({shown: false});
    }

    hideInfo = () => {
        this.storage.setItem("covid-info-closed", true);
        this.setState({shown: false});
    };

    render(){
        const { shown } = this.state;

        if(!shown)
            return null;

        return <div className="covid-info-container d-flex relative bg-blue_dark pad-10 white shadowed">
            <i className="absolute top-10 right-10 white icon-cross cursor blue-hover" onClick={this.hideInfo}></i>

            <div className="center wp-70 f-11" style={{margin: "auto"}}>
                <div className="bold f-13 marg-b-10">COVID-19 - Informations importantes</div>
                Nos &eacute;quipes restent mobilis&eacute;es pour vous accompagner dans vos op&eacute;rations.<br />
                <a className="f-11" target="_blank" href="https://blog.tankyou.co/2020/04/01/livraison-carburant-sans-contact-entreprises-essentielles/" rel="noopener noreferrer">
                    Tout savoir sur&nbsp;
                    <span className="bold underline blue-hover cursor">la livraison sans contact avec Tankyou</span>
                </a>
            </div>
        </div>
    }
}