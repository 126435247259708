import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Registry } from '../../Utils';
import Utils from "../../Utils";
import ProfilePassword from "./Profile/ProfilePassword";
import ProfileInvoiceAddress from "./Profile/ProfileInvoiceAddress";
import ProfileHome from "./Profile/ProfileHome";


class AccountProfile extends Component{
    constructor(props){
        super(props);

        this.inputChanged = this.inputChanged.bind(this);
        this.sendUpdate   = this.sendUpdate.bind(this);
        this.setUser      = this.setUser.bind(this);

        this.api = Registry.registred("api");

        this.state = {
            messageError: "",
            messageSuccess: "",
            loading: false,
            loadingExport: false,
            user: null,
            errors:{
                first_name: false,
                last_name: false,
                phone_number: false,
                email: false,
                address: false,
                address2: false,
                postal_code: false,
                city: false,
                country: false,
                old_password: false,
                new_password: false
            },
            fields: {
                first_name: "",
                last_name: "",
                phone_number: "",
                email: "",
                address: "",
                address2: "",
                postal_code: "",
                city: "",
                country: "",
                old_password: "",
                new_password: ""
            }
        };
    }

    componentDidMount(){
        let user = Registry.observe(this, "user", data => {
            this.setUser(data);
        });
        if(user)
            this.setUser(user);
    }

    setUser(user){
        this.setState({
            user: user,
            fields: {
                first_name: user ? user.first_name : "",
                last_name: user ? user.last_name : "",
                phone_number: user ? user.phone_number : "",
                email: user ? user.email : "",
                address: user ? user.address : "",
                address2: user ? user.address2 : "",
                postal_code: user ? user.postal_code : "",
                city: user ? user.city : "",
                country: user ? user.country : "",
                old_password: "",
                new_password: ""
            }
        });
    }

    inputChanged(e, input_key){
        if(this.state.fields.hasOwnProperty(input_key) && typeof e !== "undefined"){
            let nState = this.state;

            nState.fields[input_key] = (typeof e === "string" ? e : e.target.value);
            nState.errors[input_key] = false;
            this.setState(nState);
        }
    }

    sendUpdate(){
        let nState = this.state;
        let error  = false;
        let inputChanged = {};
        let inputChangedSize = 0;

        for(let k in this.state.fields){
            if ((this.state.fields[k] !== this.state.user[k]) && (k !== "old_password" && k !== "new_password")) {
                inputChanged[k] = this.state.fields[k];
                inputChangedSize++;

                if (this.state.fields[k] === "" && (k !== "address2" && k !== "old_password" && k !== "new_password")){
                    nState.errors[k] = true;
                    nState.messageError = "Le champ est invalide";
                    error = true;
                }
            }
        }

        if (this.props.match.params.childBlockId === "mot-de-passe" && (this.state.fields.old_password !== "" || this.state.fields.new_password !== "")) {
            inputChanged["old_password"] = this.state.fields.old_password;
            inputChanged["password"] = this.state.fields.new_password;
            inputChangedSize += 2;
        }

        this.setState(nState);
        if(!error && inputChangedSize > 0){
            this.setState({loading: true});
            let comp = this;

            this.api.put("/customers/" + this.state.user.id, inputChanged)
                .then(results => {
                    Registry.register("user", results.customer);
                    comp.setState({
                        loading: false,
                        messageSuccess: "Vos informations ont été modifiées avec succès",
                        messageError: ""
                    });
                    setInterval(() => { comp.setState({messageSuccess: ""}); }, 3000)
                })
                .catch(error => {
                    let nState = comp.state;

                    if(error.hasOwnProperty("subcode")){
                        switch(error.subcode){
                            case 'PUTCustomersInvalidEmail':
                                nState.errors.email = true;
                                nState.messageError = "Email est invalide";
                                break;
                            case 'PUTCustomerInvalidPhoneNumber':
                                nState.errors.phone_number = true;
                                nState.messageError = "Le numéro de téléphone est invalide";
                                break;
                            case 'PUTCustomerInvalidAddress':
                                nState.errors.address = true;
                                nState.messageError = "L'adresse est invalide";
                                break;
                            case 'PUTCustomersInvalidOldPassword':
                                nState.errors.old_password = true;
                                nState.messageError = "L'ancien mot de passe est invalide";
                                break;
                            case 'PUTCustomersInvalidPassword':
                                nState.errors.new_password = true;
                                nState.messageError = "Le mot de passe doit contenir au moins 6 caractères dont une majuscule, un chiffre et une minuscule";
                                break;
                            default:
                                break;
                        }
                        nState.loading = false;

                        comp.setState(nState);
                    }
                })
        }
    }

    cleanMsgErrors = () => {
        this.setState({
            messageError: ""
        })
    };


    downloadData = () => {
        this.setState({loadingExport: true});

        this.api.get("/customers/export")
            .then(res => {
                this.setState({loadingExport: false});

                let pom = document.createElement('a');
                pom.setAttribute('href', 'data:text/json;charset=utf-8,' + JSON.stringify(res, null, 2));
                pom.setAttribute('download', 'mes-donnees.json');
                pom.style.display = 'none';
                document.body.appendChild(pom);
                pom.click();
                document.body.removeChild(pom);
            })
            .catch(err => {
                console.log(err);
            });
    };

    render() {
        let block = "";

        switch(this.props.match.params.childBlockId){
            case "adresse-de-facturation":
                block = <ProfileInvoiceAddress
                    data={this.state.fields}
                    error={this.state.errors}
                    inputChanged={this.inputChanged}
                />;
                break;
            case "mot-de-passe":
                block = <ProfilePassword
                    data={this.state.fields}
                    error={this.state.errors}
                    inputChanged={this.inputChanged}
                />;
                break;
            default:
                block = <ProfileHome
                    data={this.state.fields}
                    error={this.state.errors}
                    inputChanged={this.inputChanged}
                />;
                break;
        }

        return (
            <div>
                <div className="block-content-account">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                            Informations personnelles
                                            <button className="btn_discovery uppercase pull-right" onClick={ this.downloadData }>
                                                {
                                                    this.state.loadingExport ?
                                                        <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i>
                                                        :
                                                        "Télécharger mes données"
                                                }
                                            </button>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className=" underline-title">
                                    <span
                                        className={"cursor marg-r-15 " + (this.props.match.params.childBlockId === undefined ? "color-red-ty" : "") }
                                        onClick={() => {Utils.goTo(this, "/mon-compte/informations"); this.cleanMsgErrors() }}>
                                        Mon profil
                                    </span>
                                    <span
                                        className={"cursor marg-r-15 " + (this.props.match.params.childBlockId === "adresse-de-facturation" ? "color-red-ty" : "") }
                                        onClick={() => {Utils.goTo(this, "/mon-compte/informations/adresse-de-facturation"); this.cleanMsgErrors() }}>
                                        Adresse de facturation
                                    </span>
                                    <span
                                        className={"cursor marg-r-15 " + (this.props.match.params.childBlockId === "mot-de-passe" ? "color-red-ty" : "") }
                                        onClick={() => {Utils.goTo(this, "/mon-compte/informations/mot-de-passe"); this.cleanMsgErrors() }}>
                                        Mot de passe
                                    </span>
                                </div>
                            </div>
                            {block}
                        </div>
                        {
                            this.state.messageError ?
                                <div className="marg-t-25 red">
                                    {this.state.messageError}
                                </div> : ""
                        }
                    </div>
                </div>
                <div className="col-md-12 center marg-t-30">
                    <button className="btn_primary cta-min-height" onClick={this.sendUpdate}>{this.state.loading ? <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i> : "Enregistrer les modifications"}</button>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountProfile);
