import React, {Component} from "react";
import Utils, {Registry} from "../../../Utils";
import Variables from '../../../vars';


class CustomerAddressModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: "",
            locations: [],
            category: "",
            modal: this.props.modal,
            error: {
                location: false,
                category: false
            },
            msgError: ""
        };
        this.api = Registry.registred("api");
    }

    componentWillReceiveProps(nextProps) {
        this.resetData();
        if (nextProps.address !== null) {
            this.api.get("/customers/addresses/" + nextProps.address)
                .then(res => {
                    let address = res.address;
                    this.setState({
                        location: address.address,
                        category: address.category
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            this.resetData();
        }
    }

    resetData = () => {
        this.setState({
            location: "",
            locations: [],
            category: "",
            error: {
                location: false,
                category: false
            },
            msgError: ""
        });
    };

    addAddress = (location, category = null) => {
        if (category === null || category === "") {
            category = this.props.categoryAddress;
        }
        let data = {category: category, address: location};

        this.api.get("/orders/checkZone?address=" + location)
            .then(res => {
                this.api.post("/customers/addresses", data)
                    .then(res => {
                        this.props.loadAddresses();
                        this.props.toggle();
                        this.setState({
                            error: {
                                location: false,
                                category: false
                            },
                            msgError: ""
                        })
                    })
                    .catch(error => {
                        this.displayAddressErrorMsg(error);
                    });
            })
            .catch(err => {
                this.setState({
                    error: {
                        location: true
                    },
                    msgError: "L'adresse n'est pas dans la zone de livraison"
                })
            })
    };

    displayAddressErrorMsg = (error) => {
        switch (error.subcode) {
            case "POSTCustomersaddressesInsertionFailed":
                this.setState({
                    error: {
                        location: true
                    },
                    msgError: "L'adresse est invalide"
                });
                break;
            case "POSTCustomersaddressesInvalidCategory":
                this.setState({
                    error: {
                        category: true
                    },
                    msgError: "Le type est invalide"
                });
                break;
            default: 
            this.setState({
                msgError: "Une erreur est survenue."
            });
        }
    };

    updateAddress = (location, category = null) => {
        if (category === null) {
            category = this.props.categoryAddress;
        }
        let data = {category: category, address: location};
        this.api.get("/orders/checkZone?address=" + location)
            .then(res => {
                this.api.put("/customers/addresses/" + this.props.address, data)
                    .then(res => {
                        this.props.loadAddresses();
                        this.props.toggle();
                        this.setState({
                            error: {
                                location: false,
                                category: false
                            },
                            msgError: ""
                        })
                    })
                    .catch(error => {
                        this.displayAddressErrorMsg(error);
                    })
            })
            .catch(err => {
                this.setState({
                    error: {
                        location: true
                    },
                    msgError: "L'adresse n'est pas dans la zone de livraison"
                })
            })

    };

    setLocation = (location) => {
        this.setState({
            location: location.main_text + ", " + location.secondary_text,
            locations: []
        })
    };

    inputChanged = (e, input_key) => {
        if(this.state.hasOwnProperty(input_key)){
            let nState = this.state;
            nState[input_key] = (typeof e === "string" ? e : e.target.value);
            this.setState(nState);
        }
    };

    updateLocations = locations => {
        this.setState({
            locations: locations
        })
    };

    deleteAddress = () => {
        this.api.del("/customers/addresses/" + this.props.address)
            .then(res => {
                this.props.loadAddresses();
                this.props.toggle();
            })
            .catch(err => {
            })
    };

    render() {
        return (
                <div className="row">
                    <div className="col-sm-12">
                        <input className={"blue_dark f-14 wp-100 border-rad-4 marg-t-25 " + (this.state.error.location ? "border-red-1" : "")}
                               onChange={ e => {
                                   if(typeof e.target.value !== "undefined" && e.target !== null){
                                       let value = e.target.value;

                                       this.setState({
                                           location: value,
                                           error: { location: false },
                                           msgError: ""
                                       }, () => {Utils.getGeoSuggest(value, this.updateLocations)});
                                   }
                               }}
                               value={this.state.location}
                        />
                        <label className={"blue_dark custom-label-control"+ (this.state.error.location ? " red" : "")}>
                            Adresse { this.props.categoryAddress === "work" ? " professionnelle" : ""} { this.props.categoryAddress === "home" ? " domicile" : ""}
                        </label>
                        {
                            this.state.locations.length === 0 ?
                                ""
                                :
                                <div className="col-sm-12">
                                    <div className="row dropdown-delivery">
                                        {
                                            this.state.locations.length ?
                                                this.state.locations.map((location, i) =>
                                                    <div key={i} className="list-address" onClick={() => { this.setLocation(location); }}>
                                                        <div className="w-40 vertical-align-middle"><i className="font-ty pad-l-0 icon-address"> </i></div>
                                                        <div className="pad-l-10">{location.main_text}<br/><span className="grey">{location.secondary_text}</span></div>
                                                    </div>
                                                )
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                    {
                        this.props.isPageAddress ?
                            this.props.categoryAddress === "" ?
                                <div className="col-sm-12 marg-t-10">
                                    <select className={"custom-select-control marg-t-25 "+ (this.state.error.category ? "border-red-1" : "")}
                                            value={this.state.category}
                                            onChange={e => {this.inputChanged(e, "category")} }>
                                        <option value="">S&eacute;lectionner</option>
                                        {Variables.address_designation && Variables.address_designation.map((type, i) => {
                                            return <option className="blue_dark f-14" key={i} value={type.value}
                                                           disabled={(type.value === "work" && this.props.hasWork) || (type.value === "home" && this.props.hasHome)}
                                            >{type.key}</option>
                                        })}
                                    </select>
                                    <label className={"blue_dark custom-label-control" + (this.state.error.category ? " red" : "")}>Type</label>
                                    {
                                        this.props.modalUpdate ?
                                            <p className="dark-blue-light right pad-t-5 cursor f-12" onClick={() => this.deleteAddress()}>Supprimer cette adresse</p>
                                            :
                                            ""
                                    }
                                </div>
                                :
                                ""
                            :
                            ""
                    }
                    {
                        this.state.msgError.length !== "" ?
                            <div className="col-md-12 marg-t-15 marg-b-10 red">
                                {this.state.msgError}
                            </div>
                            :
                            ""
                    }
                    <div className="center marg-t-10">
                        {
                            this.props.modalUpdate ?
                                <button className="medium btn_primary marg-t-10 lh-20"
                                        onClick={() => this.updateAddress(this.state.location, this.state.category)}>Modifier</button>
                                :
                                <button className="medium btn_primary marg-t-10 lh-20"
                                    onClick={() => this.addAddress(this.state.location, this.state.category)}>Ajouter</button>
                        }
                    </div>
                </div>
        );
    }
}

CustomerAddressModal.defaultProps = {
    modal: false,
    toggle: () => {},
    categoryAddress: "",
    isPageAddress: false,
    modalUpdate: false,
    loadAddresses: () => {},
    address: null,
    hasHome: false,
    hasWork: false
};

export default CustomerAddressModal;
