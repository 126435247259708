import React, {Component} from 'react';
import {Registry} from "../../Utils";
import DivGroupInline from "../DivGroupInline";
import Modal from "../Modal";
import CustomerAddressModal from "../Order/OrderBlockModal/CustomerAddressModal";

export default class AccountAddresses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            home_address: null,
            work_address: null,
            loading: false,
            toggle: false,
            isPageAddress: true,
            modalUpdate: false,
            addressToUpdate: null,
            categoryAddress: ""
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        this.loadAddresses();
    }

    isModalUpdate = (bool = false, addressId = null) => {
        this.setState({
            modalUpdate: bool,
            addressToUpdate: addressId
        });

        if (this.state.home_address !== null && addressId === this.state.home_address.id)
            this.setState({categoryAddress: "home"});
        else if (this.state.work_address !== null && addressId === this.state.work_address.id)
            this.setState({categoryAddress: "work"});
        else 
            this.setState({categoryAddress: ""});
    };

    addAddress = () => {
        this.setState({
            toggle: !this.state.toggle,
            modalUpdate: false,
            isPageAddress: true,
            categoryAddress: "",
            addressToUpdate: null
        });
    };

    resetAddresses = () => {
        this.setState({
            home_address: null,
            work_address: null,
            locations: []
        });
    };

    loadAddresses = () => {
        this.resetAddresses();
        this.setState({loading: true});
        this.api.get("/customers/addresses")
            .then(results => {
                let addresses = [];
                let work = null;
                let home = null;
                for(let add of results.addresses){
                    let h = add.address.split(", ");
                    let first = h.shift();
                    let id = add.id;
                    if(add.category === "work") {
                        work = {
                            id: id,
                            main_text: first,
                            secondary_text: h.join(", ")
                        };
                    } else if (add.category === "home") {
                        home = {
                            id: id,
                            main_text: first,
                            secondary_text: h.join(", ")
                        };
                    } else if (add.category === "other") {
                        addresses.push({
                            id: id,
                            main_text: first,
                            secondary_text: h.join(", ")
                        });
                    }
                }
                this.setState({
                    home_address: home,
                    work_address: work,
                    locations: addresses,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({loading: false});
                console.log(err)
            })
    };

    getAddressDescription = (address) => {
        return <div className="f-14">{address.main_text}<br/>
            <span className="fw-300">{address.secondary_text}</span>
        </div>
    };

    toggle = () => {
        this.setState({toggle: !this.state.toggle})
    };

    showModal = (categoryAddress = "") => {
        this.setState({
            addressToUpdate: null,
            toggle: !this.state.toggle,
            categoryAddress: categoryAddress,
            modalUpdate: false
        })
    };

    render() {
        return (
            <div className="row col-md-6">
                <div className="block-content pad-t-15 pad-b-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                            Adresses de livraison
                                            <span className="pink cursor right">
                                                <i className="icon-more" onClick={() => { this.addAddress() }}> </i>
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 f-14 marg-t-15">
                                {
                                    this.state.loading ?
                                        <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i>
                                        :
                                        ""
                                }
                                {
                                    this.state.loading ? "" :
                                        (this.state.home_address === null) ?
                                            <DivGroupInline
                                                icon="icon-house"
                                                content="Ajouter un domicile"
                                                showModal={() => {this.showModal("home")}}
                                            />
                                            :
                                            <DivGroupInline
                                                icon="icon-house"
                                                content={this.getAddressDescription(this.state.home_address)}
                                                update={true}
                                                obj={this.state.home_address}
                                                updateAction={this.isModalUpdate}
                                                toggle={this.toggle}
                                            />
                                }
                                {
                                    this.state.loading ? "" :
                                        (this.state.work_address === null) ?
                                            <DivGroupInline
                                                icon="icon-business"
                                                content="Ajouter une adresse professionnelle"
                                                showModal={() => {this.showModal("work")}}
                                            />
                                            :
                                            <DivGroupInline
                                                icon="icon-business"
                                                content={this.getAddressDescription(this.state.work_address)}
                                                update={true}
                                                obj={this.state.work_address}
                                                updateAction={this.isModalUpdate}
                                                toggle={this.toggle}
                                            />
                                }
                                { this.state.locations.length === 0 ? "" : <hr className="delimiter-delivery"/> }
                                {
                                    this.state.loading ? "" :
                                        this.state.locations.length > 0 ?
                                            this.state.locations.map((location, i) => {
                                                return <DivGroupInline
                                                            key={i}
                                                            icon="icon-address"
                                                            content={this.getAddressDescription(location)}
                                                            update={true}
                                                            obj={location}
                                                            updateAction={this.isModalUpdate}
                                                            command={true}
                                                            toggle={this.toggle}
                                                        />
                                            })
                                            :
                                            ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    modalId={ this.state.modalUpdate ? "addAddressModal" : "updateAddressModal" }
                    modal={this.state.toggle}
                    toggle={this.toggle}
                    modalHeader={ (this.state.modalUpdate ? "Modifier" : "Ajouter")  + " une adresse"}
                    validation={ this.state.modalUpdate ? "Modifier" : "Ajouter" }
                >
                    <CustomerAddressModal
                        isPageAddress={ this.state.isPageAddress }
                        modalUpdate={ this.state.modalUpdate }
                        categoryAddress={this.state.categoryAddress}
                        loadAddresses={this.loadAddresses}
                        toggle={this.toggle}
                        address={ this.state.addressToUpdate }
                        hasHome={ this.state.home_address !== null }
                        hasWork={ this.state.work_address !== null }
                    />
                </Modal>
            </div>
    );
    }
}
