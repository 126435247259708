import React, { Component } from 'react';

import "./styles.css";


export default class TYCalendarSlider extends Component {
    render() {
        return (
            <div className="ty-calendar-slider">
                <div className="display-flex">
                    {
                        this.props.days.length ?
                            this.props.days.map((day, i) => {
                                return <div className="ty-calendar-date" key={ i }>
                                    <div className="ty-calendar-day-week">{ day.format('ddd') }</div>
                                    <div className={"ty-calendar-day" +
                                        ( this.props.disabledDays.includes(day.format("YYYY-MM-DD")) ? " disabled-day" : "")
                                    }
                                         onClick={() => {
                                            if(!this.props.disabledDays.includes(day.format("YYYY-MM-DD")))
                                                this.props.getSelectedDay(day);
                                         } } >
                                        <span className={ (this.props.selectedDay === day ? " day-selected" : "")}>{ day.format('DD') }</span>
                                        { this.props.selectedDay === day ? <div className="day-selected-active"/> : "" }
                                    </div>
                                </div>
                            })
                            : ""
                    }
                </div>
            </div>
        );
    }
}

TYCalendarSlider.defaultProps = {
    days: [],

};