import React, {Component} from 'react';
import Utils, {Registry} from "../../../Utils";
import CustomerAddressModal from "../OrderBlockModal/CustomerAddressModal";
import ReactDOM from 'react-dom';
import DivGroupInline from "../../DivGroupInline";
import Modal from "../../Modal";

class OrderBlockDelivery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openDelivery: false,
            locations: [],
            loading_history: false,
            location: "",
            modal: false,
            categoryAddress: "",
            home_address: null,
            work_address: null,
            doNext: true,
            error_location: false,
            error_msg: ""
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        this.loadHistory();
        this.loadAddresses();
        // document.addEventListener('click', this.closeDelivery, false)
    }

    componentWillUnmount() {
        // document.removeEventListener('click', this.closeDelivery, false)
    }

    openDelivery = () => {
        this.setState({
            openDelivery: true
        })
    };

    closeDelivery = (e) => {
        if(!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState({
                openDelivery: false
            })
        }

    };

    loadHistory = () => {
        this.setState({loading_history: true});
        this.api.get("/customers/addresses/history")
            .then(results => {
                let histories = [];
                for(let hist of results.history){
                    let h = hist.split(", ");
                    let first = h.shift();

                    histories.push({
                        main_text: first,
                        secondary_text: h.join(", ")
                    });
                }
                this.setState({
                    locations: histories,
                    loading_history: false
                });
            })
            .catch(error => {
                this.setState({
                    locations: [],
                    loading_history: true
                });
            });
    };

    updateLocations = locations => {
        let error = false;
        let msg = false;

        if (locations.length === 0) {
            error = true;
            msg = "Aucun résultat ne correspond à votre recherche";
        }
        this.setState({
            locations: locations,
            error_location: error,
            error_msg: msg
        })
    };

    setLocation = (location) => {
        let address = location.main_text + ", " + location.secondary_text;
        let _this = this;

        this.api.get("/orders/checkZone?address=" + address)
            .then(res => {
                _this.setState({
                    location: address,
                    zoneId: res.zone.id,
                    openDelivery: false,
                    error_location: false,
                    error_msg: ""
                }, () => {
                    _this.props.orderInformations({location: address, zoneId: this.state.zoneId});

                    if (_this.state.doNext) {
                        _this.props.goNextBlock();
                        _this.setState({doNext: false});
                    }
                });
            })
            .catch(err => {
                _this.setState({
                    error_location: true,
                    error_msg: "L'adresse sélectionnée n'est pas dans la zone de livraison"
                })
            })
    };


    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    isBusinessAddress = (isBusiness) => {
        this.setState({
            categoryAddress: isBusiness
        })
    };

    loadAddresses = () => {
        this.api.get("/customers/addresses")
            .then(results => {
                let addresses = [];
                let work = null;
                let home = null;
                for(let add of results.addresses){
                    let id = add.id;
                    let h = add.address.split(", ");
                    let first = h.shift();
                    if(add.category === "work") {
                        work = {
                            id: id,
                            main_text: first,
                            secondary_text: h.join(", ")
                        };
                    }
                    else if(add.category === "home") {
                        home = {
                            id: id,
                            main_text: first,
                            secondary_text: h.join(", ")
                        };
                    } else if (add.category === "other") {
                        addresses.push({
                            id: id,
                            main_text: first,
                            secondary_text: h.join(", ")
                        });
                    }
                }
                this.setState({
                    home_address: home,
                    work_address: work,
                    locations: addresses,
                    loading: false
                });
            })
            .catch(err => {
                console.log(err)
            })
    };

    showModal = (categoryAddress = "") => {
        this.setState({
            modal: !this.state.modal,
            categoryAddress: categoryAddress
        })
    };

    render() {
        return (
            <div>
                <p className="marg-b-10">{ this.props.subtitle }</p>
                <input className={"wp-100 border-rad-4 " + (this.state.openDelivery ? "border-blue-1" : "")}
                    onFocus={ this.openDelivery }
                    onChange={ e => {
                        if(typeof e.target.value !== "undefined" && e.target !== null){
                            let value = e.target.value;

                            this.setState({
                                location: value,
                                error_location: false,
                                error_msg: ""
                            }, () => {
                                Utils.getGeoSuggest(value, this.updateLocations);
                            });
                        }
                    }}
                    value={this.state.location}
                    placeholder={this.props.placeholder}
                />
                {
                    this.state.openDelivery
                        ?
                        <div className="col-md-12 pad-0">
                            <div className="dropdown-delivery">
                                {this.state.home_address === null ?
                                    <DivGroupInline
                                        icon="icon-house"
                                        content="Ajouter un domicile"
                                        showModal={() => {this.showModal("home")}}
                                    />
                                    :
                                    <div className="block-content cursor col-md-12 marg-b-10">
                                        <div className="row pad-10 pink-hover" onClick={() => { this.setLocation(this.state.home_address)}}>
                                            <div className="col-md-2 col-sm-1 col-xs-1 pad-md-l-0 pad-xs-l-0"><i className="font-ty icon-house"> </i></div>
                                            <div className="col-md-10 col-sm-11 col-xs-11 pad-md-l-5 pad-sm-l-5">
                                                {this.state.home_address.main_text}<br/>
                                                <span className="grey">{this.state.home_address.secondary_text}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.work_address === null ?
                                    <DivGroupInline
                                        icon="icon-house"
                                        content="Ajouter une adresse professionnelle"
                                        showModal={() => {this.showModal("work")}}
                                    />
                                    :
                                    <div className="block-content cursor col-md-12 marg-b-10">
                                        <div className="row pad-10 pink-hover" onClick={() => { this.setLocation(this.state.work_address)}}>
                                            <div className="col-md-2 col-sm-1 col-xs-1 pad-md-l-0 pad-xs-l-0"><i className="font-ty icon-business"> </i></div>
                                            {
                                                this.state.loading_history ?
                                                    <div className="marg-t-10">
                                                        <i className="fa fa-spinner fa-spin fa-3x fa-fw f-16"> </i>
                                                    </div>
                                                    :
                                                    <div className="col-md-10 col-sm-11 col-xs-11 pad-md-l-5 pad-sm-l-5">
                                                        {this.state.work_address.main_text}<br/>
                                                        <span
                                                            className="grey">{this.state.work_address.secondary_text}</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <hr className="delimiter-delivery" />
                                {
                                    this.state.error_location ?
                                        <div className="red">
                                            {this.state.error_msg}
                                        </div>
                                        :
                                        this.state.locations.length > 0 ?
                                            <div className="block-content col-md-12">
                                                <ul>
                                                    {
                                                        this.state.locations.map((location, i) =>
                                                            <li key={i} className="pad-10" onClick={() => { this.setLocation(location) } }>
                                                                <div className="row">
                                                                    <div className="col-md-2 col-sm-1 col-xs-1 pad-md-l-0 pad-xs-l-0"><i className="font-ty pad-l-0 icon-address"> </i></div>
                                                                    <div className="col-md-10 col-sm-11 col-xs-11 pad-md-l-5 pad-sm-l-5">{location.main_text}<br/><span className="grey">{location.secondary_text}</span></div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            ""
                                }
                            </div>
                            <Modal
                                modal={this.state.modal}
                                toggle={this.toggle}
                                modalHeader="Ajouter une adresse"
                            >
                                <CustomerAddressModal
                                    toggle={this.toggle}
                                    loadAddresses={this.loadAddresses}
                                    hasHome={ this.state.home_address !== null }
                                    hasWork={ this.state.work_address !== null }
                                    categoryAddress={this.state.categoryAddress}
                                />
                            </Modal>
                        </div>
                        :
                        ""
                }

            </div>
        );
    }
}

OrderBlockDelivery.defaultProps = {
    subtitle: "",
    placeholder: ""
};

export default OrderBlockDelivery;
