import React, { Component } from "react";
import PropTypes from "prop-types";
import './styles.css';

export default class TYTextField extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: this.props.value,
            focused: false,
            showPassword: false
        };
    }

    componentWillReceiveProps(props){
        if(props.value && props.value !== this.state.value)
            this.setState({value: props.value});
    }

    textUpdate = e => {
        this.setState({value: e.target.value}, () => {
            this.props.onChangeText(this.state.value);
        });
    };

    togglePassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    };

    render(){
        let {
            value,
            label,
            hasError,
            onChangeText,
            password,
            ...props
        } = this.props;

        return (
            <div
                className={
                    "input-text-field "
                    + (this.state.focused ? " input-text-focused " : "")
                    + (hasError ? " input-text-error " : "")
                }
            >
                { label ? <label>{ label }</label> : null }
                {
                    password ?
                        <div className="input-group marg-t-10">
                            <input
                                type={ this.state.showPassword ?  "text" : "password"}
                                className="custom-input-group form-control"
                                value={ this.state.value }
                                onChange={ this.textUpdate }
                                onFocus={() => { this.setState({ focused: true }); }}
                                onBlur={() => { this.setState({ focused: false }); }}
                                {...props}
                            />
                            <div className="input-group-addon" onClick={this.togglePassword}>
                                <i className={"font-ty " + (this.state.showPassword ? "icon-show" : "icon-hide")}> </i>
                            </div>
                        </div>
                        :
                        <input
                            type="text"
                            value={ this.state.value }
                            onChange={ this.textUpdate }
                            onFocus={() => { this.setState({ focused: true }); }}
                            onBlur={() => { this.setState({ focused: false }); }}
                            {...props}
                        />
                }
            </div>
        );
    }
}

TYTextField.propTypes = {
    value: PropTypes.string,
    hasError: PropTypes.bool,
    onChangeText: PropTypes.func,
    password: PropTypes.bool
};

TYTextField.defaultProps = {
    value: "",
    hasError: false,
    onChangeText: text => {},
    password: false
};
