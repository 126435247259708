import React, { Component } from 'react';
import {
    withRouter
} from 'react-router-dom';
import PasswordRecoveryEmail from '../components/PasswordRecoveryEmail';


class PasswordRecovery extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="bg-blue_dark" style={{minHeight: "100vh"}}>
                <div className="pad-t-20 pad-landscape-md-t-50">
                    <div className="background-contain w-logo pad-t-100 pad-sm-t-90 pad-xs-t-63 pad-landscape-sm-t-63"
                            style={{margin: "0 auto", backgroundImage: "url('"+require('../images/TankYou-v2-white.png')+"')"}}
                    >
                    </div>
                </div>
                <h1 className="ty-title-white ty-title-after-blue">
                    R&eacute;initialisation du mot de passe
                </h1>
                <div className="ty-form
                                col-md-4 col-md-offset-4 col-landscape-md-4 col-landscape-md-offset-4
                                col-sm-6 col-sm-offset-3 col-landscape-sm-8 col-landscape-sm-offset-2"
                >
                    <PasswordRecoveryEmail />
                </div>
                <div className="clear"> </div>
            </div>
        );
    }
}

export default withRouter(PasswordRecovery);
