import React, {Component} from 'react';
import './styles.css';
import Utils, {Registry} from '../../../Utils';
import DivGroupInline from "../../DivGroupInline";
import ReactDOM from "react-dom";
import Modal from "../../Modal";
import CancelationModal from "./CancelationModal";
import Popover from "../../Popover";

import {NotificationManager} from "react-notifications";


export default class OrderCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showVehicles: this.props.data.status === "waiting" ? true : false,
            showProducts: this.props.data.status === "waiting" ? true : false,
            showOption: false,
            toggle: false
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        document.addEventListener('click', this.closeOption, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeOption, false)
    }

    closeOption = (e) => {
        if(!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState({showOption: false})
        }
    };

    toggleVehicle = () => {
        this.setState({
            showVehicles: !this.state.showVehicles
        });
    };

    toggleProducts = () => {
        this.setState({
            showProducts: !this.state.showProducts
        });
    };

    getVehicleDescription = (vehicle) => {
        return <div>{vehicle.description}<br/>
            <span className="fw-300">{vehicle.gas_name} - {vehicle.plate}</span>
        </div>
    };

    getProductDescription = (product) => {
        return <div className="product-desc">
            <div>
                {product.product_name}<br/>
                <span className="fw-300">x{product.qty}</span>
            </div>
            <div>{ product.unit_price} &euro;</div>
        </div>
    };

    downloadInvoice = () => {
		let url = "?invoices_numbers=" + this.props.data.invoice_number + "&user_id=" + this.props.data.customer_id + "&is_business=0";
		this.api
			.download("/orders/invoices/download" + url, 'Tankyou-' + this.props.data.invoice_number + '.pdf')
			.catch(error => {
                NotificationManager.error("Impossible de recuperer la facture");
			});
    };

    displayPayment = (paid) => {
        let text = "";
        let color = "";

        if (paid === 0) {
            text = "A PAYER";
            color = "order-card-status-not-paid";
        } else if (paid === 1) {
            text = "PAYÉ";
            color = "order-card-status-paid";
        } else if (paid === 2) {
            text = "PAIEMENT EN COURS";
            color = "order-card-status-waiting";
        } else {
            text = "DÉFAUT DE PAIEMENT";
            color = "order-card-status-not-paid";
        }

        return <span className={color}>{text}</span>
    };

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    };

    render() {
        return (
            <div className="col-md-12 f-14">
                <div className={"col-md-12 block-content " + (this.props.nb === (this.props.length - 1) ? "" : "marg-b-10") }>
                    <p className="underline-title f-18">
                        N&deg; {this.props.data.shorten} &nbsp;
                        {
                            this.props.data.status === "finished" ?
                                this.displayPayment(this.props.data.paid)
                                :
                                ""
                        }
                        {
                            this.props.data.status === "waiting" ?
                                <span className="order-card-status-waiting show-info">
                                    {
                                        this.props.data.cancel_request_datetime === null ?
                                            "En cours de validation" : "En cours d'annulation"
                                    }
                                    &nbsp;<i className="icon-question"> <Popover content="Votre demande est en cours de traitement"/> </i>
                                </span>
                                :
                                ""
                        }
                        {
                            (this.props.data.status === "waiting" && this.props.data.cancel_request_datetime === null) || (this.props.data.status === "finished" && this.props.data.invoice_number)  ?
                                <i className="font-ty icon-option right cursor"
                                   onClick={() => this.setState({showOption: true})}
                                >
                                    {
                                        this.state.showOption ?
                                            this.props.data.status === "finished" ?
                                                <div className="dropdown-option" onClick={() => {this.downloadInvoice(); this.toggle(); } }>T&eacute;l&eacute;charger la facture</div>
                                                :
                                                <div className="dropdown-option" onClick={() => this.toggle()}>Demande d'annulation</div>
                                            :
                                            ""
                                    }
                                </i>
                                :
                                ""
                        }
                    </p>
                    <div className="row marg-b-10">
                        <div className="col-md-7">
                            <span className="medium f-14">Date & lieu</span>
                            <div className="pad-b-10 pad-t-10">
                                <i className="font-ty icon-calendar" style={{paddingLeft: "0px"}}> </i>
                                {
                                    this.props.data.status === "waiting" ?
                                        Utils.formatDate(this.props.data.available_date_min, "ddd DD MMMM YYYY [entre] HH[h]mm") + " et " + Utils.formatDate(this.props.data.available_date_max, "HH[h]mm")
                                        :
                                        Utils.formatDate(this.props.data.start_datetime, "ddd DD MMMM YYYY [à] HH[h]mm")
                                }
                            </div>
                            <div className="pad-b-10">
                                <i className="font-ty icon-address" style={{paddingLeft: "0px"}}> </i> { this.props.data.location }
                            </div>
                            {
                                this.props.data.purchase_order ?
                                    <div className="pad-b-10">
                                        <span className="purchase-order-value">Bon de commande : { this.props.data.purchase_order }</span>
                                    </div>
                                    : ""
                            }
                        </div>
                        <div className="col-md-5">
                            <div className="marg-b-10">
                                <span className="medium f-14">V&eacute;hicule(s) : </span> {this.props.data.vehicles.length} {this.props.data.vehicles.length <= 1 ? "véhicule" : "véhicules"}
                                <i className={"right f-20 icon-chevron-" + (!this.state.showVehicles ? "down" : "top")} onClick={() => this.toggleVehicle() }> </i>
                            </div>
                            {
                                !this.state.showVehicles ?
                                    ""
                                    :
                                    this.props.data.vehicles.map((vehicle, i) =>
                                        <DivGroupInline key={i}
                                                        icon="icon-car-side"
                                                        content={this.getVehicleDescription(vehicle)}
                                                        obj={vehicle}
                                                        command={true}
                                        />
                            )}
                            {
                                this.props.data.products.length ?
                                    <div className="marg-b-10">
                                        <span className="medium f-14">Produit(s) : </span> {this.props.data.products.length} produit{this.props.data.products.length <= 1 ? "" : "s"}
                                        <i className={"right f-20 icon-chevron-" + (!this.state.showProducts ? "down" : "top")} onClick={() => this.toggleProducts() }> </i>
                                    </div>
                                    : null
                            }
                            {
                                !this.state.showProducts ?
                                    ""
                                    :
                                    this.props.data.products.map((p, i) =>
                                        <DivGroupInline key={"prod-" + i}
                                                        icon="icon-order"
                                                        content={this.getProductDescription(p)}
                                                        obj={p}
                                                        command={true}
                                        />
                            )}
                        </div>
                    </div>
                    <div className="row order-card-summary f-14">
                        <div className="col-md-4 center">
                            <p className="fw-300 uppercase f-14">Frais de service</p>
                            { this.props.data.fee_per_vehicle.toFixed(2) + " €" + (this.props.data.fees_type === 0 ? " par véhicule" : "") }
                        </div>
                        <div className="col-md-4 center order-card-separate f-14">
                            <p className="fw-300 uppercase f-14">Carburant &euro; / L</p>
                            {
                                this.props.data.gas_needed.map((shorten, i) => {
                                    return this.props.data.gas[shorten] ?
                                        <p className="f-14" key={"gas-"+ shorten}>{this.props.data.gas[shorten].name} : { this.props.data.gas[shorten].price_ttc.toFixed(2) }</p>
                                        : ""
                                })
                            }
                        </div>
                        <div className="col-md-4 center f-14">
                            <p className="fw-300 uppercase f-14">Total</p>
                            { this.props.data.delivery_datetime ? this.props.data.amount_to_pay.toFixed(2) + "€" : "-" }
                        </div>
                    </div>
                </div>
                {
                    this.props.data.status === "waiting" ?
                        <Modal
                            modal={this.state.toggle}
                            toggle={this.toggle}
                            modalHeader="Demande d'annulation"
                        ><CancelationModal
                            data={this.props.data}
                            comment={this.state.comment}
                            error={this.state.errorCancelation}
                            errorMsg={this.state.errorMessage}
                            loadOrders={this.props.loadOrders}
                        />
                        </Modal>
                        :
                        ""
                }
            </div>
        )
    }
}

OrderCard.defaultProps = {
    data: {}
};
