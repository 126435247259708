const API_SCRIPT = "https://wchat.freshchat.com/js/widget.js";
const FC_HOST = "https://wchat.freshchat.com";


let FCToken = null;
let ExecStack = [];
let UserData = null;


function addToStack(fn){
    ExecStack.push(fn);
}

export function AddFCScript(token){
    FCToken = token;
    
    if (window.fcWidget === undefined && !document.getElementById("freshchat-js-sdk")) {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = false;
		script.defer = true;
        script.src = API_SCRIPT;
        script.onload = InitFC;
        document.body.appendChild(script);
	}
}

export function InitFC(){
    if(window.fcWidget){
        if(window.fcWidget.isInitialized())
            return;

        let initData = {
            token: FCToken,
            host: FC_HOST
        };

        if(UserData){
            initData = {
                ...initData,
                ...UserData
            };
            UserData = null;
        }

        window.fcWidget.on("widget:loaded", () => {
            for(let f of ExecStack)
                f();
            ExecStack = [];
        });
        
        window.fcWidget.init(initData);
    }
}

export function SetFCUser(user){
    UserData = {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: user.phone_number
    };

    if(window.fcWidget && window.fcWidget.isInitialized())
        window.fcWidget.user.setProperties(UserData);
}

export function ClearFCUser(){
    if(!window.fcWidget)
        addToStack(ClearFCUser);
    else{
        window.fcWidget.user.clear().then(function() {}, function() {});
        DestroyFC();
        setInterval(InitFC, 100);
    }
}

export function DestroyFC(){
    if(window.fcWidget.isInitialized()){
        window.fcWidget.destroy();
    }
}

export function OpenFC(message=""){
    if (window.fcWidget && window.fcWidget.isOpen() !== true)
        window.fcWidget.open({ replyText: message });
}