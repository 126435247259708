import React, {Component} from 'react';
import TYTextField from '../../TYInputs/textField';

export default class ProfileInvoiceAddress extends Component {
    render() {
        return (
            <div className="col-md-12">
                <div className="row marg-b-15">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Rue"
                            value={this.props.data.address}
                            hasError={this.props.error.address}
                            onChangeText={(e) => this.props.inputChanged(e, "address")}
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Compl&eacute;ment d'adresse"
                            value={this.props.data.address2}
                            hasError={this.props.error.address2}
                            onChangeText={(e) => this.props.inputChanged(e, "address2")}
                            placeholder="Optionnel"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <TYTextField
                                    label="Code postal"
                                    value={this.props.data.postal_code}
                                    hasError={this.props.error.postal_code}
                                    onChangeText={(e) => this.props.inputChanged(e, "postal_code")}
                                />
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <TYTextField
                                    label="Ville"
                                    value={this.props.data.city}
                                    hasError={this.props.error.city}
                                    onChangeText={(e) => this.props.inputChanged(e, "city")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Pays"
                            value={this.props.data.country}
                            hasError={this.props.error.country}
                            onChangeText={(e) => this.props.inputChanged(e, "country")}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ProfileInvoiceAddress.defaultProps = {
    data: {
        address: "",
        address2: "",
        postal_code: "",
        city: "",
        country: ""
    }
};
