import React, {Component} from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';
import './styles.css';
import {Registry} from "../../Utils";
let WebStorage = require('react-webstorage');

class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.api = Registry.registred('api');

        this.state = {
            data: {
                card_number: false,
                card_expiration: false,
                card_ccv: false
            },
            card_name: "",
            error: "",
            error_code: "",
            loading: false
        };
        this.webStorage = new WebStorage(window.localStorage || window.sessionStorage);
    }

    onFocus = (key) => {
        if (this.state.data.hasOwnProperty(key)) {
            let data = this.state.data;
            data[key] = true;
            this.setState(data);
        }
    };

    onBlur = (key) => {
        if (this.state.data.hasOwnProperty(key)) {
            let data = this.state.data;
            data[key] = false;
            this.setState(data);
        }
    };


    handleSubmit = (ev) => {
        this.setState({loading: true});
        ev.preventDefault();

        let user = Registry.registred("user");

        if (user !== null) {
            this.props.stripe.createToken()
                .then(payload => {
                        if(payload.hasOwnProperty("token")){
                            this.api.post("/customers/" + user.id + "/paymentMethod", {
                                card_name: this.state.card_name,
                                token: payload.token.id
                            })
                                .then((res) => {
                                    this.props.toggle();
                                    this.props.cards();
                                    this.setState({loading: false, error: ""});
                                    this._element.clear();
                                })
                                .catch(error => {
                                    this.setState({error: "Il y a une erreur sur votre carte. Merci de verifier les champs renseignés.", loading: false});
                                });
                        }
                        else if(payload.hasOwnProperty("error")){
                            let invalids = document.querySelectorAll(".StripeElement--invalid");

                            for (let i = 0; i < invalids.length; i++) {
                                invalids[i].parentElement.setAttribute("class", "red");
                            }
                            this.setState({
                                error: payload.error.message,
                                error_code: payload.error.code,
                                loading: false
                            });
                        }
                    }
                );
        } else {
            this.setState({loading: false, error: "Vous n'etes pas connecté"});
        }
    };

    render() {
        return (
            <form>
                <div className="row">
                    <div className="col-md-12 marg-b-15">
                        <label
                            className={"card-label " + (this.state.data.card_number ? "color-blue": "") +
                            (this.state.error_code === "incomplete_number" || this.state.error_code === "invalid_number" ? " red" : "")
                            }>
                            N&deg; de carte bancaire
                            <CardNumberElement
                                onBlur={() => this.onBlur("card_number")}
                                onFocus={() => this.onFocus("card_number")}
                                elementRef={(c) => this._element = c }
                            />
                        </label>

                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-6 pad-r-10">
                                <label
                                    className={"card-label " + (this.state.data.card_expiration ? "color-blue": "") +
                                    (this.state.error_code === "incomplete_expiry" || this.state.error_code === "invalid_expiry" ? " red" : "")
                                    }>
                                    Date d'exp.
                                    <CardExpiryElement
                                        onBlur={() => this.onBlur("card_expiration")}
                                        onFocus={() => this.onFocus("card_expiration")}
                                        elementRef={(c) => this._element = c }
                                    />
                                </label>
                            </div>
                            <div className="col-sm-6 pad-l-10">
                                <label
                                    className={"card-label " + (this.state.data.card_ccv ? "color-blue": "") +
                                    (this.state.error_code === "incomplete_cvc" || this.state.error_code === "invalid_cvc" ? " red" : "")
                                    }>
                                    CCV
                                    <CardCVCElement
                                        onBlur={() => this.onBlur("card_ccv")}
                                        onFocus={() => this.onFocus("card_ccv")}
                                        elementRef={(c) => this._element = c }
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.error === "" ?
                        ""
                        :
                        <div className="marg-t-10 red">
                            {this.state.error}
                        </div>
                }
                <div className="center marg-t-15">
                    {
                        this.state.loading ?
                            <button><i className="fa fa-spinner fa-spin fa-3x fa-fw f-16"> </i></button>
                            :
                            <button id="stripe_submit" className="btn_primary" onClick={this.handleSubmit}>Ajouter</button>
                    }
                </div>
            </form>
        );
    }
}

CheckoutForm.defaultProps = {
    name: ""
};

export default injectStripe(CheckoutForm);
