import React, {Component} from 'react';
import './styles.css';

export default class Popover extends Component {
    render() {
        return (
            <span className={"popover above " + this.props.customClass }>{this.props.content}</span>
        );
    }
}

Popover.defaultProps = {
    content: "",
    customClass: ""
};
