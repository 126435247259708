import React, { Component } from "react";
import { Registry } from "../../Utils";
import DivGroupInline from "../DivGroupInline";
import Modal from "../Modal";
import CustomerVehicleModal from "../Order/OrderBlockModal/CustomerVehicleModal";

export default class AccountVehicles extends Component{
    constructor(props){
        super(props);

        this.user = Registry.observe(this, "user", user => {
            this.user = user;
        });

        this.api = Registry.registred("api");

        this.state = {
            vehicles: [],
            toggle: false,
            modalUpdate: false,
            vehicleToUpdate: null,
            loading: false
        };
    }

    componentDidMount(){
        this.loadVehicles();
    }

    loadVehicles = () => {
        this.setState({loading: true});
        this.api.get("/fleet")
            .then(results => {
                this.setState({
                    loading: false,
                    vehicles: results.fleet
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({loading:false});
            });
    };

    getVehicleDescription = (vehicle) => {
        return <div className="f-14">{vehicle.description}<br/>
            <span className="fw-300">{vehicle.gas_name} - {vehicle.plate}</span>
        </div>
    };

    isModalUpdate = (bool = false, vehicleId = null) => {
        this.setState({
            modalUpdate: bool,
            vehicleToUpdate: vehicleId
        });
    };

    toggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    };

    render(){
        return (
            <div className="row col-md-6">
                <div className="block-content-account">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                            V&eacute;hicules
                                            <span className="pink cursor right"><i className="icon-more" onClick={() => {this.toggle(); this.isModalUpdate(false)}}> </i> </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 marg-t-15">
                                {
                                    this.state.loading ?
                                        <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i>
                                        :
                                        this.state.vehicles.length > 0 ?
                                            this.state.vehicles.map((vehicle, i) => {
                                                return <DivGroupInline
                                                    key={i}
                                                    icon="icon-car-side"
                                                    content={this.getVehicleDescription(vehicle)}
                                                    obj={vehicle}
                                                    command={true}
                                                    update={true}
                                                    updateAction={this.isModalUpdate}
                                                    toggle={this.toggle}
                                                />
                                            })
                                            :
                                            "Aucun véhicule"
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    modalId={ this.state.modalUpdate ? "addVehicleModal" : "updateVehicleModal" }
                    modal={this.state.toggle}
                    toggle={this.toggle}
                    modalHeader={ (this.state.modalUpdate ? "Modifier" : "Ajouter")  + " un véhicule"}
                    validation={ this.state.modalUpdate ? "Modifier" : "Ajouter" }
                >
                    <CustomerVehicleModal
                        isUpdated={this.state.modalUpdate}
                        vehicle={this.state.modalUpdate ? this.state.vehicleToUpdate : null}
                        loadVehicles={this.loadVehicles}
                        toggle={this.toggle}
                    />
                </Modal>
            </div>
        );
    }
}
