import React, { Component } from 'react';
import {Registry} from '../Utils'
import {
    withRouter
} from 'react-router-dom';


class PasswordRecoveryChange extends Component {
    constructor(props){
        super(props);
        this.state = {
            fields: {
                pwd: "",
                confPwd: ""
            },
            errors: {
                pwdError: false,
                confPwdError: false,
                match: true,
                messageError: null
            },
            success: false,
            loading: false
        };

        this.api = Registry.registred("api");

        this.inputChanged = this.inputChanged.bind(this);
        this.sendNewPwd = this.sendNewPwd.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    inputChanged(e, input_key){
        if(this.state.fields.hasOwnProperty(input_key) && typeof e !== "undefined") {

            let nState = this.state;

            nState.fields[input_key] = (typeof e === "string" ? e : e.target.value);

            this.setState(nState);
        }
    }

    sendNewPwd() {
        let valid = true;
        let state = this.state;

        if (this.state.fields.pwd === ""){
            state.errors["pwdError"] = true;
            valid = false;
        }
        if (this.state.fields.confPwd === "") {
            state.errors["confPwdError"] = true;
            valid = false;
        }
        if (this.state.fields.pwd !== this.state.fields.confPwd) {
            state.errors["match"] = false;
            valid = false;
        }

        if (!valid)
            this.setState(state);
        else {
            this.setState({loading: true, state});

            let token = this.props.match.params.token;
            let n = this;

            this.api.post("/customers/password/reset?token=" + token, {password: this.state.fields.pwd})
                .then(result => {
                    this.setState({success: true, loading: false})
                })
                .catch(error => {
                    this.setState({success: false, loading: false});
                    if (error.hasOwnProperty("subcode")) {
                        switch (error.subcode) {
                            case 'POSTCustomersInvalidPassword':
                                n.setState({messageError: "Le mot de passe entré n'est pas valide."});
                                break;
                            default:
                                n.setState({messageError: "Une erreur s'est produite. Merci de bien vouloir réessayer."});
                                break;
                        }
                    }
                });
        }
    }

    handleKeyPress(target) {
        if(target.charCode===13){
            this.sendNewPwd();
        }
    }

    render() {
        return (
            <div>
                <div className="ty-inside-form-2">
                    {
                        !this.state.errors.match ?
                            <div className="alert alert-danger">
                                Les mots de passes entr&eacute;s ne correspondent pas. Merci de r&eacute;essayer.
                            </div> : ""
                    }
                    {
                        this.state.loading ?
                            <div className="alert alert-info">
                                Traitement en cours. Merci de bien vouloir patienter ...
                            </div> : ""
                    }
                    {
                        this.state.success ?
                            <div className="alert alert-success">
                                Votre mot de passe a bien &eacute;t&eacute; chang&eacute; !
                            </div> : ""
                    }
                    {
                        this.state.messageError ?
                            <div className="alert alert-danger">
                                {this.state.messageError}
                            </div> : ""
                    }
                    <p className="center marg-b-30 blue_dark f-13">
                        Merci de renseigner votre mot de passe
                    </p>

                    <span style={this.state.errors.pwdError ? {color: "red"}:{}} className="blue_dark f-15">Nouveau mot de passe</span>
                    <input type="password" onKeyPress={this.handleKeyPress} onChange={e => this.inputChanged(e, "pwd")}
                           value={this.state.fields.pwd} className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required
                    />

                    <span style={this.state.errors.confPwdError ? {color: "red"}:{}} className="blue_dark f-15">Confirmation mot de passe</span>
                    <input type="password" onKeyPress={this.handleKeyPress} onChange={e => this.inputChanged(e, "confPwd")}
                           value={this.state.fields.confPwd} className="wp-100 border-rad-4 blue_dark marg-t-5" required
                    />

                    <div className="center">
                            {
                                this.state.success ?
                                    <a href="/connexion" className="btn_primary btn_large marg-t-30 uppercase cta-min-height">
                                            Connexion
                                    </a> :
                                        this.state.loading ?
                                            <div className="btn_primary btn_large marg-t-30 uppercase cta-min-height">
                                                <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i>
                                            </div> :
                                            <div className="btn_primary btn_large marg-t-30 uppercase cta-min-height"
                                                 onClick={() => this.sendNewPwd()}
                                            >
                                                    Réinitialiser mon mot de passe
                                            </div>

                            }
                    </div>
                    <div style={{clear: "both"}}> </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PasswordRecoveryChange);
