import { COMMON } from "./common";


const DEV_ENV = {
    baseUrl: "https://dev.www.tankyou.co",
    APIUrl: "https://api.integ.tankyou.io/v2",
    
    oauth: {
        server_url: "https://dev.auth.tankyou.co",
        client_id: "7b1a6680-8c02-11e8-ab61-0e392d121bb6",
        redirect_uri: "/handle_token",
        scopes: "full"
    },

    auth: {
        Client: {
            ID: "7b1a6680-8c02-11e8-ab61-0e392d121bb6",
            RedirectURI: "/handle_token",
            Scope: "full"
        },
        Server: {
            URL: "https://auth.integ.tankyou.io",
            Endpoints: {
                Authorize: "/oauth/authorize",
                Token: "/oauth/token",
                Logout: "/user/logout"
            }
        }
    },

    stripe_token: "pk_test_0e0iKwV5QzNvSTszP1rsYq4C",


    ...COMMON
};

export default DEV_ENV;