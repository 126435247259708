/* global Stripe */

import React, { useEffect } from 'react';
import {
    Router,
    Route,
    Switch
} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import moment from "moment";

import PasswordReset from "./routes/PasswordReset";
import PasswordRecovery from "./routes/PasswordRecovery";
import Account from "./routes/Account";
import PlaceOrder from "./routes/Order";
import NotFound from "./routes/NotFound";
import Signup from "./routes/Signup";
import AccountVerified from "./routes/AccountVerified";

import Variables from "./vars";

import { TokenHandler } from "./utils/auth";
import { Registry, Api } from "./Utils";
import { AddFCScript } from "./utils/freshchat";
import { notify } from './utils/observer';
import { USER_LOGGED_IN_EVENT } from './utils/events';

import { 
    AuthInit,
    IsLogged, 
    SignOut
} from "@TaankYou/auth";


function twoDigits(d) {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
}

// eslint-disable-next-line
Date.prototype.toMysqlFormat = function() {
    return this.getUTCFullYear() + "-" + twoDigits(1 + this.getUTCMonth()) + "-" + twoDigits(this.getUTCDate()) + " " + twoDigits(this.getUTCHours()) + ":" + twoDigits(this.getUTCMinutes()) + ":" + twoDigits(this.getUTCSeconds());
};

moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },

    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1,
        doy : 4
    }
});

let history = createHistory();
Registry.register("history", history);
history.listen(() => {
    window.scrollTo(0,0);
});


AuthInit(Variables.auth);


let api = new Api(Variables.APIUrl);
Registry.register("api", api);

const StripeLoadTimer = setInterval(() => {
    if (window.Stripe) {
        let stripe = Stripe(Variables.stripe_token);
        Registry.register("stripe", stripe);
        clearInterval(StripeLoadTimer);
    }
}, 100);

const AuthRoute = (props) => {
    if(IsLogged())
        return <Route {...props} />;
    return null;
}

const App = _ => {
    useEffect(() => {
        AddFCScript(Variables.freshchatToken);
            
        setTimeout(() => api.getUser()
            .then(results => {
                notify(USER_LOGGED_IN_EVENT, results.customer);
                Registry.register("user", results.customer);
            })
            .catch(e => {
                console.log("NOT GETTING USER ", e);
            }), 
            500
        );
    }, []);

    return <Router history={history}>
        <div className="route-container">
            <Switch>
                <Route path="/handle_token" exact component={() => {
                    return <TokenHandler 
                            onFailed={() => {
                                const { protocol, host } = window.location;
                                SignOut(`${protocol}//${host}`);
                            }}
                            onSuccess={() => window.location.replace("/")}
                        /> 
                }} />

                <Route path="/" exact component={Account} />

                <Route path="/account-verified/:token" exact component={AccountVerified} />
                <Route path="/account-verified/:token/:noApp" exact component={AccountVerified} />

                <Route path="/inscription/:userType?" exact component={Signup} />
                <Route path="/mot-de-passe-oublie" exact component={PasswordRecovery} />
                <Route path="/mot-de-passe-oublie/:token" exact component={PasswordReset} />

                <AuthRoute path="/mon-compte/:blockId" exact component={Account} />
                <AuthRoute path="/mon-compte/:blockId/:childBlockId" exact component={Account} />
                <AuthRoute path="/commander" exact component={PlaceOrder} />

                <Route component={NotFound} status={404} />
                
            </Switch>
        </div>
    </Router>
}

export default App;