import React, {Component} from 'react';
import TYTextField from '../../TYInputs/textField';

export default class ProfilePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false
        }
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Ancien mot de passe"
                            value={this.props.data.old_password}
                            hasError={this.props.error.old_password}
                            onChangeText={ (e) => this.props.inputChanged(e, "old_password") }
                            password
                        />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <TYTextField
                            label="Nouveau mot de passe"
                            value={this.props.data.new_password}
                            hasError={this.props.error.new_password}
                            onChangeText={ (e) => this.props.inputChanged(e, "new_password") }
                            password
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ProfilePassword.defaultProps = {
    data: {
        old_password: "",
        new_password: ""
    }
};
