import React, { Component } from 'react';
import Variables from '../vars';
import { Registry } from '../Utils.js';
import {
    withRouter
} from 'react-router-dom'
import Phone from 'react-phone-number-input';
import BigSelect from "./BigSelect";
import Vars from "../vars";


class SignupForm extends Component {
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        this.inputChanged   = this.inputChanged.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.postUser       = this.postUser.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        this.state = {
            messageError: null,
            loading: false,
            errors:{
                fname: false,
                lname: false,
                phone: false,
                email: false,
                password: false,
                postal_code: false,
                company: false,
                passwconf: false,
				activity: false
            },
            fields: {
                fname: "",
                lname: "",
                phone: "",
                email: "",
                password: "",
                postal_code: "",
                company: "",
                passwconf: "",
				activity: ""
            },
            email_success_register: "",
            checkboxState: false,
            success: false,
            cgv: false,
            is_customer: !this.props.business,
            is_business: this.props.business
        }
    }

    inputChanged(e, input_key){
        if(this.state.fields.hasOwnProperty(input_key) && typeof e !== "undefined"){
            let nState = this.state;

            nState.fields[input_key] = (typeof e === "string" ? e : e.target.value);
            nState.errors[input_key] = false;

            this.setState(nState);
        }

    }

    toggleCheckbox(input_key){
        if (input_key === "cgv")
            this.setState({checkboxState: !this.state.checkboxState});
    }

    postUser(){
        let nState = this.state;
        let error  = false;

        for(let k in this.state.fields){
            if((this.state.fields.hasOwnProperty(k) && this.state.fields[k] === "") || (k === "phone" && this.state.fields[k] === "+33")){
                if ((k === "company" || k === "activity") && this.state.is_business) {
                    nState.errors[k] = true;
                    error = true;
                } else if ((k === "company" || k === "activity") && !this.state.is_business) {
                    break;
                } else {
                    nState.errors[k] = true;
                    error = true;
                }
            }
        }

        nState.messageError = "";
        this.setState(nState);

        if (this.state.fields.password !== this.state.fields.passwconf)
            error = true;

        if(!this.state.checkboxState)
            this.setState({cgv: true}, () => {window.scrollTo(0, 280)});
        else if(!error){
            // POST FORM
            let e = this;

            this.setState({loading: true});

            let formData = {
                first_name: this.state.fields.fname,
                last_name: this.state.fields.lname,
                phone_number: this.state.fields.phone,
                email: this.state.fields.email,
                password: this.state.fields.password,
                postal_code: this.state.fields.postal_code
            };

            if (this.state.is_business) {
                formData.company = this.state.fields.company;
                formData.activity = this.state.fields.activity;
            }

            this.api.post("/customers", formData)
                .then(() => {
                    this.setState({
                        loading: false,
                        success: true,
                        cgv: false,
                        fields: {
                            fname: "",
                            lname: "",
                            phone: "",
                            email: "",
                            password: "",
                            postal_code: "",
                            company: "",
                            passwconf: "",
                            activity: ""
                        },
                        email_success_register: formData.email
                    });
                })
                .catch(error => {
                    let nState = e.state;
                    if(error.hasOwnProperty("subcode")){
                        switch(error.subcode){
                            case 'POSTCustomerMissingLastName':
                                nState.errors.lname = true;
                                break;
                            case 'POSTCustomerMissingFirstName':
                                nState.errors.fname = true;
                                break;
                            case 'POSTCustomerEmailExists':
                            case 'POSTCustomerInvalidEmail':
                            case 'POSTCustomerMissingEmail':
                                nState.errors.email = true;
                                break;
                            case 'POSTCustomerInvalidPhoneNumber':
                                nState.errors.phone = true;
                                break;
                            case 'POSTCustomerPasswordTooShort':
                            case 'POSTCustomerMissingPassword':
                                nState.errors.password = true;
                                break;
                            default:
                                break;
                        }

                        nState.messageError = error.details;
                        nState.loading      = false;
                        nState.cgv          = false;

                        e.setState(nState, () => {window.scrollTo(0, 280)});

                    }
                });
        }
    }

    handleKeyPress(target) {
        if(target.charCode===13){
            this.postUser();
        }

    }

    render() {
        return (
            <div>
                <div className="ty-inside-form">
                    {
                        this.state.success ?
                            <div className="alert alert-success">
                                Un email de confirmation vous a &eacute;t&eacute; envoy&eacute; à l'adresse suivante : <br/> {this.state.email_success_register}
                            </div> : ""
                    }
                    {
                        this.state.messageError ?
                            <div className="alert alert-danger">
                                { this.state.messageError }
                            </div> : ""
                    }
                    {
                        this.state.cgv ?
                            <div className="alert alert-danger">
                                Merci d'accepter les conditions g&eacute;n&eacute;rales
                            </div> : ""
                    }
                    <form>
                        {
                            !this.props.not_signup_page ?
                                <div className="row">
                                    <div className="d-flex">
                                        <a
                                            className={"col-md-6 col-sm-6 col-xs-6 " + (this.state.is_customer ? "signup-type-active" : "signup-type")}
                                            href={"/inscription/particulier"}
                                        ><i className="font-ty icon-user pad-r-10" /> Particulier</a>
                                        <a
                                            href={"/inscription/professionnel"}
                                            className={"col-md-6 col-sm-6 col-xs-6 " + (this.state.is_customer ? "signup-type" : "signup-type-active")}
                                        ><i className="font-ty icon-business pad-r-10" /> Professionnel</a>
                                    </div>
                                    <hr className="hr-signup"/>
                                </div>
                                :
                                ""
                        }
                        {
                            this.state.is_business ?
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-landscape-xs-6 pad-md-r-5 pad-landscape-sm-r-5 pad-xs-r-0">
                                        <span style={this.state.errors.company ? {color: "red"}:{}}  className="f-15">Raison sociale</span>
                                        <input name="company" type="text" onKeyPress={this.handleKeyPress}
                                               onChange={e => this.inputChanged(e, "company")} value={this.state.fields.company}
                                               className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required/>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-landscape-xs-6 pad-md-l-5 pad-landscape-sm-r-5 pad-xs-l-0">
                                        <span style={this.state.errors.activity ? {color: "red"}:{}}  className="f-15">Type d'activit&eacute;</span>
                                        <BigSelect
                                            className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5"
                                            items={Vars.enterprise_activities}
											itemClicked={activity => {
											    let fields = this.state.fields;
											    let errors = this.state.errors;
											    errors.activity = activity === "";
											    fields.activity = activity;
											    this.setState({fields: fields, errors: errors});
                                            }}
                                        />
                                    </div>
                                </div>
                                :
                                ""
                        }

                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-landscape-xs-6 pad-md-r-5 pad-landscape-sm-r-5 pad-xs-r-0">
                                <span style={this.state.errors.lname ? {color: "red"}:{}}  className="f-15">Nom</span>
                                <input name="last_name" type="text" onKeyPress={this.handleKeyPress}
                                       onChange={e => this.inputChanged(e, "lname")} value={this.state.fields.lname}
                                       className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required/>
                            </div>
                            <div className="col-md-6 col-sm-6 col-landscape-xs-6 pad-md-l-5 pad-landscape-sm-r-5 pad-xs-l-0">
                                <span style={this.state.errors.fname ? {color: "red"}:{}}  className="f-15">Pr&eacute;nom</span>
                                <input name="first_name" type="text" onKeyPress={this.handleKeyPress}
                                       onChange={e => this.inputChanged(e, "fname")} value={this.state.fields.fname}
                                       className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <span style={this.state.errors.email ? {color: "red"}:{}} className="f-15">Email</span>
                                <input name="email" type="email" onKeyPress={this.handleKeyPress}
                                       onChange={e => this.inputChanged(e, "email")} value={this.state.fields.email}
                                       className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-landscape-xs-6 pad-md-r-5 pad-landscape-sm-r-5 pad-xs-r-0">
                                <span style={this.state.errors.phone ? {color: "red"}:{}} className="f-15">Tel Mobile</span>
                                <Phone country={'FR'} onChange={e => this.inputChanged(e, "phone")}
                                       value={this.state.fields.phone}
                                       className="blue_dark marg-b-30 marg-t-5" required/>
                            </div>
                            <div className="col-md-6 col-sm-6 col-landscape-xs-6 pad-md-l-5 pad-landscape-sm-r-5 pad-xs-l-0">
                                <span style={this.state.errors.postal_code ? {color: "red"}:{}} className="f-15">Code postal</span>
                                <input name="zip_code" type="text" onKeyPress={this.handleKeyPress}
                                       onChange={e => this.inputChanged(e, "postal_code")} value={this.state.fields.postal_code}
                                       className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <span style={this.state.errors.password ? {color: "red"}:{}} className="f-15">Mot de passe</span>
                                <input name="pwd" type="password" onKeyPress={this.handleKeyPress} onChange={e => this.inputChanged(e, "password")} value={this.state.fields.password} className="wp-100 marg-b-20 border-rad-4 blue_dark marg-t-5" required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <span style={(this.state.errors.passwconf || this.state.fields.passwconf !== this.state.fields.password) ? {color: "red"}:{}} className="blue_dark f-15">Confirmer le mot de passe</span>
                                <input name="pwd-conf" type="password" onKeyPress={this.handleKeyPress} onChange={e => this.inputChanged(e, "passwconf")} value={this.state.fields.passwconf} className="wp-100 marg-b-20 border-rad-4 marg-t-5" required />
                            </div>
                        </div>

                        <div className="row">
                            <p className="marg-t-10 marg-b-10">
                                <input type="checkbox" id="cgv" onChange={() => this.toggleCheckbox("cgv")} />
                                <label htmlFor="cgv" className="f-13">
                                    J'accepte les <a target="_blank" rel="noopener noreferrer" href={Variables.baseUrl+"/cgu.pdf"} className="secondary_link underline">conditions d'utilisation</a>
                                </label>
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-md-12 center">
                                <div className="btn_primary btn_large marg-t-10 uppercase cta-min-height" onClick={() => this.postUser()}>
                                    {this.state.loading ? <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i> : "Créer mon compte"}
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="marg-t-25 center f-11 col-md-12">
                                Vous avez d&eacute;jà un compte ? <a href="/connexion" className="secondary_link underline"> Connectez-vous</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

SignupForm.defaultProps = {
    business: false
};

export default withRouter(SignupForm);
