import React, { Component } from 'react';
import Utils from '../Utils.js';
import {
    withRouter
} from 'react-router-dom';


class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    getUrl(path) {
        return `https://www.tankyou.co${path}`;
    }

    render() {
        return (
            <footer className={"pad-md-t-50 pad-xs-t-0 pad-md-b-50 pad-xs-b-20 border-t-grey-1 f-14 " + this.props.textColor + " bg-" + this.props.backgroundColor}>
                <div className="container">
                    <div className="col-md-3 col-landscape-md-3 col-landscape-sm-4 col-sm-4 col-md-offset-1 col-landscape-xs-4 lh-30 pad-md-0 pad-landscape-md-t-30 pad-xs-b-0 pad-30">
                        <div className="pad-b-15">
                            <span className="background-contain h-34 display-block"
                                  style={{
                                      backgroundImage: "url("+require("../images/"+ (this.props.backgroundColor === "grey_light" ? "TankYou-v2.png" : "TankYou-v2-white.png"))+")",
                                      backgroundSize: ""
                                  }}>
                            </span>
                        </div>
                        <a href={this.getUrl("/livraison-carburant-professionnels")} className="cursor pink-hover">
                            Pour les professionnels
                        </a>
                        <br/>
                        <a href={this.getUrl("/livraison-carburant-particuliers")} className=" cursor pink-hover">
                            Pour les particuliers
                        </a>
                        <br/>
                        <span className="display-inline-bloc ">
                            <a href={this.getUrl("/livraison-carburant-gnr")} className=" pink-hover cursor pad-r-5">GNR</a>
                        </span>
                        <br/>
                        <span className="display-inline-bloc ">
                            <a href={this.getUrl("/faq")} className=" pink-hover cursor pad-r-5">FAQ</a>
                            |
                            <a href={this.getUrl("/presse")} className=" pink-hover cursor pad-l-5 pad-r-5">Presse</a>
                            |
                            <a href="https://www.welcometothejungle.com/fr/companies/tankyou/jobs" target="_blank" rel="noopener noreferrer" className=" pink-hover cursor pad-l-5">Recrutement</a>
                        </span>
                        <br/>
                        <span className="display-inline-bloc ">
                            <a href={"https://tankyou-cdn.s3.eu-west-2.amazonaws.com/public/Documents/cgu.pdf"} className=" pink-hover cursor pad-r-5" target="_blank" rel="noopener noreferrer">CGV</a>
                            |
                            <a href={"https://tankyou-cdn.s3.eu-west-2.amazonaws.com/public/Documents/privacy.pdf"} className=" pink-hover cursor pad-l-5" target="_blank" rel="noopener noreferrer">Politique de confidentialit&eacute;</a>
                        </span>
                    </div>

                    <div className="col-md-3 col-md-offset-1 col-sm-4 col-landscape-md-3 col-landscape-sm-4 col-landscape-xs-4
                                    pad-30 pad-md-0 pad-landscape-md-t-30 pad-xs-b-0 pad-landscape-sm-r-0 pad-landscape-sm-l-0">
                        <div className={"ty-title-footer-" + this.props.textColor + " ty-title-footer-after-blue pad-b-5"}>
                            Livraisons carburant
                        </div>
                        <div className={"grey" + (this.props.backgroundColor === "grey_light" ? "_dark" : "") + " pad-t-10 pad-b-25"}>
                            Tankyou livre actuellement votre carburant &agrave; Paris, en r&eacute;gion Ile-de-France, &agrave; Lille ainsi qu'&agrave; Lyon.
                        </div>
                        <div className={"ty-title-footer-" + this.props.textColor + " ty-title-footer-after-blue pad-b-5"}>
                            Nos carburants
                        </div>
                        <div className={"grey" + (this.props.backgroundColor === "grey_light" ? "_dark" : "") + " pad-t-10"}>
                            Tankyou ravitaille vos v&eacute;hicules en essence (SP95 et SP98 ou E5, E10, E85), Diesel (gasoil B7 et B10) et <a href="/livraison-carburant-gnr" className=" pink-hover cursor">GNR</a>.
                        </div>
                    </div>

                    <div className="col-md-3 col-landscape-md-3 col-landscape-sm-3 col-sm-3 col-sm-offset-1 col-md-offset-1 col-landscape-xs-4 pad-md-0 pad-landscape-md-t-30 pad-30">
                        <div className={"ty-title-footer-" + this.props.textColor + " ty-title-footer-after-blue"}>
                            Paiement sécurisé
                        </div>
                        {
                            this.props.backgroundColor === "blue_dark" ?
                                <span className="background-contain h-70 display-block cursor"
                                      style={{
                                          backgroundImage: "url("+require('../images/logo-stripe-white.png')+")",
                                          backgroundSize: ""
                                      }}
                                      onClick={() => Utils.goToNewTab("https://www.stripe.com/about")}
                                >
                                </span> :
                                <span className="background-contain h-70 display-block cursor"
                                      style={{
                                          backgroundImage: "url("+require('../images/logo-stripe.png')+")",
                                          backgroundSize: ""
                                      }}
                                      onClick={() => Utils.goToNewTab("https://www.stripe.com/about")}
                                >
                                </span>
                        }
                        <div className="pad-t-20 pad-b-13">
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"> </i>
                                <i className={"fa pink-hover cursor fa-facebook f-18 fa-stack-1x fa-inverse "
                                                + (this.props.backgroundColor === "blue_dark" ? "blue_dark" : "")}
                                   onClick={() => Utils.goToNewTab("https://www.facebook.com/TankYou.co/")}>
                                </i>
                            </span>
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"> </i>
                                <i className={"fa pink-hover cursor fa-linkedin f-18 fa-stack-1x fa-inverse "
                                                + (this.props.backgroundColor === "blue_dark" ? "blue_dark" : "")}
                                   onClick={() => Utils.goToNewTab("https://fr.linkedin.com/company/tankyou")}> </i>
                            </span>
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"> </i>
                                <i className={"fa pink-hover cursor fa-twitter f-18 fa-stack-1x fa-inverse "
                                                + (this.props.backgroundColor === "blue_dark" ? "blue_dark" : "")}
                                   onClick={() => Utils.goToNewTab("https://twitter.com/tankyou_co?lang=fr")}> </i>
                            </span>
                            <span className="fa-stack fa-lg">
                                <i className="fa fa-circle fa-stack-2x"> </i>
                                <i className={"fa pink-hover cursor fa-google-plus f-16 fa-stack-1x fa-inverse "
                                                + (this.props.backgroundColor === "blue_dark" ? "blue_dark" : "")}
                                   onClick={() => Utils.goToNewTab("https://plus.google.com/115521631349232707085")}> </i>
                            </span>
                        </div>

                        <div className="pad-b-8">
                            <a href="mailto:hello@tankyou.com" className=" pink-hover cursor pad-r-10">hello@tankyou.com</a>
                        </div>
                        <span>
                            <i className="icon-phone" aria-hidden="true"> </i> 01 86 76 08 88
                        </span>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.defaultProps = {
    backgroundColor: "grey_light",
    textColor: "blue_dark"
};

export default withRouter(Footer);
