import React, {Component} from 'react';
import {Registry} from "../../../Utils";
import DivGroupInline from "../../DivGroupInline";

export default class OrderBlockProducts extends Component {
    constructor(props) {
        super(props);

        this.selected_products = [];

        this.state = {
            products: []
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        this.loadProducts();
    }

    loadProducts = () => {
        this.api.get("/products/zone/" + this.props.zoneId)
            .then(res => {
                this.setState({ products: res })
            })
            .catch(err => {
                console.log(err);
            })
    };

    getProductDescription(product) {
        return <div>
            {product.product.name}<br />
            {product.price} &euro;
        </div>
    }

    productSelected = (product, add = true) => {
        if(add)
            this.selected_products.push(product);
        else{
            this.selected_products = this.selected_products.filter(obj => {
                return obj.id !== product.id;
            });
        }
        this.props.orderInformations({products: this.selected_products});
    };

    render() {
        if(!this.state.products.length)
            return <div />;

        return (
            <div className="marg-b-20">
                <div className="row">
                    <div className="col-md-10">
                        <p className="marg-b-10">Produits additionnels</p>
                    </div>
                </div>
                    {
                        this.state.products.map((p, i) =>
                            <DivGroupInline key={"prod-"+i}
                                icon="icon-order"
                                content={this.getProductDescription(p)}
                                obj={p}
                                getSelected={this.productSelected}
                                choose
                            />
                        )
                    }
            </div>
        );
    }
}

OrderBlockProducts.defaultProps = {
    placeholder: "",
    subtitle: "",
    zoneId: "",
};
