import React, { Component } from 'react';
import SignupForm from '../components/SignupForm.js';
import {
    withRouter
} from 'react-router-dom';


class Signup extends Component {
    componentDidMount() {
        document.title = "Inscription | Tankyou, livraison de carburant à domicile";
    }

    render() {
        let userType = this.props.match.params.userType;
        let is_business = (userType === "professionnel");

        return (
            <div className=" bg-blue_dark" style={{minHeight: "100vh"}}>
                <div>
                    <div className="pad-t-20">
                        <div className="background-contain w-logo pad-t-100 pad-sm-t-90 pad-xs-t-63 pad-landscape-sm-t-63"
                                style={{margin: "0 auto", backgroundImage: "url('"+require('../images/TankYou-v2-white.png')+"')"}}
                        >
                        </div>
                    </div>
                    <h1 className="ty-title-white ty-title-after-blue">
                        Vous êtes sur le point d'utiliser la plus belle innovation pour g&eacute;rer votre carburant
                    </h1>
                    <div className="ty-form
                                    col-md-4 col-md-offset-4 col-landscape-md-4 col-landscape-md-offset-4
                                    col-sm-8 col-sm-offset-2 col-landscape-sm-8 col-landscape-sm-offset-2
                                    col-ipad-8 col-ipad-offset-2 col-landscape-ipad-8 col-landscape-ipad-offset-2"
                    >
                        <SignupForm business={is_business} />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

export default withRouter(Signup);
