import React, {Component} from 'react';

export default class DivGroupInlineColor extends Component {
    render() {
        return (
            <div className="fw-300 div-block-content border-grey-1 white--important" style={{backgroundColor: this.props.color}}>
                <div className="div-block-logo">
                    <i className={"font-ty " + this.props.icon}> </i>
                </div>
                <div className="pad-5 vertical-align-middle">
                    {this.props.content}
                </div>
                <div className="div-block-price">{this.props.content2} &euro; / L</div>
            </div>
        );
    }
}

DivGroupInlineColor.defaultProps = {
    color: "",
    icon: "icon-drop",
    content: "",
    content2: ""
};
