import React, { Component } from "react";


class BigSelect extends Component{
    constructor(props){
        super(props);

        this.state = {
            item: null,
            listShown: false
        };

        this.inputClicked = this.inputClicked.bind(this);
        this.itemClicked = this.itemClicked.bind(this);
    }

    inputClicked(){
        this.setState({
            listShown: !this.state.listShown
        });
    }

    itemClicked(item){
        this.setState({
            item: item,
            listShown: !this.state.listShown
        });
        this.props.itemClicked(item);
    }

    render(){
        let items_list = [];
        let i = 0;

        while(i < this.props.items.length){
            let s = this.props.items[i];
            items_list.push(
                <li className={"autoSuggest-item " + this.props.itemClass}
                    onClick={() => { this.itemClicked(s) }}>
                    {this.props.renderItem(s)}
                </li>
            );
            i++;
        }

        return (
            <div className={"relative " + this.props.className}>
                <div
                    className={this.props.inputClass + (!this.state.item ? " " + this.props.placeholderColor: "") + " cursor"}
                    onClick={ this.inputClicked }
                >
                    { !this.state.item ? this.props.placeholder : this.props.renderItem(this.state.item) }

                    <i className="fa fa-caret-down pull-right" aria-hidden="true"></i>
                </div>
                {
                    this.state.listShown && (this.props.items.length || this.props.footer) ?

                        <ul className={"autoSuggest-list " + this.props.listClass} style={{
                            zIndex: 10000
                        }}>
                            {items_list.map(item => {return item})}
                            { this.props.footer ?
                                <li className={"autoSuggest-item " + this.props.itemClass}
                                    onClick={this.props.onFooterClicked}>
                                    {this.props.footer}
                                </li>
                                : ""
                            }
                        </ul>

                        : ""
                }
            </div>
        );
    }
}

BigSelect.defaultProps = {
    placeholder: "Choisir ...",
    placeholderColor: "grey_dark",
    inputClass: "wp-100 border-rad-5 f-14 border-grey-1 pad-15",
    itemClass: "pad-10 border-b-grey-1 f-12 grey_dark bg-grey_light-hover blue_dark-hover cursor",
    listClass: "wp-100 list-unstyled bg-white absolute top-40 border-grey-1 border-b-0",
    itemClicked: suggest => {},
    renderItem: item => { return item; },
    onFooterClicked: () => {},
    items: [],
    footer: false,
    className: ""
};

export default BigSelect;
