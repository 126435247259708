import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Utils, Registry } from '../../Utils';
import OrderCard from "./Orders/OrderCard";

class AccountOrders extends Component{
    constructor(props){
        super(props);

        this.state = {
            orders: [],
            loading: false
        };

        this.api = Registry.registred("api");
    }

    componentWillMount() {
        let status = null;
        let finished = null;
        switch (this.props.match.params.childBlockId) {
            case "validees":
                finished = 0;
                break;
            case "terminees":
                status = "finished";
                break;
            default:
                status = "waiting";
                break;
        }
        this.loadOrders(status, finished);
    }

    loadOrders = (status = null, finished = null) => {
        let data = [];
        let _this = this;
        this.setState({loading: true});
        let filter = "";
        if (status === null && finished !== null) {
            filter = "finished=" + finished;
        } else {
            filter = "status=" + status;
        }
        this.api.get("/orders?order_by_desc=" + (status === "waiting" ? "available_date_min&" : "start_datetime&") + filter)
            .then(results => {
                if (_this.props.match.params.childBlockId === "validees") {
                    for (let i = 0; i < results.results.length; i++) {
                        if (results.results[i].status !== "waiting")
                            data.push(results.results[i]);
                    }
                } else {
                    data = results.results;
                }
                this.setState({
                    orders: data,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    switchCategory = (nb) => {
        let status = null;
        let finished = null;
        switch (nb) {
            case 1:
                Utils.goTo(this, "/mon-compte/commandes/validees");
                finished = 0;
                break;
            case 2:
                Utils.goTo(this, "/mon-compte/commandes/terminees");
                status = "finished";
                break;
            default:
                Utils.goTo(this, "/mon-compte/commandes");
                status = "waiting";
                break;
        }
        this.loadOrders(status, finished);
    };

    render() {
        return (
            <div>
                <div className="block-content-account">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h3 className="ty-subtitle-blue_dark ty-subtitle-after-blue">
                                            Commandes
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className=" underline-title">
                                    <span
                                        className={"cursor marg-r-15 " + (this.props.match.params.childBlockId === undefined ? "color-red-ty" : "") }
                                        onClick={() => { this.switchCategory(0); }}>
                                        En attente
                                    </span>
                                    <span
                                        className={"cursor marg-r-15 " + (this.props.match.params.childBlockId === "validees" ? "color-red-ty" : "") }
                                        onClick={() => { this.switchCategory(1); }}>
                                        Valid&eacute;es
                                    </span>
                                    <span
                                        className={"cursor marg-r-15 " + (this.props.match.params.childBlockId === "terminees" ? "color-red-ty" : "") }
                                        onClick={() => { this.switchCategory(2); }}>
                                        Termin&eacute;es
                                    </span>
                                </div>
                            </div>
                            {
                                this.state.loading ?
                                    <div className="col-md-12"><i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i></div>
                                    :
                                    (
                                        this.state.orders.length ?
                                            this.state.orders.map((order, i) =>
                                                <OrderCard
                                                    key={i}
                                                    data={order}
                                                    nb={i}
                                                    length={this.state.orders.length}
                                                    loadOrders={this.loadOrders}
                                                />
                                            )
                                            :
                                            <div className="col-md-12">Aucune commande</div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AccountOrders.defaultProps = {
    finished: true,
    waiting: false
};

export default withRouter(AccountOrders);
