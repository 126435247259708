import React, { Component } from 'react';
import {Registry} from '../Utils'
import {
    withRouter
} from 'react-router-dom'

class PasswordRecoveryEmail extends Component {
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        this.inputChanged = this.inputChanged.bind(this);
        this.sendRecovery = this.sendRecovery.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        this.state = {
            email: "",
            emailError: false,
            loading: false,
            success: false,
            sent: false,
            invalidEmail: false,
            messageError: ""
        };

    }

    handleKeyPress(target) {
        if(target.charCode===13){
            this.sendRecovery();
        }

    }

    inputChanged(e, input_key){
        let nState = this.state;

        nState[input_key] = (typeof e === "string" ? e : e.target.value);

        this.setState(nState);

    }

    sendRecovery(){
        this.setState({loading: true});

        if (this.state.email === "") {
            this.setState({emailError: true, loading: false})
        }
        else {
            this.setState({emailError: false});

            this.api.post("/customers/password/reset/request", {email: this.state.email})
                .then(result => {
                    this.setState({success: true, loading: false})
                })
                .catch(error => {
                    let nState = this;
                    if(error.hasOwnProperty("subcode")) {
                        switch (error.subcode) {
                            case 'POSTCustomersResourceNotFound':
                                nState.setState({
                                    invalidEmail: true,
                                    messageError: "l'email entré ne correspond à aucun utilisateur enregistré"});
                                break;
                            case 'POSTCustomersInvalidEmail':
                                nState.setState({
                                    invalidEmail: true,
                                    messageError: "l'email entré est invalide"});
                                break;
                            default:
                                break;
                        }
                    }

                    this.setState({success: false, loading: false})
                });

            this.setState({sent: true});
        }

    }

    render() {
        return (
            <div>
                <div className="ty-inside-form-2">
                    {
                        this.state.success && this.state.sent ?
                            <div className="alert alert-success">
                                Un email contenant un lien vous permettant de red&eacute;finir votre mot de passe vous a &eacute;t&eacute; envoy&eacute; à l'adresse suivante :
                                <br/>
                                {this.state.email}
                            </div>
                            :
                            (
                                !this.state.loading && this.state.sent ?
                                    <div>
                                        <div className="alert alert-danger">
                                            Une erreur s'est produite : {this.state.messageError}
                                        </div>
                                        <div className="blue_dark marg-b-20 center f-13">
                                            Si le problème persiste, <a href="/contact" className="basic_link thin cursor">contactez-nous</a>.
                                        </div>
                                    </div> : ""
                            )

                    }
                    <p className="center marg-b-30 blue_dark f-13">
                        Entrez l'adresse email associ&eacute;e à votre compte Tankyou et nous vous enverrons un email avec les instructions pour changer votre mot de passe
                    </p>
                    <span style={this.state.emailError ? {color: "red"}:{}} className="blue_dark f-15">Email</span>
                    <input type="email" onKeyPress={this.handleKeyPress} onChange={e => this.inputChanged(e, "email")} value={this.state.email} className="wp-100 marg-t-5 border-rad-3 blue_dark" required />


                    <div className="center">
                        <div className="btn_primary btn_large marg-t-30 uppercase cta-min-height" onClick={() => this.sendRecovery()}>
                            {this.state.loading ? <i className="fa fa-spinner fa-spin fa-3x fa-fw f-18"> </i> : "Réinitialiser mon mot de passe"}
                        </div>
                    </div>

                    <div className="row">
                        <div className="marg-t-25 center f-11 col-md-12 blue_dark">
                            <a href="/connexion" className="secondary_link underline"> Connectez-vous</a>
                        </div>
                    </div>

                    <div style={{clear: "both"}}> </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PasswordRecoveryEmail);
