import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Utils, Registry } from "../Utils";
import {NotificationManager} from "react-notifications";
import {NotificationContainer} from "react-notifications";

import AccountHeader from "../components/Account/Header/Header";
import Footer from "../components/Footer";
import OrderDeliveryInfo from "../components/Order/Delivery";
import OrderPayment from "../components/Order/Payment";
import OrderSummary from "../components/Order/Summary";
import OrderBlockSummary from "../components/Order/OrderBlockAction/OrderBlockSummary";


class PlaceOrder extends Component{
    constructor(props){
        super(props);

        this.user = Registry.observe(this, "user", user => {
            this.user = user;
        });

        this.api = Registry.registred("api");

        this.bookOrder = this.bookOrder.bind(this);

        this.state = {
            loading: false,
            error: null,
            vehicle: [],
            products: [],
            payment: null,
            comments: "",
            step: 1,
            gas_prices: [],
            fees: 5.0,
            fees_type: 0,
            fees_source: "",
            special_offer_label: "",
            stepAction: 1,
            location: null,
            selectedDay: null,
            beginHour: null,
            endHour: null,
            promotionalCode: "",
            error_msg: "",
            promo_success: false,
            promo: null,
            locationSelected: false
        };
    }

    getPrices = (location) => {
        this.api.get("/customers/prices?location=" + location)
            .then(res => {
                let gas_prices = [];
                for (let i = 0; i < res.length; i++) {
                    if (res[i].product_type === "GAS" && res[i].price_ttc !== null) {
                        gas_prices.push({
                            color: res[i].color,
                            name: res[i].name,
                            price_ttc: res[i].price_ttc
                        });
                    }
                    if (res[i].product_type === "FEE") {
                        this.setState({
                            fees: res[i].price_ttc,
                            fees_type: parseInt(res[i].product_type_id),
                            fees_source: res[i].source,
                            special_offer_label: res[i]["special-offer-label"]
                        });
                    }
                }
                this.setState({gas_prices: gas_prices, locationSelected: true});
            })
            .catch(err => {
                this.setState({msgError: "Erreur", locationSelected: false});
            })
    };

    bookOrder () {
        this.setState({loading: true});

        let min = new Date(this.state.selectedDay);
        let max = new Date(this.state.selectedDay);
        min.setHours(this.state.beginHour, 0, 0);
        max.setHours(this.state.endHour, 0, 0);

        let p_data = [];
        
        for(let p of this.state.products)
            p_data.push({id: p.product.id, qty: 1});

        let data = {
            location: this.state.location,
            available_date_min: min.toMysqlFormat(),
            available_date_max: max.toMysqlFormat(),
            payment_id: this.state.payment.id,
            delivery_infos: this.state.comments,
            vehicles: JSON.stringify(this.state.vehicle),
            products: JSON.stringify(p_data)
        };

        if (this.state.promotionalCode !== "") {
            data["promo_code"] = this.state.promotionalCode;
        }

        if (this.state.comments !== "") {
            data["comments"] = this.state.comments;
        }

        this.api.post("/orders", data)
            .then(data => {
                this.setState({
                    order_id: data.order.id,
                    error: false,
                    error_msg: ""
                }, () => {
                    NotificationManager.success("Commande créée");
                    setTimeout(() => {Utils.goTo(this, "/mon-compte/accueil")}, 2000)
                });
            })
            .catch(error => {
                let msg = "";

                switch (error.subcode) {
                    case "POSTOrdersInvalidPromo":
                        msg = "Code promotionnel invalide";
                        break;
                    case "POSTOrdersCardDeclined":
                        msg = "Impossible de créer la commande car votre carte a été declinée";
                        break;
                    default:
                        msg = "Erreur lors de la création de votre commande";
                        break;
                }

                NotificationManager.error(msg);

                this.setState({
                    loading: false,
                    error: true,
                    error_msg: msg
                });
            });
    };

    promotionalCodeDetail = (promo) => {
        return <div className="f-12">
            <span className="green">-{promo.reduc_value} { promo.type_reduc === 0 ? "€" : "%"} sur {promo.type_target === 0 ? "les frais de service" : "cette commande"}</span><br/>
            {promo.name} sera appliqu&eacute;
        </div>
    };

    promotionalCode = (name) => {
        this.api.get("/promos/"+ name + "/check")
            .then(res => {
                this.setState({
                    promo: res.promo,
                    promo_success: true,
                    error: false,
                    error_msg: ""
                })
            })
            .catch(err => {
                let error = false;
                let msg = "";
                switch(err.subcode) {
                    case "GETPromosError":
                        error = true;
                        msg = "Code promotionnel incorrect";
                        break;
                    default:
                        error = true;
                        msg = "Impossible de verifier le code promo";
                }

                this.setState({
                    error: error,
                    error_msg: msg
                })
            });
    };

    nextStep = () => {
        if (this.state.step === 3) {
            // this.bookOrder();
        } else {
            this.setState({
                step: this.state.step + 1
            });
        }
    };

    orderInformations = (data) => {
        this.setState(data);

        if (data.location) {
            this.getPrices(data.location);
        }
    };

    changeStep = (nb) => {
        if (this.state.step === nb)
            return;

        if (nb === 1) {
            this.setState({step: nb});
        }
        else if (nb === 2) {
            if (this.state.step === 1) {
                if (this.state.location === null || this.state.selectedDay === null || this.state.vehicle === null) {
                    return;
                }
            }
            if (this.state.step === 3) {
                if (this.state.payment === null)
                    return;
            }
            this.setState({step: nb});
        }
    };

    updatePromotionalCode = (code) => {
        this.setState({
            promotionalCode: code
        })
    };

    updateComment = (comment) => {
        this.setState({
            comments: comment
        })
    };

    cancelPromo = () => {
        this.setState({
            promo_success: false,
            promotionalCode: "",
            reduc_value: 0
        })
    };

    render(){
        if(!this.user)
            return <Redirect to="/" />;

        return (
            <div className="bg-grey_light ">
                <AccountHeader background-color="blue_dark" text-color="white" backgroundImage={require("../images/TankYou-v2-white.png")} />

                <section className="bg-grey_light home-account">
                    <div className="container min-height-container">
                        <div className="col-md-4 hide_below-md">
                            <div className="col-md-12 block-content pad-b-15" style={{display: (this.state.step === 3 ? "none" : "block")}}>
                                <p className="underline-title cursor" onClick={() => {Utils.goTo(this, "/mon-compte/accueil")}}><i className="font-ty icon-arrow-left"> </i>&nbsp;&nbsp;Accueil</p>
                                <div className="col-md-12 bg-grey-light f-13 marg-b-15">
                                    <p className="f-13 fw-100">Tarifs des carburants du jour</p>
                                    {
                                        this.state.locationSelected ?
                                            <div>
                                                <div className="col-md-12 underline-title">
                                                    {
                                                        this.state.gas_prices.map((gas, i) => {
                                                            return <div key={"gas-"+ i} className={"row " + (i !== this.state.gas_prices.length -1 ? "marg-b-15" : "")}>
                                                                <div className="inline-gas-price">
                                                                    <div className="font-ty-round-inline" style={{ backgroundColor: gas.color }}>
                                                                        <i className="icon-drop"> </i>
                                                                    </div>
                                                                    <div className="pad-l-15">{gas.name}</div>
                                                                    <div className="inline-price">{gas.price_ttc} &euro; / L</div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 pad-l-0">
                                                            Frais de service
                                                        </div>
                                                        <div className="col-md-6 right pad-r-0">
                                                            {
                                                                parseInt(this.state.fees) !== 0 ?
                                                                    <span className="pull-right">
                                                            { this.state.fees_source === "special-offer" && this.state.fees !== 0 ? <span className="line-through marg-r-5">5 &euro;</span> : "" }
                                                                        {this.state.fees} &euro;{parseInt(this.state.fees_type) === 0 ? " / véhicule" : ""}
                                                        </span>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                this.state.fees_source === "special-offer" ?
                                                                    <div className="green right">{this.state.special_offer_label}</div> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <p className="f-12 marg-t-15">Pour visualiser les tarifs des carburants du jour, rentrez une adresse de livraison.</p>
                                    }
                                </div>
                                <OrderBlockSummary
                                    location={this.state.location}
                                    selectedDay={this.state.selectedDay}
                                    beginHour={this.state.beginHour}
                                    endHour={this.state.endHour}
                                    vehicle={this.state.vehicle}
                                    products={this.state.products}
                                />
                                <div className="col-md-12 marg-t-15">
                                    <div className="row">
                                        {
                                            this.state.promo_success ?
                                                <div className="promo-block-success">
                                                    {this.promotionalCodeDetail(this.state.promo)}
                                                    <div className="underline right cursor h-5" onClick={() => this.cancelPromo() }>Annuler</div>
                                                </div>
                                                :
                                                <div className="input-group">
                                                    <input type="text" placeholder="Code promotionnel" className="custom-input-group form-control" value={this.state.promotionalCode} onChange={(e) => this.updatePromotionalCode(e.target.value)} />
                                                    <div className="input-group-addon underline cursor" onClick={() => this.promotionalCode(this.state.promotionalCode)}>Valider</div>
                                                </div>
                                        }

                                        {
                                            this.state.error ?
                                                <div className="marg-t-10 red">
                                                    {this.state.error_msg}
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 f-13">
                            <div className="row">
                                <div className="col-md-6 block-content pad-t-10 pad-b-10 marg-b-15">
                                    <span className={"cursor " + (this.state.step === 1 ? "pink" : "grey")}>Info. livraison <i className="icon-chevron-right grey"> </i> </span>
                                    <span className={"cursor " + (this.state.step === 2 ? "pink" : "grey")}>Moyen de paiement <i className="icon-chevron-right grey"> </i> </span>
                                    <span className={"cursor " + (this.state.step === 3 ? "pink" : "grey")}>R&eacute;capitulatif</span>
                                </div>
                            </div>
                            {
                                this.state.step !== 1 ?
                                    ""
                                    :
                                    <OrderDeliveryInfo
                                        orderInformations={this.orderInformations}
                                        nextStep={this.nextStep}
                                        zoneId={this.state.zoneId}
                                    />
                            }
                            {
                                this.state.step !== 2 ?
                                    ""
                                    :
                                    <OrderPayment
                                        orderInformations={this.orderInformations}
                                        nextStep={this.nextStep}
                                    />
                            }
                            {
                                this.state.step !== 3 ?
                                    ""
                                    :
                                    <OrderSummary
                                        data={this.state}
                                        getPromotionalCode={this.promotionalCode}
                                        getPromotionalCodeDetail={this.promotionalCodeDetail}
                                        cancelPromo={this.cancelPromo}
                                        updatePromotionalCode={this.updatePromotionalCode}
                                        updateComment={this.updateComment}
                                        nextStep={this.bookOrder}
                                        loading={this.state.loading}
                                    />
                            }
                        </div>
                    </div>
                </section>
                <NotificationContainer/>

                <Footer  backgroundColor="white"/>
            </div>
        );
    }
}

export default withRouter(PlaceOrder);
