import React, { Component } from 'react';
import { Utils, Registry } from '../../../Utils.js';
import {
    withRouter
} from 'react-router-dom';
import { SignOut } from "@TaankYou/auth";

class HeaderBurger extends Component {
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        let user = Registry.observe(this, "user", user => {
            this.setState({user:user});
        });

        this.state = {
            user: user,
            displayMenu: "none"
        };

        this.logout = this.logout.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

    }

    toggleMenu(){
        if(this.state.displayMenu === "block"){
            this.setState({displayMenu: "none"});
        }else{
            this.setState({displayMenu: "block"});
        }
    }

    logout(){
        Registry.remove("user");
        const { protocol, host } = window.location;
        SignOut(`${protocol}//${host}`);
    }

    componentDidMount(){
        let user = Registry.registred("user");
        if(user)
            this.setState({user: user});
    }

    render() {
        return (
            <div>
                <div className={"wp-100 bg-" + this.props["background-color"] + " f-14 left display_below-md " + this.props["text-color"]}>
                    <div className="border-b-grey-1">
                        <div className="h-60">
                            {
                                this.state.displayMenu === "block" ?
                                    <div className={"pull-right marg-t-10 marg-r-20 " + this.props["text-color"]}>
                                        <img alt="img" className="cursor" src={require("../../../images/close-white.png")} height="33px" width="33px" onClick={() => this.toggleMenu()}/>
                                    </div> :
                                    <div id="nav-icon2" style={{zIndex: "1000"}}
                                         className={"navBurger marg-r-20 " + this.props["text-color"]}
                                         onClick={() => this.toggleMenu()}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                            }

                            <div onClick={() => Utils.goTo(this, "/")} className="cursor marg-l-30 background-contain-center h-66 w-80 f-32 lh-66 display-inline-block" style={{backgroundImage: 'url("'+this.props.backgroundImage+'")'}}>
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    <div className="relative">
                        <div className=" header-content h-full bg-grey_light"  style={{display: this.state.displayMenu}}>
                            <div>
                                {
                                    this.state.user ?
                                        <div className="bg-white pad-t-10 pad-b-10 ">
                                            <div>
                                                <div className="relative center uppercase pad-5 blue_dark">
                                                    {
                                                        "Bonjour " + this.state.user.first_name + " !"
                                                    }
                                                </div>
                                                <div className="absolute top-15 right-30">
                                                    <i className="f-17 icon-log-out cursor pink-hover blue_dark pad-5"
                                                       aria-hidden="true" onClick={() => this.logout()}
                                                    > </i>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="center pad-10">
                                                    <div onClick={()=> Utils.goTo(this, "/commander")}
                                                         className="btn_primary lh-25"
                                                    >
                                                    <span className="pad-l-10 pad-r-10">
                                                        Commander
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pad-10 pad-t-5">
                                                <div className="center underline primary_link f-12">
                                                <span className="pad-5" onClick={()=> Utils.goTo(this, "/")}>
                                                    Aller sur www.tankyou.co
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                            <div className="row pad-t-20">
                                <div className="col-sm-4 col-sm-offset-4 col-xs-10 col-xs-offset-1 block-content pad-l-15 pad-r-15">
                                    <ul>
                                        <li className={ (this.props.match.params.blockId === "accueil" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/accueil")}}>
                                            <i className="font-ty icon-house"> </i> Accueil
                                        </li>
                                        <li className={ (this.props.match.params.blockId === "informations" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/informations")}}>
                                            <i className="font-ty icon-user"> </i> Informations personnelles
                                        </li>
                                        <li className={ (this.props.match.params.blockId === "commandes" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/commandes")}}>
                                            <i className="font-ty icon-order"> </i> Commandes
                                        </li>
                                        <li className={ (this.props.match.params.blockId === "vehicules" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/vehicules")}}>
                                            <i className="font-ty icon-car"> </i> V&eacute;hicules
                                        </li>
                                        <li className={ (this.props.match.params.blockId === "adresses" ? "color-red-ty" : "") }  onClick={() => {Utils.goTo(this, "/mon-compte/adresses")}}>
                                            <i className="font-ty icon-address"> </i> Adresses
                                        </li>
                                        <li className={ (this.props.match.params.blockId === "paiement" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/paiement")}}>
                                            <i className="font-ty icon-credit-card"> </i> Paiement
                                        </li>
                                        <li className={ (this.props.match.params.blockId === "tarifs" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/tarifs-et-zones")}}>
                                            <i className="font-ty icon-gun-petrol"> </i> Tarifs & Zones
                                        </li>
                                    </ul>
                                </div>
                            </div>

                           
                            <div className="center pad-20">
                                <div className="lh-35 blue_dark">
                                    <i className="icon-phone" aria-hidden="true"></i> 01 86 76 08 88
                                </div>
                                <div className="blue_dark thin">
                                    6j/7 de 9h à 20h
                                </div>
                            </div>
                            <div className="pad-b-50">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

HeaderBurger.defaultProps = {
    "text-color": "white",
    "background-color": "blue_dark",
    "backgroundImage": require("../../../images/TankYou-v2-white.png")
};

export default withRouter(HeaderBurger);
